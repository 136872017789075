import { useNavigate } from "react-router-dom"
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "../../../../../components/ui/tabs"
import { CircleAlert } from "lucide-react"
import { getPath } from "../../../../../utils/helpers/path"

export const TabsAlert = ({ value }: { value: string }) => {
  const navigate = useNavigate()

  return (
    <Tabs value={value} className="flex flex-col gap-3">
      <div className="w-full flex items-center py-3 gap-2">
        <CircleAlert size={20} />
        <h1 className="text-[20px] font-semibold">Alertas</h1>
      </div>
      <TabsList className="grid w-full grid-cols-3">
        <TabsTrigger value="details" onClick={() => navigate(`/alert/details/${getPath()}`)}>Detalhes</TabsTrigger>
        <TabsTrigger value="comments" onClick={() => navigate(`/alert/comments/${getPath()}`)}>Comentários</TabsTrigger>
        <TabsTrigger value="historic" onClick={() => navigate(`/alert/historic/${getPath()}`)}>Histórico</TabsTrigger>
      </TabsList>
    </Tabs>
  )
}
