import React, { useState } from "react"
import { countFilledData } from "../../../../utils/helpers/calculate"
import { getInfractions, InfractionStatus, InfractionReason, InfractionSituation, AnalysisResult } from "../../../../api/endpoints/refund"
import { trimSpaces } from "../../../../utils/helpers/trimSpaces"
import { Input } from "../../../../components/ui/input"
import { Label } from "../../../../components/ui/label"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from "../../../../components/ui/sheet"
import { handleEnterKeyDown } from "../../../../utils/helpers/keyboard"
import { Button } from "../../../../components/ui/button"
import { Badge } from "../../../../components/ui/badge"
import { Filter, Search } from "lucide-react"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectGroup, SelectLabel, SelectItem } from "../../../../components/ui/select"
import { Filters } from "../page"

type FilterKey = keyof Filters

interface TableFiltersInfractionProps {
  filters: Filters
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
  setInfractions: React.Dispatch<React.SetStateAction<any[]>>
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  appliedFilters: Filters
  setAppliedFilters: React.Dispatch<React.SetStateAction<Filters>>
  pageIndex: number
  pageSize: number
}

export const TableFiltersInfraction: React.FC<TableFiltersInfractionProps> = ({ filters, setFilters, setInfractions, setPageIndex, setTotalPages, appliedFilters, setAppliedFilters, pageIndex, pageSize }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitFilters = async () => {
    if (!filters.infractionId.trim() && !filters.reporterIspb.trim() && !filters.counterPartIspb.trim() && !filters.status && !filters.reason && !filters.situation && !filters.analysisResult && !filters.createDateStart.trim() && !filters.createDateEnd.trim()) return
    setIsSubmitting(true)

    const response: any = await getInfractions({ ...filters, pageIndex: 1, pageSize })

    if (response?.data?.items) {
      setInfractions(response?.data?.items)
      setAppliedFilters(filters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setInfractions([])
      setAppliedFilters(filters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const removeAllFilters = async () => {
    const resetFilters: Filters = {
      infractionId: '',
      reporterIspb: '',
      counterPartIspb: '',
      status: '',
      reason: '',
      situation: '',
      analysisResult: '',
      createDateStart: '',
      createDateEnd: ''
    }
    setFilters(resetFilters)
    const response: any = await getInfractions({ pageIndex: 1, pageSize })
    if (response?.data?.items) {
      setInfractions(response?.data?.items)
      setAppliedFilters(resetFilters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setInfractions([])
      setAppliedFilters(resetFilters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const handleRemoveFilter = (key: FilterKey) => {
    const newFilters = { ...filters, [key]: '' }
    setFilters(newFilters)
    if (countFilledData(newFilters) === 0) {
      removeAllFilters()
    } else {
      submitFilters()
    }
  }

  const handleInputChange = (key: FilterKey, value: string) => {
    setFilters({ ...filters, [key]: trimSpaces(value) })
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="flex w-2/3 gap-2">
        <Input
          type="text"
          onKeyDown={handleEnterKeyDown(submitFilters)}
          placeholder="Procurar pelo ID..."
          disabled={isSubmitting}
          value={filters.infractionId}
          onChange={(e) => handleInputChange('infractionId', e.target.value)}
          className="max-w-sm"
        />
        <Button disabled={isSubmitting || !filters.infractionId.trim()} onClick={submitFilters} variant="default">
          <Search size={20} />
        </Button>
        <Sheet>
          <SheetTrigger>
            <Button disabled={isSubmitting} className="flex gap-2">
              <Filter size={15} />
              Filtros
            </Button>
          </SheetTrigger>
          <SheetContent className="flex flex-col gap-8">
            <SheetHeader>
              <SheetTitle className="flex items-center gap-2">
                <Filter size={20} />
                Filtros
              </SheetTitle>
              <SheetDescription>
                Utilize os filtros personalizados para encontrar dados que está buscando na tabela
              </SheetDescription>
            </SheetHeader>
            <div className="flex flex-col overflow-y-auto px-1 gap-5">
              {(['infractionId', 'reporterIspb', 'counterPartIspb', 'status', 'reason', 'situation', 'analysisResult', 'createDateStart', 'createDateEnd'] as FilterKey[]).map((key) => (
                <div key={key}>
                  <Label>{filterLabels[key]}</Label>
                  {key === 'status' || key === 'reason' || key === 'situation' || key === 'analysisResult' ? (
                    <Select onValueChange={(value) => handleInputChange(key, value)}>
                      <SelectTrigger>
                        <SelectValue placeholder={filterLabels[key]} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>{filterLabels[key]}</SelectLabel>
                          {key === 'status' && Object.values(InfractionStatus).map((status) => (
                            <SelectItem key={status} value={status}>{translateStatus(status)}</SelectItem>
                          ))}
                          {key === 'reason' && Object.values(InfractionReason).map((reason) => (
                            <SelectItem key={reason} value={reason}>{translateReason(reason)}</SelectItem>
                          ))}
                          {key === 'situation' && Object.values(InfractionSituation).map((situation) => (
                            <SelectItem key={situation} value={situation}>{translateSituation(situation)}</SelectItem>
                          ))}
                          {key === 'analysisResult' && Object.values(AnalysisResult).map((result) => (
                            <SelectItem key={result} value={result}>{translateResult(result)}</SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  ) : key === 'createDateStart' || key === 'createDateEnd' ? (
                    <Input
                      type="date"
                      value={filters[key] as string}
                      onChange={(e) => handleInputChange(key, e.target.value)}
                    />
                  ) : (
                    <Input
                      type="text"
                      value={filters[key] as string}
                      onChange={(e) => handleInputChange(key, e.target.value)}
                    />
                  )}
                </div>
              ))}
            </div>
            <SheetFooter>
              <SheetClose asChild>
                <Button variant="secondary" disabled={isSubmitting} onClick={removeAllFilters}>
                  Remover todos os Filtros
                </Button>
              </SheetClose>
              <SheetClose asChild>
                <Button disabled={isSubmitting} onClick={submitFilters}>
                  Aplicar
                </Button>
              </SheetClose>
            </SheetFooter>
          </SheetContent>
        </Sheet>
        {countFilledData(appliedFilters) !== 0 && (
          <div className="w-8 h-8 flex items-center justify-center rounded-[50%] bg-teal-700 text-white select-none">
            {countFilledData(appliedFilters)}
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        {(['infractionId', 'reporterIspb', 'counterPartIspb', 'status', 'reason', 'situation', 'analysisResult', 'createDateStart', 'createDateEnd'] as FilterKey[]).map((key) => (
          appliedFilters[key] && (
            <TagsFilter
              key={key}
              filterKey={key}
              filterValue={appliedFilters[key] as string}
              removeFilter={handleRemoveFilter}
            />
          )
        ))}
      </div>
    </div>
  )
}

const filterLabels: { [key in FilterKey]: string } = {
  infractionId: "ID da Infração",
  reporterIspb: "ISPB do Relator",
  counterPartIspb: "ISPB da Contraparte",
  status: "Status",
  reason: "Razão",
  situation: "Situação",
  analysisResult: "Resultado da Análise",
  createDateStart: "Data de Início",
  createDateEnd: "Data de Término"
}

const translateStatus = (status: string) => {
  switch (status) {
    case 'Pending': return 'Pendente'
    case 'Open': return 'Aberta'
    case 'Acknowledged': return 'Reconhecida'
    case 'Closed': return 'Fechada'
    case 'Cancelled': return 'Cancelada'
    case 'Failed': return 'Falhou'
    default: return status
  }
}

const translateReason = (reason: string) => {
  switch (reason) {
    case 'RefundRequest': return 'Solicitação de Reembolso'
    case 'RefundCancelled': return 'Reembolso Cancelado'
    default: return reason
  }
}

const translateSituation = (situation: string) => {
  switch (situation) {
    case 'Scam': return 'Golpe'
    case 'AccountTakeover': return 'Tomada de Conta'
    case 'Coercion': return 'Coerção'
    case 'FraudulentAccess': return 'Acesso Fraudulento'
    case 'Other': return 'Outro'
    case 'Unknown': return 'Desconhecido'
    default: return situation
  }
}

const translateResult = (result: string) => {
  switch (result) {
    case 'Agreed': return 'Concordado'
    case 'Disagreed': return 'Discordado'
    default: return result
  }
}

interface TagsFilterProps {
  filterKey: FilterKey
  filterValue: string
  removeFilter: (key: FilterKey) => void
}

const TagsFilter: React.FC<TagsFilterProps> = ({ filterKey, filterValue, removeFilter }) => (
  <Badge variant="secondary" onClick={() => removeFilter(filterKey)} className="cursor-pointer">
    {filterLabels[filterKey]}: {filterValue} ✕
  </Badge>
)
