import { useEffect, useState } from "react"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { TabsAccount } from "../_components/tabs-account"
import { AccountSettingsProps, getAccountById, updateAccountSettings } from "../../../../../api/endpoints/account"
import { getPath } from "../../../../../utils/helpers/path"
import { Switch } from "../../../../../components/ui/switch"
import { Input } from "../../../../../components/ui/input"
import { Button } from "../../../../../components/ui/button"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { Loader2, Settings } from "lucide-react"
import Swal from "sweetalert2"

export default function AccountSettings() {
  const [standardSettings, setStandardSettings] = useState<AccountSettingsProps>()
  const [settings, setSettings] = useState<AccountSettingsProps>()
  const [isSubmit, setIsSubmit] = useState<boolean>(false)

  const fetchAccountById = async () => {
    const response = await getAccountById({ accountId: getPath() })

    if (response?.data) {
      setStandardSettings({
        allowTransfer: response?.data?.allowTransfer,
        receiptsOnlySameDebtor: response?.data?.receiptsOnlySameDebtor,
        maxQrCode: response?.data?.maxQrCode === null ? 0 : response?.data?.maxQrCode
      })
      setSettings({
        allowTransfer: response?.data?.allowTransfer,
        receiptsOnlySameDebtor: response?.data?.receiptsOnlySameDebtor,
        maxQrCode: response?.data?.maxQrCode === null ? 0 : response?.data?.maxQrCode
      })
    }

    return null
  }

  const handleSubmitSettings = async () => {
    setIsSubmit(true)

    const response: any = await updateAccountSettings({ accountId: getPath(), settings: settings })
    if (response?.status === 200) {
      await fetchAccountById()
    }

    if (response?.status !== 200) {
      Swal.fire({
        icon: "error",
        title: "Não foi possível realizar a alteração.",
        confirmButtonColor: "var(--gray-500)",
      })
    }

    return setIsSubmit(false)
  }

  useEffect(() => {
    fetchAccountById()
  }, [])

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsAccount value="settings" />
          {settings ? (
            <div className="h-full flex flex-col items-center py-14 gap-10">
              <div className="flex items-center justify-center gap-2">
                <Settings size={20} />
                <h1 className="text-zinc-700 font-semibold text-xl">Configurações personalizadas</h1>
              </div>
              <div className="w-1/2 flex flex-col gap-8">
                <div className="w-full flex items-center justify-between gap-5">
                  <p className="text-zinc-600">Aceitar recebimento de transferências</p>
                  <Switch
                    checked={settings?.allowTransfer}
                    disabled
                  />
                </div>
                <div className="w-full flex items-center justify-between gap-5">
                  <p className="text-zinc-600">Receber transferências apenas do mesmo devedor</p>
                  <Switch
                    checked={settings?.receiptsOnlySameDebtor}
                    onClick={() => setSettings({ ...settings, receiptsOnlySameDebtor: !settings.receiptsOnlySameDebtor })}
                  />
                </div>
                <div className="w-full flex items-center justify-between gap-5">
                  <p className="text-zinc-600">Quantidade de QRcode limitada</p>
                  <Switch
                    checked={settings.maxQrCode === 0 ? false : true}
                    onClick={() => settings.maxQrCode === 0 ? setSettings({ ...settings, maxQrCode: 1 }) : setSettings({ ...settings, maxQrCode: 0 })}
                  />
                </div>
                {settings.maxQrCode !== null && settings.maxQrCode > 0 && standardSettings && (
                  <div className="border-2 border-zinc-200 rounded-md p-2 flex items-center justify-between">
                    <p className="text-zinc-600 text-[14px]">Selecione a quantidade de QRcodes que podem ser gerados:</p>
                    <Input
                      type="number"
                      className="w-20 text-center"
                      value={settings.maxQrCode}
                      onChange={(event) => {
                        const value = Number(event.target.value)
                        if (value > 0) {
                          setSettings({ ...settings, maxQrCode: value })
                        } else {
                          setSettings({ ...settings, maxQrCode: 1 })
                        }
                      }}
                    />
                  </div>
                )}
                <div className="text-center">
                  {settings.allowTransfer !== standardSettings?.allowTransfer ||
                    settings.receiptsOnlySameDebtor !== standardSettings?.receiptsOnlySameDebtor ||
                    settings.maxQrCode !== standardSettings?.maxQrCode ?
                    (
                      <Button onClick={handleSubmitSettings}>{!isSubmit ?
                        <>Salvar alterações</> :
                        <> <Loader2 className="mr-2 h-4 w-4 animate-spin" /> Por favor, aguarde</>
                      }</Button>
                    ) :
                    <div></div>}
                </div>
              </div>
            </div>
          ) : (
            <Skeleton className="w-full h-full" />
          )}
        </div>
      </div>
    </PlatformTemplate>
  )
}
