import { ChangeEvent, useState } from "react"
import { useNavigate } from "react-router-dom"
import { IsEmptyObject } from "../../../../utils/helpers/validate"
import { updateMyNewPassword } from "../../../../api/endpoints/auth"
import { PlatformTemplate } from "../../../../components/layout/template"
import { Input } from "../../../../components/ui/input"
import { Button } from "../../../../components/ui/button"
import Swal from "sweetalert2"
import { Loader2 } from "lucide-react"

interface PasswordProps {
  previousPassword: string
  newPassword: string
  repeatNewPassword: string
}

export default function NewPassword() {
  const navigate = useNavigate()
  const [invalidErrors, setInvalidErrors] = useState({
    previousPassword: {
      active: false,
      text: "A senha atual não está correta."
    },
    newPasswordMatch: {
      active: false,
      text: "As senhas não são iguais, por favor, faça novamente"
    }
  })
  const [isSubmitNewPassword, setIsSubmitNewPassword] = useState<boolean>(false)
  const [password, setPassword] = useState<PasswordProps>({ previousPassword: '', newPassword: '', repeatNewPassword: '' })

  const submitNewPassword = async () => {
    setIsSubmitNewPassword(true)
    const isMatchPassword = validateIfPasswordsMatch()
    const isEmpty = IsEmptyObject(password)

    if (isEmpty) {
      setIsSubmitNewPassword(false)
      return (
        Swal.fire({
          icon: "error",
          title: "Preencha todos os campos",
          confirmButtonColor: "var(--gray-500)",
        })
      )
    }

    if (isMatchPassword) {
      const response = await updateMyNewPassword({ oldPassword: password.previousPassword, newPassword: password.newPassword })

      if (response.status !== 200) {
        setIsSubmitNewPassword(false)
        return Swal.fire({
          icon: "error",
          title: "Não foi possível realizar a alteração",
          confirmButtonColor: "var(--gray-500)",
        })
      }

      Swal.fire({
        icon: "success",
        title: "Senha alterada com Sucesso!",
        confirmButtonColor: "var(--gray-500)",
      })

      return navigate("/profile")
    }
  }

  const validateIfPasswordsMatch = () => {
    if (password.newPassword !== password.repeatNewPassword) {
      setInvalidErrors({ ...invalidErrors, newPasswordMatch: { ...invalidErrors.newPasswordMatch, active: true } })
      return false
    }

    setInvalidErrors({ ...invalidErrors, newPasswordMatch: { ...invalidErrors.newPasswordMatch, active: false } })
    return true
  }

  return (
    <PlatformTemplate>
      <div className="w-full h-full flex flex-col items-center justify-center gap-5">
        <h1 className="text-slate-700 font-medium text-[20px]">Redefinir senha</h1>
        <div className="w-1/2 flex flex-col items-center gap-5">
          <div className="w-2/3 flex flex-col gap-2">
            <Input
              placeholder="Senha Atual"
              type="password"
              value={password.previousPassword}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword({ ...password, previousPassword: event.target.value })}
            />
            {invalidErrors.previousPassword.active && <p className="text-red-500 font-medium text-sm">{invalidErrors.previousPassword.text}</p>}
            <p className="w-full text-gray-500 text-sm">Para alterar sua senha, digite sua senha atual. Isso confirma sua identidade e ajuda a proteger sua conta contra acessos não autorizados.</p>
          </div>
          <div className="w-2/3 flex flex-col gap-2">
            <Input
              placeholder="Nova Senha"
              type="password"
              value={password.newPassword}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword({ ...password, newPassword: event.target.value })}
            />
            <Input
              placeholder="Repita a nova senha"
              type="password"
              value={password.repeatNewPassword}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword({ ...password, repeatNewPassword: event.target.value })}
            />
            {invalidErrors.newPasswordMatch.active && <p className="text-red-500 font-medium text-sm">{invalidErrors.newPasswordMatch.text}</p>}
            <p className="w-full text-gray-500 text-sm">Para alterar sua senha, insira a nova senha e, em seguida, repita-a no campo de confirmação. Isso garante que a senha foi digitada corretamente e evita erros.</p>
          </div>
        </div>
        {!isSubmitNewPassword ? <Button variant="default" onClick={submitNewPassword}>Atualizar senha</Button> : <Loader2 className="mr-2 h-5 w-5 animate-spin" />}
      </div>
    </PlatformTemplate>
  )
}