import React from "react"

interface NavBarProps extends React.HTMLAttributes<HTMLDivElement> { children: React.ReactNode }

const NavBar = ({ children, ...props }: NavBarProps) => {
    return <div className={`w-full h-[6.5%] flex items-center justify-between px-5 bg-slate-900 ${props.className}`} {...props}>{children}</div>
}

const NavBarItem = ({ children, ...props }: NavBarProps) => {
    return <div className={`${props.className}`} {...props}>{children}</div>
}

export { NavBar, NavBarItem }