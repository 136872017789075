// => transactions.ts
import api from '../index'
import { HeadersAccessToken } from '../config'

/**
 * => Interface que define os parâmetros para obter transações.
 */
interface GetTransactionsProps {
    pageIndex?: number
    pageSize?: number
    transactionId?: string
    debtorName?: string
    creditorName?: string
    createDateStart?: string
    createDateEnd?: string
}

/**
 * => Função para obter transações com base nos parâmetros fornecidos.
 * @param params Parâmetros para filtragem de transações.
 * @returns Lista de transações ou erro.
 */
export const getTransactions = async (params: GetTransactionsProps) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const queryParams = {
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
        transactionId: params.transactionId,
        debtorName: params.debtorName,
        creditorName: params.creditorName,
        createDateStart: params.createDateStart,
        createDateEnd: params.createDateEnd,
    }

    const urlParams = Object.entries(queryParams)
        .filter(([_, value]) => (typeof value === 'string' ? value.trim() !== '' : value !== undefined && value !== null))
        .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
        .join('&')

    const url = `/v1/transaction/?${urlParams}`
    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para obter uma transação pelo ID.
 * @param transactionId ID da transação.
 * @returns Dados da transação ou erro.
 */
export const getTransactionById = async ({ transactionId }: { transactionId: string }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/transaction/${transactionId}`
    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}


/**
 * => Função para rastrear uma transação pela identificação única.
 * @param transactionId Identificação da transação.
 * @returns Dados de rastreamento da transação ou erro.
 */
export const getTransactionTrackingById = async ({ transactionId }: { transactionId: string }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/transaction/${transactionId}/tracking`
    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para rastrear uma transação pela identificação end-to-end.
 * @param endToEndIdentification Identificação end-to-end da transação.
 * @returns Dados de rastreamento da transação ou erro.
 */
export const getTransactionTrackingByEndToEndIdentification = async (endToEndIdentification: string) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/transaction/tracking/${endToEndIdentification}`
    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}
