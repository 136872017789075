import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getRiskRules } from "../../../api/endpoints/risk-rules"
import { PlatformTemplate } from "../../../components/layout/template"
import { Plus, Ruler } from "lucide-react"
import { Skeleton } from "../../../components/ui/skeleton"
import { Button } from "../../../components/ui/button"
import TableRiskRules from "./_components/table-risk-rules"

export type RiskRuleProps = {
    riskRuleId: string
    scope: string
    type: string
    name: string
    description: string
    expression: string
    action: string
    createDate: string
    updateDate: string
    active: boolean
}

export type Filters = {
    id: string
    scope: string
    type: string
    name: string
    expression: string
    action: string
    createDateStart: string
    createDateEnd: string
    updateDateStart: string
    updateDateEnd: string
}

export default function RiskRules() {
    const navigate = useNavigate()
    const [riskRules, setRiskRules] = useState<RiskRuleProps[]>()
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [filters, setFilters] = useState<Filters>({
        id: '',
        scope: '',
        type: '',
        name: '',
        expression: '',
        action: '',
        createDateStart: '',
        createDateEnd: '',
        updateDateStart: '',
        updateDateEnd: '',
    })
    const [appliedFilters, setAppliedFilters] = useState<Filters>(filters)

    const fetchRiskRules = async (pageIndex: number, pageSize: number, filters: Filters) => {
        const response = await getRiskRules({ pageIndex, pageSize, ...filters })
        setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
        if (response?.data?.items) {
            setRiskRules(response?.data?.items)
        } else {
            setRiskRules([])
        }
    }

    useEffect(() => {
        fetchRiskRules(pageIndex, pageSize, appliedFilters)
    }, [pageIndex, pageSize, appliedFilters])

    return (
        <PlatformTemplate>
            <div className="w-full h-full flex flex-col">
                <div className="w-full flex items-center justify-between">
                    <div className="w-full flex items-center py-3 gap-2">
                        <Ruler size={20} />
                        <h1 className="text-[20px] font-semibold">Regras de Risco</h1>
                    </div>
                    <div className="flex items-center gap-3">
                        <Button variant="default" className="gap-1" onClick={() => navigate("/risk-rules/create")}>
                            <Plus size={20} />
                        </Button>
                    </div>
                </div>
                {riskRules ? (
                    <TableRiskRules
                        riskRules={riskRules}
                        setRiskRules={setRiskRules}
                        pageIndex={pageIndex}
                        setPageIndex={setPageIndex}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalPages={totalPages}
                        setTotalPages={setTotalPages}
                        filters={filters}
                        setFilters={setFilters}
                        appliedFilters={appliedFilters}
                        setAppliedFilters={setAppliedFilters}
                    />
                ) : (
                    <Skeleton className="w-full h-full" />
                )}
            </div>
        </PlatformTemplate>
    )
}
