import { ChangeEvent } from "react";

// Função para lidar com a tecla "Enter"
export const handleEnterKeyDown = (callback: () => void) => {
  return (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      callback()
    }
  }
}

// Função para lidar com a tecla "Esc"
export const handleEscapeKeyDown = (callback: () => void) => {
  return (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      callback()
    }
  }
}

// Função para lidar com cliques do mouse fora de um elemento específico
export const handleClickOutside = (ref: React.RefObject<any>, callback: () => void) => {
  return (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback()
    }
  }
}

// Função para lidar com mudanças em inputs controlados
export const handleInputChange = (setter: React.Dispatch<React.SetStateAction<any>>) => {
  return (event: ChangeEvent<HTMLInputElement>) => {
    setter(event.target.value)
  }
}