import { useNavigate } from "react-router-dom"
import { ChangeEvent, useState } from "react"
import { trimSpaces } from "../../../utils/helpers/trimSpaces"
import { handleEnterKeyDown } from "../../../utils/helpers/keyboard"
import { version } from "../../../version"
import { Input } from "../../../components/ui/input"
import { Button } from "../../../components/ui/button"
import { CircleUser, Loader2, Mail } from "lucide-react"
import { saveUserDataToLocalStorage } from "../../../utils/storage/auth"
import { IsEmptyObject } from "../../../utils/helpers/validate"
import PopUpAuth from "./_components/pop-up-auth"
import api from "../../../api/index"
import Swal from "sweetalert2"
import Logo from "../../../assets/logo.svg"

interface popUpProps {
    active: boolean
    content: 'terms' | 'policy'
}

export default function Login() {
    const navigate = useNavigate()
    const [isSubmit, setIsSubmit] = useState<boolean>(false)
    const [formLogin, setFormLogin] = useState({ userName: '', password: '' })
    const [popUp, setPopUp] = useState<popUpProps>({ content: 'terms', active: false })

    const handleSubmitAuthLogin = async () => {
        setIsSubmit(true)

        if (IsEmptyObject(formLogin)) {
            Swal.fire({
                icon: "error",
                title: "Preencha todos os campos obrigatórios.",
                confirmButtonColor: "var(--gray-500)",
            })

            return setIsSubmit(false)
        }

        try {
            const form = new URLSearchParams()
            form.append('grant_type', 'password')
            form.append('user_name', formLogin.userName)
            form.append('password', formLogin.password)

            const response = await api.post('/v1/auth', form.toString(), {
                headers: {
                    'Authorization': '',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })

            if (response.status === 200 && response.data.access_token) {
                saveUserDataToLocalStorage(response.data)
                navigate('/owner')
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Erro desconhecido. Tente novamente.",
                    confirmButtonColor: "var(--gray-500)",
                })
            }
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    title: "Usuário ou senha incorretos. Tente novamente.",
                    confirmButtonColor: "var(--gray-500)",
                })
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Ocorreu um erro. Tente novamente mais tarde.",
                    confirmButtonColor: "var(--gray-500)",
                })
            }
        } finally {
            setIsSubmit(false)
        }
    }

    return (
        <div className="w-full h-screen flex bg-neutral-100">
            {popUp.active && <PopUpAuth setPopUp={setPopUp} content={popUp.content} />}
            <div className="w-1/2 h-screen bg-zinc-900 flex flex-col items-start justify-between p-10 select-none">
                <img className="w-1/4 h-auto object-cover" src={Logo} alt="Logo Cyclobank" />
                <div className="flex gap-1 flex-col items-start">
                    <p className="text-white text-xl">Gestão de contas - PIX Indireto</p>
                    <p className="text-gray-400 text-base">Versão: {version}</p>
                </div>
            </div>
            <main className="w-1/2 h-screen flex items-center justify-center">
                <div className="w-3/6 h-3/6 flex flex-col items-center justify-between">
                    <div className="flex flex-col items-center justify-center gap-2">
                        <h1 className="text-3xl font-bold">
                            CycloBank Login
                        </h1>
                        <p className="text-gray-500 text-center">
                            Acesso restrito: insira suas credenciais de acesso.
                        </p>
                    </div>
                    <div className="w-full flex flex-col gap-4">
                        <Input
                            disabled={isSubmit}
                            value={formLogin.userName}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setFormLogin({ ...formLogin, userName: trimSpaces(event.target.value) })}
                            onKeyDown={handleEnterKeyDown(handleSubmitAuthLogin)}
                            type="text"
                            className="py-5 px-3 bg-white text-base"
                            placeholder="Nome de usuário"
                        />
                        <Input
                            disabled={isSubmit}
                            value={formLogin.password}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setFormLogin({ ...formLogin, password: trimSpaces(event.target.value) })}
                            onKeyDown={handleEnterKeyDown(handleSubmitAuthLogin)}
                            type="password"
                            className="py-5 px-3 bg-white text-base"
                            placeholder="Senha"
                        />
                        <Button onClick={() => handleSubmitAuthLogin()} disabled={isSubmit} variant="default" className="py-5 px-3 text-base">
                            {!isSubmit ?
                                <div className="flex items-center justify-center gap-2"><CircleUser size={15} />Entrar com user-name</div> :
                                <div className="flex items-center justify-center gap-2"><Loader2 className="mr-2 h-4 w-4 animate-spin" />Por favor, aguarde</div>
                            }
                        </Button>
                    </div>
                    <div className="w-full flex flex-col items-center justify-center gap-5">
                        <Button disabled={isSubmit} variant="outline" className="w-full py-5 px-3 bg-blue-900 text-white hover:bg-blue-950 hover:text-white">
                            Entrar com Microsoft
                        </Button>
                        <p className="w-4/5 text-center text-gray-500 text-sm">
                            Ao clicar em continuar, você concorda com nossos <span className="cursor-pointer underline hover:text-gray-900" onClick={() => setPopUp({ active: true, content: 'terms' })}>Termos de Serviço</span> e <span onClick={() => setPopUp({ active: true, content: 'policy' })} className="cursor-pointer underline hover:text-gray-900">Política de Privacidade.</span>.
                        </p>
                    </div>
                </div>
            </main>
        </div>
    )
}