import { useEffect, useState } from "react"
import { getAccounts } from "../../../api/endpoints/account"
import { Skeleton } from "../../../components/ui/skeleton"
import { PlatformTemplate } from "../../../components/layout/template"
import TableAccounts from "./_components/table-account"
import { Landmark } from "lucide-react"

export type AccountProps = {
    accountId: string
    branch: string
    accountNumber: string
    type: string
    balance: number
    status: string
    owner: {
        id: string
        name: string
    }
}

export type Filters = {
    accountId: string
    accountNumber: string
    ownerId: string
    ownerName: string
    ownerDocument: string
    status: '' | 'Active' | 'Inactive' | 'Blocked'
    type: '' | 'checkingAccount' | 'salary' | 'savings' | 'transactional'
}

export default function Accounts() {
    const [accounts, setAccounts] = useState<AccountProps[]>()
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [filters, setFilters] = useState<Filters>({
        accountId: '',
        accountNumber: '',
        ownerId: '',
        ownerName: '',
        ownerDocument: '',
        status: '',
        type: '',
    })
    const [appliedFilters, setAppliedFilters] = useState<Filters>(filters)

    const fetchAccounts = async (pageIndex: number, pageSize: number, filters: Filters) => {
        const response: any = await getAccounts({ pageIndex, pageSize, ...filters })
        setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
        if (response?.data?.items) {
            setAccounts(response?.data?.items)
        } else {
            setAccounts([])
        }
    }

    useEffect(() => {
        fetchAccounts(pageIndex, pageSize, appliedFilters)
    }, [pageIndex, pageSize, appliedFilters])

    return (
        <PlatformTemplate>
            <div className="w-full h-full flex flex-col">
                <div className="w-full flex items-center py-3 gap-2">
                    <Landmark size={20} />
                    <h1 className="text-[20px] font-semibold">Contas</h1>
                </div>
                {accounts ? (
                    <TableAccounts
                        accounts={accounts}
                        setAccounts={setAccounts}
                        pageIndex={pageIndex}
                        setPageIndex={setPageIndex}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalPages={totalPages}
                        setTotalPages={setTotalPages}
                        filters={filters}
                        setFilters={setFilters}
                        appliedFilters={appliedFilters}
                        setAppliedFilters={setAppliedFilters}
                    />
                ) : (
                    <Skeleton className="w-full h-full" />
                )}
            </div>
        </PlatformTemplate>
    )
}
