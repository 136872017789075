import { useEffect, useState } from "react"
import { getAccountActivityById } from "../../../../../api/endpoints/account"
import { getPath } from "../../../../../utils/helpers/path"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "../../../../../components/ui/table"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { Button } from "../../../../../components/ui/button"
import { TabsAccount } from "../_components/tabs-account"

export default function AccountActivity() {
  const [account, setAccount] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const fetchAccountById = async (page: number, pageSize: number) => {
    setIsLoading(true)
    const response: any = await getAccountActivityById({
      accountId: getPath(),
      pageIndex: page,
      pageSize: pageSize
    })

    if (response?.data?.items) {
      setAccount(response?.data?.items)
      setTotalItems(response?.data?.totalCount)
      return setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAccountById(currentPage, itemsPerPage)
  }, [currentPage, itemsPerPage])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsAccount value="activities" />
          {!isLoading ?
            <AccountActivitiesContent
              account={account}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            /> : <Skeleton className="w-full h-full" />
          }
        </div>
      </div>
    </PlatformTemplate>
  )
}

const AccountActivitiesContent = ({ account, currentPage, itemsPerPage, totalItems, totalPages, onPageChange }:
  { account: any[], currentPage: number, itemsPerPage: number, totalItems: number, totalPages: number, onPageChange: (page: number) => void }) => {

  return (
    <div>
      <Table>
        <TableCaption>Lista de Atividades</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Data</TableHead>
            <TableHead>Ação</TableHead>
            <TableHead>Descrição</TableHead>
            <TableHead>Usuário</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {account.map((item: any) => (
            <TableRow key={item.accountId}>
              <TableCell className="font-medium">{convertUTCToUserTimezone(item.createDate, getUserTimezoneOffset())}</TableCell>
              <TableCell>{item.action}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>{item.userName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flex justify-between items-center mt-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
          disabled={currentPage === 1}
        >
          Anterior
        </Button>
        <span>
          Página {currentPage} de {totalPages}
        </span>
        <Button
          variant="outline"
          size="sm"
          onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Próximo
        </Button>
      </div>
    </div>
  )
}
