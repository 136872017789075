import React, { useEffect, useState } from "react";
import { PlatformTemplate } from "../../../../../components/layout/template";
import { getOwnerBlockById, unblockOwner } from "../../../../../api/endpoints/owner";
import { getPath } from "../../../../../utils/helpers/path";
import { AlertCircle, CheckCircle, User, Calendar, Key, XCircle, LockKeyholeOpen, Loader2, Undo2 } from 'lucide-react';
import { Button } from "../../../../../components/ui/button";
import { Skeleton } from "../../../../../components/ui/skeleton";
import { useNavigate } from "react-router-dom";
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date";
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "../../../../../components/ui/alert-dialog";
import { Textarea } from "../../../../../components/ui/textarea";
import Swal from "sweetalert2";

interface OwnerBlockedDetailsProps {
  ownerBlockId: string,
  ownerId: string,
  blockReason: string,
  blockDate: string,
  blockUserId: string,
  unblockReason: string,
  unblockDate: string,
  unblockUserId: string,
  active: boolean
}

export default function OwnerBlockDetails() {
  const navigate = useNavigate();
  const [unblockReason, setUnblockReason] = useState<string>('');
  const [blockedOwner, setBlockedOwner] = useState<OwnerBlockedDetailsProps>();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const getBlockedOwnerById = async () => {
    const response = await getOwnerBlockById(getPath());
    if (response?.data) {
      setBlockedOwner(response.data);
    }
  }

  useEffect(() => {
    getBlockedOwnerById();
  }, []);

  const handleUnblockOwner = async () => {
    setIsSubmit(true)
    const response = await unblockOwner({ id: blockedOwner?.ownerId, blockId: getPath(), reason: unblockReason });
    if (response.status === 200) {
      setIsSubmit(false)
      Swal.fire({
        icon: "success",
        title: "Cliente Desbloqueado com Sucesso.",
        confirmButtonColor: "var(--gray-500)",
      })
      return navigate('/owner/blocked')
    }

    Swal.fire({
      icon: "error",
      title: "Não foi possível realizar a operação.",
      confirmButtonColor: "var(--gray-500)",
    })
    return setIsSubmit(false)
  }

  return (
    <PlatformTemplate>
      {blockedOwner ? (
        <div className="h-full w-full flex justify-center items-center py-10">
          <div className="w-11/12 lg:w-3/4 flex flex-col gap-5">
            <div className="container mx-auto p-4">
              <div className="bg-white border border-neutral-200 shadow-lg rounded-xl p-8 transition-all duration-300 hover:shadow-xl">
                <div className="w-full h-24 flex items-center justify-between">
                  <div className="flex items-center justify-center gap-5">
                    <Button onClick={() => navigate('/owner/blocked')} variant="outline">
                      <Undo2 size={20} />
                    </Button>
                    <h1 className="text-xl font-bold text-center text-gray-900">Detalhes {blockedOwner.active && <span>de Bloqueio</span>}</h1>
                  </div>
                  {!isSubmit ?
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        {blockedOwner.active &&
                          <Button className="bg-green-600 hover:bg-green-900 gap-2">
                            <LockKeyholeOpen size={15} />Desbloquear
                          </Button>}
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle className='flex items-center gap-2'>Desbloquear</AlertDialogTitle>
                          <AlertDialogDescription className='flex flex-col gap-2'>
                            <p>Em poucas palavras, faça uma justificativa do desbloqueio.</p>
                            <Textarea rows={5} onChange={(event) => setUnblockReason(event.target.value)} />
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancelar</AlertDialogCancel>
                          <AlertDialogAction onClick={handleUnblockOwner}>Enviar</AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                    :
                    <div className="w-32 flex items-center justify-center">
                      <Loader2 className="w-8 h-8 animate-spin" />
                    </div>
                  }
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                  <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg shadow-sm transition-all duration-200 hover:bg-gray-100">
                    <User className="text-blue-500 w-6 h-6" />
                    <div>
                      <p className="font-semibold text-gray-600">ID da Conta:</p>
                      <p className="text-gray-800">{blockedOwner.ownerId}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg shadow-sm transition-all duration-200 hover:bg-gray-100">
                    {blockedOwner.active ? (
                      <CheckCircle className="text-red-500 w-6 h-6" />
                    ) : (
                      <XCircle className="text-green-500 w-6 h-6" />
                    )}
                    <div>
                      <p className="font-semibold text-gray-600">Status:</p>
                      <p className="text-gray-800">{blockedOwner.active ? "Bloqueado" : "Desbloqueado"}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg shadow-sm transition-all duration-200 hover:bg-gray-100">
                    <Key className="text-yellow-500 w-6 h-6" />
                    <div>
                      <p className="font-semibold text-gray-600">ID do Usuário que Bloqueou:</p>
                      <p className="text-gray-800">{blockedOwner.blockUserId}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg shadow-sm transition-all duration-200 hover:bg-gray-100">
                    <Key className="text-yellow-500 w-6 h-6" />
                    <div>
                      <p className="font-semibold text-gray-600">ID do Usuário que Desbloqueou:</p>
                      <p className="text-gray-800">{blockedOwner.unblockUserId ? blockedOwner.unblockUserId : "N/A"}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg shadow-sm transition-all duration-200 hover:bg-gray-100">
                    <Calendar className="text-red-500 w-6 h-6" />
                    <div>
                      <p className="font-semibold text-gray-600">Data do Bloqueio:</p>
                      <p className="text-gray-800">{convertUTCToUserTimezone(blockedOwner.blockDate, getUserTimezoneOffset())}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg shadow-sm transition-all duration-200 hover:bg-gray-100">
                    <Calendar className="text-green-500 w-6 h-6" />
                    <div>
                      <p className="font-semibold text-gray-600">Data do Desbloqueio:</p>
                      <p className="text-gray-800">{blockedOwner.unblockDate ? convertUTCToUserTimezone(blockedOwner.unblockDate, getUserTimezoneOffset()) : "N/A"}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg shadow-sm transition-all duration-200 hover:bg-gray-100">
                    <AlertCircle className="text-red-500 w-6 h-6" />
                    <div>
                      <p className="font-semibold text-gray-600">Motivo do Bloqueio:</p>
                      <p className="text-gray-800">{blockedOwner.blockReason}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg shadow-sm transition-all duration-200 hover:bg-gray-100">
                    <XCircle className="text-green-500 w-6 h-6" />
                    <div>
                      <p className="font-semibold text-gray-600">Motivo do Desbloqueio:</p>
                      <p className="text-gray-800">{blockedOwner.unblockReason ? blockedOwner.unblockReason : "N/A"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Skeleton className="w-full h-full" />
      )}
    </PlatformTemplate>
  );
}
