import React, { useEffect, useState } from "react"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { getAlertById } from "../../../../../api/endpoints/alert"
import { getPath } from "../../../../../utils/helpers/path"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import { User, Calendar, CheckCircle } from "lucide-react"
import { TabsAlert } from "../_components/tabs-alert"

interface HistoricProps {
  id: string
  alertId: string
  action: string
  oldStatus: string
  newStatus: string
  userId: string
  userName: string
  createDate: string
}

export default function AlertHistoric() {
  const [historic, setHistoric] = useState<HistoricProps[] | null>(null)

  const fetchAlertHistoric = async () => {
    const response = await getAlertById({ alertId: getPath() })
    if (response?.data?.histories) {
      setHistoric(response.data.histories)
    } else {
      setHistoric([])
    }
  }

  useEffect(() => {
    fetchAlertHistoric()
  }, [])

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsAlert value="historic" />
          {historic ? (
            <div className="w-full h-full my-5">
              <div className="bg-white shadow-lg rounded-lg p-6">
                <h2 className="text-xl font-bold mb-6">Histórico de Alertas</h2>
                <ul className="space-y-6">
                  {historic.map((item: HistoricProps) => (
                    <li
                      key={item.id}
                      className="p-4 border border-gray-300 rounded-lg flex flex-col gap-2"
                    >
                      <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2 text-base font-medium text-gray-800 mb-2">
                          <CheckCircle size={15} className="text-green-600" />
                          Ação: {item.action}
                        </div>
                        <div className="flex items-center gap-2 text-gray-500 text-sm">
                          <Calendar size={15} />
                          {convertUTCToUserTimezone((item.createDate), getUserTimezoneOffset())}
                        </div>
                      </div>
                      <div className="text-gray-600 text-sm flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                          <User size={15} className="text-blue-500" />
                          <strong>Usuário:</strong> {item.userName || "N/A"}
                        </div>
                        <div className="flex items-center gap-2">
                          <strong>Status Antigo:</strong> {item.oldStatus || "N/A"}
                        </div>
                        <div className="flex items-center gap-2">
                          <strong>Novo Status:</strong> {item.newStatus}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <Skeleton className="w-full h-full" />
          )}
        </div>
      </div>
    </PlatformTemplate>
  )
}
