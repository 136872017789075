import { useEffect, useState } from "react"
import { getAlerts } from "../../../api/endpoints/alert"
import { Skeleton } from "../../../components/ui/skeleton"
import { PlatformTemplate } from "../../../components/layout/template"
import TableAlerts from "./_components/table-alert"
import { CircleAlert } from "lucide-react"

export type AlertProps = {
  alertId: string
  scope: string
  type: string
  referenceId: string
  accountId: string
  accountNumber: string
  ownerId: string
  ownerName: string
  priority: string
  status: string
  expression: string
  value: string
  resolution: string
  resolutionDate: string
  openedUserId: string
  openedUserName: string | null
  closedUserId: string
  closedUserName: string | null
  comments: string | null
  histories: string | null
  createDate: string
  updateDate: string
}

export type Filters = {
  alertId: string
  referenceId: string
  status: string
  type: string
  scope: string
  priority: string
  accountId: string
  ownerId: string
  openedUserId: string
  closedUserId: string
  createDateStart: string
  createDateEnd: string
  updateDateStart: string
  updateDateEnd: string
  resolutionDateStart: string
  resolutionDateEnd: string
}

export default function Alerts() {
  const [alerts, setAlerts] = useState<AlertProps[]>()
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [filters, setFilters] = useState<Filters>({
    alertId: '',
    referenceId: '',
    status: '',
    type: '',
    scope: '',
    priority: '',
    accountId: '',
    ownerId: '',
    openedUserId: '',
    closedUserId: '',
    createDateStart: '',
    createDateEnd: '',
    updateDateStart: '',
    updateDateEnd: '',
    resolutionDateStart: '',
    resolutionDateEnd: '',
  })
  const [appliedFilters, setAppliedFilters] = useState<Filters>(filters)

  const fetchAlerts = async (pageIndex: number, pageSize: number, filters: Filters) => {
    const response = await getAlerts({ pageIndex, pageSize, ...filters })
    setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
    if (response?.data?.items) {
      setAlerts(response?.data?.items)
    } else {
      setAlerts([])
    }
  }

  useEffect(() => {
    fetchAlerts(pageIndex, pageSize, appliedFilters)
  }, [pageIndex, pageSize, appliedFilters])

  return (
    <PlatformTemplate>
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex items-center py-3 gap-2">
          <CircleAlert size={20} />
          <h1 className="text-[20px] font-semibold">Alertas</h1>
        </div>
        {alerts ? (
          <TableAlerts
            alerts={alerts}
            setAlerts={setAlerts}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            filters={filters}
            setFilters={setFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
        ) : (
          <Skeleton className="w-full h-full" />
        )}
      </div>
    </PlatformTemplate>
  )
}
