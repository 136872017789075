import { useEffect, useState } from "react"
import { getPath } from "../../../../../../utils/helpers/path"
import { Input } from "../../../../../../components/ui/input"
import { PlatformTemplate } from "../../../../../../components/layout/template"
import { PreviewKey } from "./_components/preview-key"
import { IsEmpty } from "../../../../../../utils/helpers/validate"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../../../../components/ui/accordion"
import { Button } from "../../../../../../components/ui/button"
import { getOwnersById, getSettlementAccountsPreviewKey } from "../../../../../../api/endpoints/owner"
import { trimSpaces } from "../../../../../../utils/helpers/trimSpaces"
import { Loader2 } from "lucide-react"

export type PreviewKeyProps = {
  name: string,
  documentNumber: string,
  documentType: string,
  accountNumber: string,
  branchNumber: string,
  accountType: string,
  bankIspb: string,
  bankCode: string,
  bankName: string,
  warning: string,
  error: null | string[]
}

export default function CreateBankByIdAndKey() {
  const [pixKey, setPixKey] = useState('')
  const [documentOwner, setDocumentOwner] = useState<string>('')
  const [isMatchDocuments, setIsMatchDocuments] = useState<boolean>(false)
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [previewKey, setPreviewKey] = useState<PreviewKeyProps | undefined>(undefined)
  const [isError, setIsError] = useState({
    isActive: false,
    message: ''
  })

  const getDocumentNumberByOwnerId = async () => {
    const response = await getOwnersById(getPath())
    if (response.status === 200) {
      return setDocumentOwner(response?.data?.documentNumber)
    }
  }

  useEffect(() => {
    getDocumentNumberByOwnerId()
  }, [])

  const handlePreviewKey = async () => {
    setIsError({ isActive: false, message: '' })
    setIsSubmit(true)

    if (IsEmpty(pixKey)) {
      setIsError({ isActive: true, message: 'Preencha o campo antes de enviar' })
      setIsSubmit(false)
      return null
    }

    const response = await getSettlementAccountsPreviewKey({ ownerId: getPath(), key: pixKey })

    if (response?.response?.status === 400) {
      setIsSubmit(false)
      setIsError({ isActive: true, message: 'Chave inválida!' })
      return null
    }

    await validateMatchDocuments({ documentKey: await response.data.documentNumber })

    setPreviewKey(response?.data)
    setIsSubmit(false)

    return null
  }

  const validateMatchDocuments = async ({ documentKey }: { documentKey: string }) => {
    console.log(documentKey)
    console.log(documentOwner)
    if (documentKey === documentOwner) {
      return setIsMatchDocuments(true)
    }

    return setIsMatchDocuments(false)
  }

  const handleNewApiKey = () => {
    setPreviewKey(undefined)
    setIsMatchDocuments(false)
    setPixKey('')
  }

  return (
    <PlatformTemplate>
      <div className="w-full h-full flex flex-col items-center">
        <div className="w-1/2 flex flex-col gap-14 items-center">
          <h1 className="text-[20px] font-semibold">Vincular novo Banco</h1>
          <div className="w-full flex flex-col gap-5">
            <div className="flex items-center justify-between">
              <h1 className="font-semibold">Insira a chave pix</h1>
              {!isMatchDocuments && previewKey &&
                <Button onClick={handleNewApiKey} variant='default'>Tentar outra chave</Button>
              }
            </div>
            <div className="flex flex-col gap-2">
              <Input
                disabled={documentOwner === '' || previewKey !== undefined}
                value={pixKey}
                onChange={(e) => setPixKey(trimSpaces(e.target.value))}
                placeholder="Chave pix..."
                className={`${isError.isActive && "border-2 border-red-400"}`}
              />
              {isError.isActive && <p className="text-red-400 font-medium text-xs">{isError.message}</p>}
            </div>
            {!previewKey &&
              <div className="flex justify-center">
                {!isSubmit ? <Button onClick={() => handlePreviewKey()}>Validar Chave</Button> : <Loader2 className="mr-2 h-8 w-8 animate-spin" />}
              </div>
            }
            {previewKey && <PreviewKey isMatchDocuments={isMatchDocuments} pixKey={pixKey} previewKey={previewKey} />}
          </div>
          <div className="w-full flex flex-col gap-5">
            <h1 className="font-semibold text-slate-500">Termos de Privacidade de Dados</h1>
            <Accordion type="single" collapsible className="w-full text-slate-400">
              <AccordionItem value="item-1">
                <AccordionTrigger>Verificação do Solicitante</AccordionTrigger>
                <AccordionContent>
                  <li>Certifique-se de que a solicitação da chave Pix seja feita por uma instituição ou pessoa de confiança.</li>
                  <li>Verifique a identidade da pessoa ou instituição que está solicitando a chave Pix.</li>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2">
                <AccordionTrigger>Comunicação Segura</AccordionTrigger>
                <AccordionContent>
                  <li>Utilize canais seguros para enviar sua chave Pix, como aplicativos de mensagens criptografadas ou sistemas internos do banco.</li>
                  <li>Evite compartilhar sua chave Pix em redes sociais, e-mails ou mensagens de texto não seguras.</li>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-3">
                <AccordionTrigger>Autenticidade da Solicitação</AccordionTrigger>
                <AccordionContent>
                  <li>Confirme a legitimidade da solicitação através de contatos oficiais do banco ou instituição financeira.</li>
                  <li>Não forneça sua chave Pix em resposta a solicitações inesperadas ou suspeitas.</li>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-4">
                <AccordionTrigger>Confidencialidade</AccordionTrigger>
                <AccordionContent>
                  <li>Mantenha sua chave Pix em sigilo, compartilhando-a apenas quando necessário e com partes confiáveis.</li>
                  <li>Evite anotar sua chave Pix em locais de fácil acesso ou compartilhá-la com terceiros sem necessidade.</li>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-5">
                <AccordionTrigger>Monitoramento</AccordionTrigger>
                <AccordionContent>
                  <li>Acompanhe regularmente suas transações bancárias para identificar qualquer atividade suspeita.</li>
                  <li>Utilize notificações e alertas do banco para ser informado sobre movimentações em sua conta.</li>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-6">
                <AccordionTrigger>Atualização de Dados</AccordionTrigger>
                <AccordionContent>
                  <li>Caso suspeite que sua chave Pix tenha sido comprometida, entre em contato imediatamente com seu banco para atualizá-la.</li>
                  <li>Mantenha seus dados de contato atualizados junto ao banco para facilitar a comunicação em caso de emergência.</li>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </PlatformTemplate>
  )
}