import { useEffect, useState } from "react"
import { getUserById, updateUserById } from "../../../../api/endpoints/auth"
import { getPath } from "../../../../utils/helpers/path"
import { PlatformTemplate } from "../../../../components/layout/template"
import { Skeleton } from "../../../../components/ui/skeleton"
import { Card, CardContent } from "../../../../components/ui/card"
import { Input } from "../../../../components/ui/input"
import { Button } from "../../../../components/ui/button"
import { CircleUserRound, Fingerprint, Info, Loader2, Lock, Mail, ScanEye, ShieldAlert } from "lucide-react"
import { UserByIdNewPassword } from "./_components/new-password"

export default function UserById() {
  const [user, setUser] = useState()

  const fetchUserById = async () => {
    const response = await getUserById({ userId: getPath() })
    setUser(response?.data)
  }

  useEffect(() => {
    fetchUserById()
  }, [])

  return (
    <PlatformTemplate>
      {user ? <UserByIdContent user={user} /> : <Skeleton className="w-full h-full" />}
    </PlatformTemplate>
  )
}

const UserByIdContent = ({ user }: { user: any }) => {
  const [newEmail, setNewEmail] = useState<string>(user.email)
  const [isNewPassword, setIsNewPassword] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const handleSaveNewEmail = async () => {
    const response = await updateUserById(user.id, { email: newEmail, roles: user.roles })

    if (response.status === 200) {
      return setIsEdit(false)
    }
  }

  return (
    <div className="h-full w-full flex justify-center">
      <div className="h-full w-3/4 flex flex-col items-center justify-center gap-5">
        <Card className="w-2/3">
          <CardContent className="py-5">
            <div className="flex flex-col gap-10">
              <h1 className="font-semibold text-[18px] text-slate-700 flex items-center gap-2"><Info size={20} />Informações de Usuário</h1>
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between text-gray-500">
                  <p className="font-medium flex items-center gap-2"><CircleUserRound size={20} />Nome de Usuário</p>
                  <p>{user.userName}</p>
                </div>
                <div className="flex items-center justify-between text-gray-500">
                  <p className="font-medium flex items-center gap-2"><ScanEye size={20} />Tipo de Usuário</p>
                  <p>{user.type}</p>
                </div>
                <div className="flex items-center justify-between text-gray-500">
                  <p className="font-medium flex items-center gap-2"><Mail size={20} />E-mail</p>
                  <div className="w-[400px] flex items-center gap-2">
                    <Input
                      className="w-full text-base text-gray-700"
                      value={newEmail}
                      disabled={!isEdit}
                      onChange={(event) => setNewEmail(event.target.value)}
                    />
                    {!isEdit ? <Button onClick={() => setIsEdit(true)}>Editar</Button> : <Button onClick={handleSaveNewEmail}>Salvar</Button>}
                  </div>
                </div>
                <div className="flex items-center justify-between text-gray-500">
                  <p className="font-medium flex items-center gap-2"><ShieldAlert size={20} />Acessos</p>
                  {user.roles.map((item: any) => (
                    <p>{item}</p>
                  ))}
                </div>
                <div className="flex items-center justify-between text-gray-500">
                  <p className="font-medium flex items-center gap-2"><Fingerprint size={20} />Identificador</p>
                  <p>{user.id}</p>
                </div>
              </div>
              <div className="flex items-center justify-end">
                <Button onClick={() => setIsNewPassword(true)} disabled={isNewPassword} variant="secondary" className="flex items-center justify-center gap-2"><Lock size={15} />Alterar a senha dessa conta</Button>
              </div>
            </div>
          </CardContent>
        </Card>
        {isNewPassword && <UserByIdNewPassword user={user} setIsNewPassword={setIsNewPassword} />}
      </div>
    </div>
  )
}