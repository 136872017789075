import React from "react"
import { useNavigate } from "react-router-dom"
import { getUserDataFromLocalStorage, logout } from "../../../utils/storage/auth"
import {
    NavBar,
    NavBarItem
} from "../navbar"
import {
    SideBar,
    SideBarItem,
    SideBarLink,
    SideBarTitle
} from "../sidebar"
import {
    ArrowLeftRight,
    Landmark,
    Users,
    LogOut,
    UserRoundSearch,
    CircleUserRound,
    ShieldAlert,
    TicketSlash,
    CircleAlert,
    Ruler,
} from "lucide-react"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "../../ui/alert-dialog";
import Logo from "../../../assets/logo.svg"
import { Button } from "../../ui/button"

const PlatformTemplate = ({ children }: { children?: React.ReactNode }) => {
    const navigate = useNavigate()
    const user: any = getUserDataFromLocalStorage()

    const handleLogoutUser = () => {
        logout()
        navigate("/auth")
    }

    return (
        <div className="w-full h-screen">
            <NavBar>
                <NavBarItem>
                    <img className="w-auto h-[35px]" src={Logo} alt="Logo Cyclobank" />
                </NavBarItem>
                <NavBarItem>
                    <p className="text-white font-extralight">Olá, {getUserDataFromLocalStorage()?.user.userName}</p>
                </NavBarItem>
            </NavBar>
            <div className="w-full h-[93.5%] flex">
                <SideBar>
                    <SideBarItem>
                        <SideBarTitle>Gestão</SideBarTitle>
                        <SideBarLink href="/owner"><Users size={15} />Clientes</SideBarLink>
                        <SideBarLink href="/account"><Landmark size={15} />Contas</SideBarLink>
                        <SideBarLink href="/transaction"><ArrowLeftRight size={15} />Transações</SideBarLink>
                    </SideBarItem>
                    <SideBarItem>
                        <SideBarTitle>Antifraude</SideBarTitle>
                        <SideBarLink href="/alert"><CircleAlert size={15} />Alertas</SideBarLink>
                        <SideBarLink href="/infraction"><ShieldAlert size={15} />Infrações</SideBarLink>
                        <SideBarLink href="/refund"><TicketSlash size={15} />MED</SideBarLink>
                        <SideBarLink href="/risk-rules"><Ruler size={15} />Regras de Risco</SideBarLink>
                    </SideBarItem>
                    <SideBarItem>
                        <SideBarTitle>Configurações</SideBarTitle>
                        {user?.user_type === "Super" &&
                            <SideBarLink href="/user"><UserRoundSearch size={15} />Usuários</SideBarLink>}
                        <SideBarLink href="/profile"><CircleUserRound size={15} />Minha Conta</SideBarLink>
                        <AlertDialog>
                            <AlertDialogTrigger asChild>
                                <Button variant="ghost" className="w-full py-1 px-2 font-normal cursor-pointer rounded-md flex items-center justify-start text-[14px] gap-2 hover:bg-slate-100">
                                    <LogOut size={15} />
                                    Sair da Conta
                                </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Tem certeza de que deseja sair?</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        Você pode ter alterações não salvas que serão perdidas se você sair sem salvar. Recomendamos que você salve suas alterações antes de sair para evitar a perda de dados.
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel>Cancelar</AlertDialogCancel>
                                    <AlertDialogAction onClick={() => handleLogoutUser()}>Continuar</AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </SideBarItem>
                </SideBar>
                <div className="flex-1 h-full py-14 px-5 overflow-y-auto">
                    {children}
                </div>
            </div>
        </div>
    )
}

export { PlatformTemplate }