// => Via CEP API
import axios from 'axios'

// => Criação da Instância
const api = axios.create({
    baseURL: `https://viacep.com.br/ws`,
})

// => Endpoint principal que retorna o endereço
export const getAddressByCep = async (cep: string) => {
    const response = await api.get(`/${cep}/json`)
    return response.data
}