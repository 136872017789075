import React, { useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table"
import { ChevronDown, Fingerprint, Library, MoreHorizontal, RefreshCw } from "lucide-react"
import { Button } from "../../../../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu"
import { UserProps, Filters } from "../page"
import { TableFiltersUser } from "./table-filter-users"
import { useReactTable, ColumnDef, SortingState, VisibilityState, getCoreRowModel, getPaginationRowModel, getSortedRowModel, flexRender } from "@tanstack/react-table"
import { Skeleton } from "../../../../components/ui/skeleton"
import { getUsers } from "../../../../api/endpoints/auth"
import { useNavigate } from "react-router-dom"

const ActionCell = ({ user }: { user: UserProps }) => {
  const navigate = useNavigate()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Abrir Menu</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Ações</DropdownMenuLabel>
        <DropdownMenuItem
          onClick={() => navigator.clipboard.writeText(user.id)}
        >
          <Fingerprint size={15} className="mr-2" />Copiar ID do Usuário
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate(`/user/${user.id}`)}><Library size={15} className="mr-2" />Ver Detalhamentos</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const columns: ColumnDef<UserProps>[] = [
  {
    accessorKey: "userName",
    header: "Nome de Usuário",
    cell: ({ row }) => (
      <div>{row.getValue("userName")}</div>
    ),
  },
  {
    accessorKey: "email",
    header: "Email",
    cell: ({ row }) => (
      <div>{row.getValue("email")}</div>
    ),
  },
  {
    accessorKey: "type",
    header: "Tipo",
    cell: ({ row }) => (
      <div>{row.getValue("type")}</div>
    ),
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <ActionCell user={row.original} />,
  },
]

export default function TableUsers({ users, setUsers, pageIndex, setPageIndex, pageSize, setPageSize, totalPages, setTotalPages, filters, setFilters, appliedFilters, setAppliedFilters }:
  {
    users: UserProps[],
    setUsers: any,
    pageIndex: number,
    setPageIndex: any,
    pageSize: number,
    setPageSize: any,
    totalPages: number,
    setTotalPages: any,
    filters: Filters,
    setFilters: any,
    appliedFilters: Filters,
    setAppliedFilters: any,
  }) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = useState({})

  const [onRefresh, setOnRefresh] = useState<boolean>(false)
  const table = useReactTable({
    data: users,
    columns,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility,
      rowSelection,
    },
  })

  const handleRefreshTable = async (pageIndex: number, pageSize: number, filters: Filters) => {
    setOnRefresh(true)
    const response = await getUsers({ pageIndex, pageSize, ...filters })
    setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
    if (response?.data?.items) {
      setUsers(response?.data?.items)
      setOnRefresh(false)
    } else {
      setUsers([])
      setOnRefresh(false)
    }
  }

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <div className="w-full h-auto flex items-center gap-2">
          <TableFiltersUser
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setTotalPages={setTotalPages}
            filters={filters}
            setFilters={setFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            setUsers={setUsers}
          />
        </div>
        <DropdownMenu>
          <div className="flex items-center justify-center gap-2">
            <Button onClick={() => handleRefreshTable(pageIndex, pageSize, filters)} variant="secondary">
              <RefreshCw size={20} className={`${onRefresh && 'animate-spin'}`} />
            </Button>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                Colunas <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
          </div>
          <DropdownMenuContent align="end">
            {table.getAllColumns().filter((column) => column.getCanHide()).map((column) => (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
              >
                {column.id}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        {!onRefresh ?
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    Sem resultados
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table> :
          <Skeleton className="w-full h-[525px]" />}
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="font-semibold text-sm text-zinc-600">
          {pageIndex} de {totalPages}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPageIndex((old: number) => Math.max(old - 1, 1))}
            disabled={pageIndex === 1}
          >
            Anterior
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPageIndex((old: number) => old + 1)}
            disabled={pageIndex === totalPages}
          >
            Próximo
          </Button>
        </div>
      </div>
    </div>
  )
}
