import { useEffect, useState } from "react"
import { getTransactionTrackingById } from "../../../../../api/endpoints/transaction"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import { Card, CardContent } from "../../../../../components/ui/card"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { getPath } from "../../../../../utils/helpers/path"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../../../../components/ui/accordion"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { TabsTransaction } from "../_components/tabs-transaction"

export default function TrackingTrajectory() {
  const [tracking, setTracking] = useState()
  const transactionId = getPath()

  const fetchtrackingTracking = async () => {
    const response = await getTransactionTrackingById({ transactionId: transactionId })
    if (response?.data) {
      return setTracking(response?.data)
    }
  }

  useEffect(() => {
    fetchtrackingTracking()
  }, [])

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsTransaction value="trajectory" />
          {tracking ? <TrackingContent tracking={tracking} /> : <Skeleton className="w-full h-full" />}
        </div>
      </div>
    </PlatformTemplate>
  )
}

const TrackingContent = ({ tracking }: { tracking: any }) => {
  return (
    <div className="flex flex-col gap-5 py-5">
      <div>
        {tracking.map((item: any, index: number) => (
          <div key={index}>
            {index !== 0 && (
              <div className="h-5 px-10">
                <div className="w-1 h-full bg-gray-300"></div>
              </div>
            )}
            <Card>
              <CardContent className="flex items-center justify-between py-3 px-5">
                <Accordion type="single" collapsible className="w-full">
                  <AccordionItem value="item-1">
                    <AccordionTrigger className="flex items-center gap-5">
                      <div className="w-full flex items-center justify-between">
                        <p className="text-gray-700">{item.message}</p>
                        <p className="font-semibold">{convertUTCToUserTimezone(item.createDate, getUserTimezoneOffset())}</p>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="w-full flex flex-col gap-6 p-4 bg-white">
                        <div className="w-full flex justify-between items-center gap-5 border-b pb-2">
                          <h3 className="font-semibold text-gray-700">E2E:</h3>
                          <p className="text-gray-600">{item.originalEndToEndIdentification}</p>
                        </div>
                        <div className="w-full flex justify-between items-center gap-5 border-b pb-2">
                          <h3 className="font-semibold text-gray-700">ID:</h3>
                          <p className="text-gray-600">{item.id}</p>
                        </div>
                        <div className="w-full flex justify-between items-center gap-5 border-b pb-2">
                          <h3 className="font-semibold text-gray-700">Detalhamento:</h3>
                          <p className="w-1/2 text-end text-gray-600">{item.details}</p>
                        </div>
                        <div className="w-full flex justify-between items-center gap-5 border-b pb-2">
                          <h3 className="font-semibold text-gray-700">Domínio:</h3>
                          <p className="text-gray-600">{item.domain}</p>
                        </div>
                        <div className="w-full flex justify-between items-center gap-5">
                          <h3 className="font-semibold text-gray-700">Tipo:</h3>
                          <p className="text-gray-600">{item.type}</p>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
    </div>
  )
}