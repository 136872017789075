// => api.ts
import axios from 'axios'
import { logout } from '../utils/storage/auth'

// => Configurando a Instância dos End-point
const api = axios.create({
    baseURL: `${process.env.REACT_APP_BANK_API_URL}`
})

// => Interceptador de respostas para lidar com erros
api.interceptors.response.use(
    (response) => response,
    (error) => {
        // => Se o retorno for 401. Fazer o Logout do Usuário
        if (error.response?.status === 401) {
            logout()
            window.location.href = "/auth"
        }
        return Promise.reject(error)
    }
)

// => Retorno da instância
export default api
