import { useEffect, useState } from "react"
import { getTransactions } from "../../../api/endpoints/transaction"
import { Skeleton } from "../../../components/ui/skeleton"
import { PlatformTemplate } from "../../../components/layout/template"
import { ArrowLeftRight } from "lucide-react"
import TableTransactions from "./_components/table-transaction"

export type TransactionProps = {
    transactionId: string
    debtorName: string
    creditorName: string
    amount: number
    status: string
    createDate: string
}

export type Filters = {
    transactionId: string
    debtorName: string
    creditorName: string
    createDateStart: string
    createDateEnd: string
}

export default function Transactions() {
    const [transactions, setTransactions] = useState<TransactionProps[]>()
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [filters, setFilters] = useState<Filters>({
        transactionId: '',
        debtorName: '',
        creditorName: '',
        createDateStart: '',
        createDateEnd: '',
    })
    const [appliedFilters, setAppliedFilters] = useState<Filters>(filters)

    const fetchTransactions = async (pageIndex: number, pageSize: number, filters: Filters) => {
        const response: any = await getTransactions({ pageIndex, pageSize, ...filters })
        setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
        if (response?.data?.items) {
            setTransactions(response?.data?.items)
        } else {
            setTransactions([])
        }
    }

    useEffect(() => {
        fetchTransactions(pageIndex, pageSize, appliedFilters)
    }, [pageIndex, pageSize, appliedFilters])

    return (
        <PlatformTemplate>
            <div className="w-full h-full flex flex-col">
                <div className="w-full flex items-center py-3 gap-2">
                    <ArrowLeftRight size={20} />
                    <h1 className="text-[20px] font-semibold">Transações</h1>
                </div>
                {transactions ? (
                    <TableTransactions
                        transactions={transactions}
                        setTransactions={setTransactions}
                        pageIndex={pageIndex}
                        setPageIndex={setPageIndex}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalPages={totalPages}
                        setTotalPages={setTotalPages}
                        filters={filters}
                        setFilters={setFilters}
                        appliedFilters={appliedFilters}
                        setAppliedFilters={setAppliedFilters}
                    />
                ) : (
                    <Skeleton className="w-full h-full" />
                )}
            </div>
        </PlatformTemplate>
    )
}
