import { HeadersAccessToken } from "../config"
import api from ".."

/**
 * => Interface que define as propriedades de uma regra de risco.
 */
export interface RiskRuleProps {
  riskRuleId?: string
  scope: string
  type: string
  name: string
  description?: string
  expression: string
  action: string
  createDate?: string
  updateDate?: string
  active?: boolean
}

/**
 * => Interface que define os parâmetros para obter regras de risco.
 */
export interface GetRiskRulesProps {
  id?: string
  scope?: string
  type?: string
  name?: string
  expression?: string
  action?: string
  createDateStart?: string
  createDateEnd?: string
  updateDateStart?: string
  updateDateEnd?: string
  pageSize?: number
  pageIndex?: number
}

/**
 * => Função para obter regras de risco com base nos parâmetros fornecidos.
 * @param params Parâmetros para filtragem de regras de risco.
 * @returns Lista de regras de risco ou erro.
 */
export const getRiskRules = async (params: GetRiskRulesProps) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const queryParams = {
    id: params.id,
    scope: params.scope,
    type: params.type,
    name: params.name,
    expression: params.expression,
    action: params.action,
    createDateStart: params.createDateStart,
    createDateEnd: params.createDateEnd,
    updateDateStart: params.updateDateStart,
    updateDateEnd: params.updateDateEnd,
    pageSize: params.pageSize,
    pageIndex: params.pageIndex
  }

  const urlParams = Object.entries(queryParams)
    .filter(([_, value]) => (typeof value === 'string' ? value.trim() !== '' : value !== undefined && value !== null))
    .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
    .join('&')

  const url = `/v1/RiskRule/?${urlParams}`

  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para obter uma regra de risco pelo ID.
 * @param id ID da regra de risco.
 * @returns Dados da regra de risco ou erro.
 */
export const getRiskRuleById = async ({ id }: { id: string }) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/RiskRule/${id}`
  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para criar uma nova regra de risco.
 * @param data Dados da nova regra de risco.
 * @returns Resultado da criação ou erro.
 */
export const createRiskRule = async (data: RiskRuleProps) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/RiskRule`
  try {
    return await api.post(url, data, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para atualizar uma regra de risco pelo ID.
 * @param id ID da regra de risco.
 * @param data Dados atualizados da regra de risco.
 * @returns Resultado da atualização ou erro.
 */
export const updateRiskRule = async ({ id, data }: { id: string, data: any }) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/RiskRule/${id}`
  try {
    return await api.put(url, data, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para ativar uma regra de risco pelo ID.
 * @param id ID da regra de risco.
 * @returns Resultado da ativação ou erro.
 */
export const activateRiskRule = async ({ id }: { id: string }) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/RiskRule/${id}/active`
  try {
    return await api.put(url, {}, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para desativar uma regra de risco pelo ID.
 * @param id ID da regra de risco.
 * @returns Resultado da desativação ou erro.
 */
export const deactivateRiskRule = async ({ id }: { id: string }) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/RiskRule/${id}/inactive`
  try {
    return await api.put(url, {}, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para obter os tipos de regras de risco.
 * @returns Lista de tipos de regras de risco ou erro.
 */
export const getRiskRuleTypes = async (scope: string) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/RiskRule/types?type=${scope}`
  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}