import { useEffect, useState } from "react"
import { getRefunds, RefundStatus, RefundReason, AnalysisResult } from "../../../api/endpoints/refund"
import { Skeleton } from "../../../components/ui/skeleton"
import { PlatformTemplate } from "../../../components/layout/template"
import TableRefunds from "./_components/table-refund"
import { TicketSlash } from "lucide-react"

export type RefundProps = {
  refundId: string
  endToEndIdentification: string
  refundIdentification: string
  infractionReportId: string
  requestingParticipant: string
  contestedParticipant: string
  refundTransactionIdentification: string
  status: RefundStatus
  reason: RefundReason
  analysisResult: AnalysisResult
  createDate: string
}

export type Filters = {
  refundId: string
  endToEndIdentification: string
  refundIdentification: string
  infractionReportId: string
  requestingParticipant: string
  contestedParticipant: string
  refundTransactionIdentification: string
  status: RefundStatus | ''
  reason: RefundReason | ''
  analysisResult: AnalysisResult | ''
  createDateStart: string
  createDateEnd: string
}

export default function Refunds() {
  const [refunds, setRefunds] = useState<RefundProps[]>()
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [filters, setFilters] = useState<Filters>({
    refundId: '',
    endToEndIdentification: '',
    refundIdentification: '',
    infractionReportId: '',
    requestingParticipant: '',
    contestedParticipant: '',
    refundTransactionIdentification: '',
    status: '',
    reason: '',
    analysisResult: '',
    createDateStart: '',
    createDateEnd: '',
  })
  const [appliedFilters, setAppliedFilters] = useState<Filters>(filters)

  const fetchRefunds = async (pageIndex: number, pageSize: number, filters: Filters) => {
    const response: any = await getRefunds({ pageIndex, pageSize, ...filters })
    setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
    if (response?.data?.items) {
      setRefunds(response?.data?.items)
    } else {
      setRefunds([])
    }
  }

  useEffect(() => {
    fetchRefunds(pageIndex, pageSize, appliedFilters)
  }, [pageIndex, pageSize, appliedFilters])

  return (
    <PlatformTemplate>
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex items-center py-3 gap-2">
          <TicketSlash size={20} />
          <h1 className="text-[20px] font-semibold">Reembolsos MED</h1>
        </div>
        {refunds ? (
          <TableRefunds
            refunds={refunds}
            setRefunds={setRefunds}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            filters={filters}
            setFilters={setFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
        ) : (
          <Skeleton className="w-full h-full" />
        )}
      </div>
    </PlatformTemplate>
  )
}
