import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../components/ui/table"
import {
  ColumnDef,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ChevronDown, Fingerprint, Library, MoreHorizontal, RefreshCw } from "lucide-react"
import { Button } from "../../../../../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../../components/ui/dropdown-menu"
import { OwnerBlockProps, Filters } from "../page"
import { TableFiltersOwnerBlock } from "./table-filter-owner-blocked"
import { getOwnerBlocks } from "../../../../../api/endpoints/owner"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import { CaretSortIcon } from "@radix-ui/react-icons"

const ActionCell = ({ ownerBlock }: { ownerBlock: OwnerBlockProps }) => {
  const navigate = useNavigate()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Abrir Menu</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Ações</DropdownMenuLabel>
        <DropdownMenuItem
          onClick={() => navigator.clipboard.writeText(ownerBlock.ownerBlockId)}
        >
          <Fingerprint size={15} className="mr-2" />Copiar ID do Bloqueio
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigator.clipboard.writeText(ownerBlock.blockUserId)}>
          <Fingerprint size={15} className="mr-2" />Copiar ID do Usuário que Bloqueou
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate(`/owner/blocked/${ownerBlock.ownerBlockId}`)}>
          <Library size={15} className="mr-2" />Ver Detalhes
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const columns: ColumnDef<OwnerBlockProps>[] = [
  {
    accessorKey: "blockDate",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Data de Bloqueio
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div>{convertUTCToUserTimezone(row.getValue("blockDate"), getUserTimezoneOffset())}</div>
    ),
  },
  {
    accessorKey: "ownerName",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Nome
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div>{row.getValue("ownerName") ? row.getValue("ownerName") : 'N/A'}</div>
    ),
  },
  {
    accessorKey: "unblockDate",
    header: "Data de Desbloqueio",
    cell: ({ row }) => (
      <div>{row.getValue("unblockDate") ? convertUTCToUserTimezone(row.getValue("unblockDate"), getUserTimezoneOffset()) : 'N/A'}</div>
    ),
  },
  {
    accessorKey: "blockReason",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Motivo do Bloqueio
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div>{row.getValue("blockReason") ? row.getValue("blockReason") : 'N/A'}</div>
    ),
  },
  {
    accessorKey: "unblockReason",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Motivo do Desbloqueio
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div>{row.getValue("unblockReason") ? row.getValue("unblockReason") : 'N/A'}</div>
    ),
  },
  {
    accessorKey: "active",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div className={`capitalize ${row.getValue("active") ? "text-red-500 font-medium" : "text-green-500 font-medium"}`}>
        {row.getValue("active") ? "Bloqueado" : "Desbloqueado"}
      </div>
    ),
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <ActionCell ownerBlock={row.original} />,
  },
]

export default function TableOwnerBlocks({ ownerBlocks, setOwnerBlocks, pageIndex, setPageIndex, pageSize, setPageSize, totalPages, setTotalPages, filters, setFilters, appliedFilters, setAppliedFilters }:
  {
    ownerBlocks: OwnerBlockProps[],
    setOwnerBlocks: any,
    pageIndex: number,
    setPageIndex: any,
    pageSize: number,
    setPageSize: any,
    totalPages: number,
    setTotalPages: any,
    filters: Filters,
    setFilters: any,
    appliedFilters: Filters,
    setAppliedFilters: any,
  }) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})

  const [onRefresh, setOnRefresh] = useState<boolean>(false)
  const table = useReactTable({
    data: ownerBlocks,
    columns,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility,
      rowSelection,
    },
  })

  const handleRefreshTable = async (pageIndex: number, pageSize: number, filters: Filters) => {
    setOnRefresh(true)
    const response = await getOwnerBlocks({ pageIndex, pageSize, ...filters })
    setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
    if (response?.data?.items) {
      setOwnerBlocks(response?.data?.items)
      setOnRefresh(false)
    } else {
      setOwnerBlocks([])
      setOnRefresh(false)
    }
  }

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <div className="w-full h-auto flex items-center gap-2">
          <TableFiltersOwnerBlock
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setTotalPages={setTotalPages}
            filters={filters}
            setFilters={setFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            setOwnerBlocks={setOwnerBlocks}
          />
        </div>
        <DropdownMenu>
          <div className="flex items-center justify-center gap-2">
            <Button onClick={() => handleRefreshTable(pageIndex, pageSize, filters)} variant="secondary">
              <RefreshCw size={20} className={`${onRefresh && 'animate-spin'}`} />
            </Button>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                Colunas <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
          </div>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        {!onRefresh ?
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    Sem resultados
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table> :
          <Skeleton className="w-full h-[525px]" />}
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="font-semibold text-sm text-zinc-600">
          {pageIndex} de {totalPages}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPageIndex((old: number) => Math.max(old - 1, 1))}
            disabled={pageIndex === 1}
          >
            Anterior
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPageIndex((old: number) => old + 1)}
            disabled={pageIndex === totalPages}
          >
            Próximo
          </Button>
        </div>
      </div>
    </div>
  )
}
