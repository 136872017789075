import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { formatToBRL } from "../../../../../utils/helpers/formated"
import { getAccountActivityById, getAccountById } from "../../../../../api/endpoints/account"
import { getPath } from "../../../../../utils/helpers/path"
import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from "../../../../../components/ui/card"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { Switch } from "../../../../../components/ui/switch"
import { Button } from "../../../../../components/ui/button"
import { Copy } from "lucide-react"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { TabsAccount } from "../_components/tabs-account"

export default function AccountDetails() {
  const [account, setAccount] = useState<any>()
  const [latestActivities, setLatestActivities] = useState<any>()

  const fetchActivitiesByAccount = async () => {
    const response: any = await getAccountActivityById({ accountId: getPath() })
    setLatestActivities(response.data.items.slice(0, 5))
  }

  const fetchAccountById = async () => {
    const accountId = getPath()
    const response: any = await getAccountById({ accountId: accountId })
    setAccount(response?.data)
  }

  useEffect(() => {
    fetchAccountById()
    fetchActivitiesByAccount()
  }, [])

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsAccount value="details" />
          {account && latestActivities ?
            <AccountDetailsContent account={account} latestActivities={latestActivities} /> :
            <Skeleton className="w-full h-full" />
          }
        </div>
      </div>
    </PlatformTemplate>
  )
}

const AccountDetailsContent = ({ account, latestActivities }: { account: any, latestActivities: any }) => {
  const navigate = useNavigate()

  const handleCopyPixCode = () => {
    const pixKey = account.keys && account.keys.length > 0 ? account.keys[0].key : ''
    navigator.clipboard.writeText(pixKey)
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="w-full flex items-center justify-between">
        <h1 className="text-[20px] font-semibold">Saldo:</h1>
        <p className="text-[20px] font-semibold">{formatToBRL(account.balance)}</p>
      </div>
      <Card>
        <CardContent className="flex items-center justify-between py-3 px-5">
          <div className="flex items-center justify-center gap-1">
            <h3>Agência:</h3>
            <p className="font-semibold">{account.branch}</p>
          </div>
          <div className="flex items-center justify-center gap-1">
            <h3>Conta:</h3>
            <p className="font-semibold">{account.accountNumber}</p>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="w-full flex flex-col items-center justify-between py-10 px-5 gap-5">
          {account.keys && account.keys.length > 0 ?
            <div className="w-full flex items-center justify-between">
              <h3 className="font-semibold">Chave:</h3>
              <Button
                variant="ghost"
                className="flex items-center gap-3 cursor-pointer"
                onClick={handleCopyPixCode}
              >
                <p>{account.keys[0].key}</p>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Copy className="cursor-pointer" size={20} />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Copiar Chave PIX</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Button>
            </div>
            : ''}
          <div className="w-full flex items-center justify-between">
            <h3 className="font-semibold">Tipo:</h3>
            <p>{account.keys && account.keys.length > 0 ? account.keys[0].type : ''}</p>
          </div>
          <div className="w-full flex items-center justify-between">
            <h3 className="font-semibold">Ativo:</h3>
            <Switch checked={account.status === "Active"} id="airplane-mode" />
          </div>
        </CardContent>
      </Card>
      <div className="w-full grid grid-cols-[2fr_3fr] gap-5">
        <Card className="hover:bg-gray-50">
          <CardContent
            onClick={() => navigate(`/owner/details/${account.ownerId}`)}
            className="flex flex-col gap-5 py-10 px-8 cursor-pointer"
          >
            <h2 className="font-semibold">Cliente</h2>
            <div className="flex flex-col gap-1">
              <h3 className="font-semibold">Razão Social</h3>
              <p>{account.ownerName}</p>
            </div>
            <div className="flex flex-col gap-1">
              <h3 className="font-semibold">Documento</h3>
              <p>{account.ownerDocument}</p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="h-[250px] flex flex-col gap-5 p-5 overflow-y-auto">
            <CardTitle>Últimas Atividades</CardTitle>
            <div className="w-full flex flex-col gap-3 overflow-y-auto">
              {latestActivities.map((item: any) => (
                <Card>
                  <CardContent className="flex flex-col gap-2 p-2">
                    <div className="flex items-center justify-between">
                      <CardTitle>{convertUTCToUserTimezone(item.createDate, getUserTimezoneOffset())}</CardTitle>
                      <CardDescription className="text-medium">{item.userName}</CardDescription>
                    </div>
                    <CardDescription>{item.description}</CardDescription>
                  </CardContent>
                </Card>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}