import { useState } from "react"
import { Card, CardContent } from "../../../../../components/ui/card"
import { updateUserNewPasswordById } from "../../../../../api/endpoints/auth"
import { Button } from "../../../../../components/ui/button"
import { Input } from "../../../../../components/ui/input"
import { getPath } from "../../../../../utils/helpers/path"
import Swal from "sweetalert2"
import { IsEmpty } from "../../../../../utils/helpers/validate"

interface UserByIdNewPasswordProps {
  user: any
  setIsNewPassword: (isNewPassword: boolean) => void
}

export const UserByIdNewPassword = ({ setIsNewPassword, user }: UserByIdNewPasswordProps) => {
  const [newPassword, setNewPassword] = useState<string>('')
  const [repeatPassword, setRepeatPassword] = useState<string>('')
  const [error, setError] = useState({
    isActive: false,
    message: ''
  })

  const handleUpdateNewPassword = async () => {
    setError({ ...error, isActive: false })

    if (IsEmpty(newPassword) || IsEmpty(repeatPassword)) {
      return setError({ isActive: true, message: 'Os campos não podem estar vazios!' })
    }

    if (newPassword !== repeatPassword) {
      return setError({ isActive: true, message: 'As senhas devem ser iguais!' })
    }

    const response = await updateUserNewPasswordById(getPath(), { oldPassword: user.password, newPassword: newPassword })

    if (response.status === 200) {
      setIsNewPassword(false)
      return Swal.fire({
        icon: "success",
        title: "Senha alterada com Sucesso!",
        confirmButtonColor: "var(--gray-500)",
      })
    }

    return Swal.fire({
      icon: "error",
      title: "Não foi possível realizar a alteração",
      confirmButtonColor: "var(--gray-500)",
    })
  }

  return (
    <Card className="w-2/3">
      <CardContent className="flex flex-col gap-5 py-5">
        <h1 className="font-semibold text-zinc-600">Alteração de Senha</h1>
        <p className="w-2/3 text-zinc-500 text-sm">Alterar sua senha regularmente é essencial para a segurança, mas lembre-se de armazená-la com segurança e comunicar mudanças para evitar problemas de acesso para você e seus colegas.</p>
        <div className="flex flex-col gap-2">
          <Input
            placeholder="Nova Senha"
            type="password"
            onChange={(event) => setNewPassword(event.target.value)}
          />
          <Input
            placeholder="Repetir nova senha"
            type="password"
            onChange={(event) => setRepeatPassword(event.target.value)}
          />
        </div>
        {error.isActive && <p className="text-sm text-red-500">{error.message}</p>}
        <div className="flex items-center justify-center">
          <Button onClick={handleUpdateNewPassword}>Salvar</Button>
        </div>
      </CardContent>
    </Card>
  )
}
