// => refund.ts
import api from '../index'
import { HeadersAccessToken } from '../config'

/**
 * => Tipos de status para reembolso.
 */
export enum RefundStatus {
  PENDING = 'Pending',
  OPEN = 'Open',
  CLOSED = 'Closed',
  CANCELLED = 'Cancelled',
  FAILED = 'Failed'
}

/**
 * => Tipos de razão para reembolso.
 */
export enum RefundReason {
  FRAUD = 'Fraud',
  OPERATIONAL_FLAW = 'OperationalFlaw',
  REFUND_CANCELLED = 'RefundCancelled'
}

/**
 * => Tipos de resultado de análise para reembolso.
 */
export enum AnalysisResult {
  TOTALLY_ACCEPTED = 'TotallyAccepted',
  PARTIALLY_ACCEPTED = 'PartiallyAccepted',
  REJECTED = 'Rejected'
}

/**
 * => Parâmetros para obter reembolsos.
 */
export interface GetRefundsProps {
  refundId?: string
  endToEndIdentification?: string
  refundIdentification?: string
  infractionReportId?: string
  requestingParticipant?: string
  contestedParticipant?: string
  refundTransactionIdentification?: string
  status?: RefundStatus | ''
  reason?: RefundReason | ''
  analysisResult?: AnalysisResult | ''
  createDateStart?: string
  createDateEnd?: string
  pageSize?: number
  pageIndex?: number
}

/**
 * => Função para obter reembolsos com base nos parâmetros fornecidos.
 * @param params Parâmetros para filtragem de reembolsos.
 * @returns Lista de reembolsos ou erro.
 */
export const getRefunds = async (params: GetRefundsProps) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const queryParams = {
    refundId: params.refundId,
    endToEndIdentification: params.endToEndIdentification,
    refundIdentification: params.refundIdentification,
    infractionReportId: params.infractionReportId,
    requestingParticipant: params.requestingParticipant,
    contestedParticipant: params.contestedParticipant,
    refundTransactionIdentification: params.refundTransactionIdentification,
    status: params.status,
    reason: params.reason,
    analysisResult: params.analysisResult,
    createDateStart: params.createDateStart,
    createDateEnd: params.createDateEnd,
    pageSize: params.pageSize,
    pageIndex: params.pageIndex,
  }

  const urlParams = Object.entries(queryParams)
    .filter(([_, value]) => (typeof value === 'string' ? value.trim() !== '' : value !== undefined && value !== null))
    .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
    .join('&')

  const url = `/v1/refund?${urlParams}`

  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para obter um reembolso com base no ID fornecido.
 * @param refundId ID do reembolso.
 * @returns Detalhes do reembolso ou erro.
 */
export const getRefundById = async (refundId: string) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/refund/${encodeURIComponent(refundId)}`

  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Tipos de status para infrações.
 */
export enum InfractionStatus {
  PENDING = 'Pending',
  OPEN = 'Open',
  ACKNOWLEDGED = 'Acknowledged',
  CLOSED = 'Closed',
  CANCELLED = 'Cancelled',
  FAILED = 'Failed'
}

/**
 * => Tipos de razão para infrações.
 */
export enum InfractionReason {
  REFUND_REQUEST = 'RefundRequest',
  REFUND_CANCELLED = 'RefundCancelled'
}

/**
 * => Tipos de situação para infrações.
 */
export enum InfractionSituation {
  SCAM = 'Scam',
  ACCOUNT_TAKEOVER = 'AccountTakeover',
  COERCION = 'Coercion',
  FRAUDULENT_ACCESS = 'FraudulentAccess',
  OTHER = 'Other',
  UNKNOWN = 'Unknown'
}

/**
 * => Tipos de resultado de análise para infrações.
 */
export enum AnalysisResult {
  AGREED = 'Agreed',
  DISAGREED = 'Disagreed'
}

/**
 * => Parâmetros para obter infrações.
 */
interface GetInfractionsProps {
  infractionId?: string
  endToEndIdentification?: string
  reporterIspb?: string
  counterPartIspb?: string
  infractionIdentification?: string
  status?: InfractionStatus | ''
  reason?: InfractionReason | ''
  situation?: InfractionSituation | ''
  analysisResult?: AnalysisResult | ''
  createDateStart?: string
  createDateEnd?: string
  pageSize?: number
  pageIndex?: number
}

/**
 * => Função para obter infrações com base nos parâmetros fornecidos.
 * @param params Parâmetros para filtragem de infrações.
 * @returns Lista de infrações ou erro.
 */
export const getInfractions = async (params: GetInfractionsProps) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const queryParams = {
    infractionId: params.infractionId,
    endToEndIdentification: params.endToEndIdentification,
    reporterIspb: params.reporterIspb,
    counterPartIspb: params.counterPartIspb,
    infractionIdentification: params.infractionIdentification,
    status: params.status,
    reason: params.reason,
    situation: params.situation,
    analysisResult: params.analysisResult,
    createDateStart: params.createDateStart,
    createDateEnd: params.createDateEnd,
    pageSize: params.pageSize,
    pageIndex: params.pageIndex,
  }

  const urlParams = Object.entries(queryParams)
    .filter(([_, value]) => (typeof value === 'string' ? value.trim() !== '' : value !== undefined && value !== null))
    .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
    .join('&')

  const url = `/v1/refund/infraction?${urlParams}`

  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para obter uma infração com base no ID fornecido.
 * @param infractionId ID da infração.
 * @returns Detalhes da infração ou erro.
 */
export const getInfractionById = async (infractionId: string) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/refund/infraction/${encodeURIComponent(infractionId)}`

  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para criar um novo relatório de infração.
 * @param infraction Dados da nova infração.
 * @returns Resultado da criação ou erro.
 */
export const createInfraction = async (infraction: any) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/refund/infraction`
  try {
    return await api.post(url, infraction, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para cancelar uma infração com base no ID fornecido.
 * @param infractionIdentification ID da infração.
 * @returns Resultado do cancelamento ou erro.
 */
export const cancelInfraction = async (infractionIdentification: string) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/refund/infraction/cancel`

  const payload = {
    infractionIdentification: infractionIdentification
  }

  try {
    return await api.post(url, payload, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Parâmetros para fechar infrações.
 */
export interface CloseInfractionProps {
  infractionIdentification: string
  fraudType: string
  analysisResult: AnalysisResult
  analysisDetails: string
}

/**
 * => Função para fechar uma infração com base nos dados fornecidos.
 * @param infraction Dados da infração a ser fechada.
 * @returns Resultado do fechamento ou erro.
 */
export const closeInfraction = async (infraction: CloseInfractionProps) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/refund/infraction/close`

  const payload = {
    infractionIdentification: infraction.infractionIdentification,
    AnalysisResult: infraction.analysisResult,
    analysisDetails: infraction.analysisDetails,
    fraudType: infraction.fraudType
  }

  try {
    return await api.post(url, payload, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Parâmetros para realizar solicitação de reembolso.
 */
export interface CloseRefundProps {
  endToEndIdentification: string
  remittanceInformation: string
  amount: number | null
}

/**
 * => Função para realizar um reembolso com base nos dados fornecidos.
 * @param refund Dados do reembolso a ser fechado.
 * @returns Resultado do fechamento ou erro.
 */
export const closeRefund = async ({ endToEndIdentification, remittanceInformation, amount }: CloseRefundProps) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = '/v1/refund'

  const payload = {
    endToEndIdentification,
    remittanceInformation,
    amount
  }

  try {
    return await api.post(url, payload, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Parâmetros para realizar o cancelamento de reembolso.
 */
export interface CancelRefundProps {
  refundIdentification: string
}

/**
 * => Função para Cancelar um reembolso usando o Identification.
 * @param Identificador do rembolso a ser cancelado.
 * @returns Resultado do cancelamento ou erro.
 */
export const cancelRefund = async (refundIdentification: string) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = '/v1/refund'

  const payload = { refundIdentification }

  try {
    return await api.post(url, payload, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}