import React, { useState } from "react"
import { getRefunds, RefundStatus, RefundReason, AnalysisResult } from "../../../../api/endpoints/refund"
import { Input } from "../../../../components/ui/input"
import { Label } from "../../../../components/ui/label"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../../../components/ui/sheet"
import { Button } from "../../../../components/ui/button"
import { Badge } from "../../../../components/ui/badge"
import { Filter, Search } from "lucide-react"
import { Filters } from "../page"
import { DatePicker } from "../../../../components/ui/date-picker"
import { format } from "date-fns"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../../../components/ui/select"
import { countFilledData } from "../../../../utils/helpers/calculate"
import { handleEnterKeyDown } from "../../../../utils/helpers/keyboard"

type FilterKey = keyof Filters

interface TableFiltersRefundProps {
  filters: Filters
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
  setRefunds: React.Dispatch<React.SetStateAction<any[]>>
  pageIndex: number
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  appliedFilters: Filters
  setAppliedFilters: React.Dispatch<React.SetStateAction<Filters>>
}

export const TableFiltersRefund: React.FC<TableFiltersRefundProps> = ({
  filters, setFilters, setRefunds, pageIndex, setPageIndex, setTotalPages, pageSize, appliedFilters, setAppliedFilters
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitFilters = async () => {
    setIsSubmitting(true)
    const response: any = await getRefunds({ ...filters, pageIndex: 1, pageSize })
    if (response?.data?.items) {
      setRefunds(response?.data?.items)
      setAppliedFilters(filters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setRefunds([])
      setAppliedFilters(filters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const removeAllFilters = async () => {
    const resetFilters: Filters = {
      refundId: '',
      endToEndIdentification: '',
      refundIdentification: '',
      infractionReportId: '',
      requestingParticipant: '',
      contestedParticipant: '',
      refundTransactionIdentification: '',
      status: '',
      reason: '',
      analysisResult: '',
      createDateStart: '',
      createDateEnd: '',
    }
    setFilters(resetFilters)

    const response: any = await getRefunds({ pageIndex: 1, pageSize })

    if (response?.data?.items) {
      setRefunds(response?.data?.items)
      setAppliedFilters(resetFilters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setRefunds([])
      setAppliedFilters(resetFilters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const handleInputChange = (key: FilterKey, value: string) => {
    setFilters({ ...filters, [key]: value })
  }

  const handleDateChange = (key: FilterKey, value: Date | undefined) => {
    const formattedDate = value ? format(value, "yyyy-MM-dd") : ""
    setFilters({ ...filters, [key]: formattedDate })
  }

  const handleRemoveFilter = (key: FilterKey) => {
    const newFilters = { ...filters, [key]: '' }
    setFilters(newFilters)
    if (countFilledData(newFilters) === 0) {
      removeAllFilters()
    } else {
      submitFilters()
    }
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="flex w-2/3 gap-2">
        <Input
          type="text"
          placeholder="Procurar pelo ID do Reembolso..."
          onKeyDown={handleEnterKeyDown(submitFilters)}
          disabled={isSubmitting}
          value={filters.refundId}
          onChange={(e) => handleInputChange('refundId', e.target.value)}
          className="max-w-sm"
        />
        <Button disabled={isSubmitting || !filters.refundId.trim()} onClick={submitFilters} variant="default">
          <Search size={20} />
        </Button>
        <Sheet>
          <SheetTrigger>
            <Button disabled={isSubmitting} className="flex gap-2">
              <Filter size={15} />
              Filtros
            </Button>
          </SheetTrigger>
          <SheetContent className="flex flex-col gap-8">
            <SheetHeader>
              <SheetTitle className="flex items-center gap-2">
                <Filter size={20} />
                Filtros
              </SheetTitle>
              <SheetDescription>
                Utilize os filtros personalizados para encontrar dados que está buscando na tabela
              </SheetDescription>
            </SheetHeader>
            <div className="flex flex-col overflow-y-auto px-1 gap-5">
              {(['refundId', 'endToEndIdentification', 'refundIdentification', 'infractionReportId', 'requestingParticipant', 'contestedParticipant', 'refundTransactionIdentification'] as FilterKey[]).map((key) => (
                <div key={key}>
                  <Label>{filterLabels[key]}</Label>
                  <Input
                    type="text"
                    value={filters[key]}
                    onChange={(e) => handleInputChange(key, e.target.value)}
                  />
                </div>
              ))}
              <div>
                <Label>Status</Label>
                <Select onValueChange={(value) => handleInputChange('status', value as RefundStatus)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Selecione o Status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Pending">Pending</SelectItem>
                    <SelectItem value="Open">Open</SelectItem>
                    <SelectItem value="Closed">Closed</SelectItem>
                    <SelectItem value="Cancelled">Cancelled</SelectItem>
                    <SelectItem value="Failed">Failed</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label>Razão</Label>
                <Select onValueChange={(value) => handleInputChange('reason', value as RefundReason)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Selecione a Razão" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Fraud">Fraud</SelectItem>
                    <SelectItem value="OperationalFlaw">Operational Flaw</SelectItem>
                    <SelectItem value="RefundCancelled">Refund Cancelled</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label>Resultado da Análise</Label>
                <Select onValueChange={(value) => handleInputChange('analysisResult', value as AnalysisResult)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Selecione o Resultado" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="TotallyAccepted">Totally Accepted</SelectItem>
                    <SelectItem value="PartiallyAccepted">Partially Accepted</SelectItem>
                    <SelectItem value="Rejected">Rejected</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label>Data de Início</Label>
                <DatePicker
                  selectedDate={filters.createDateStart ? new Date(filters.createDateStart) : undefined}
                  onDateChange={(date) => handleDateChange('createDateStart', date)}
                />
              </div>
              <div>
                <Label>Data de Fim</Label>
                <DatePicker
                  selectedDate={filters.createDateEnd ? new Date(filters.createDateEnd) : undefined}
                  onDateChange={(date) => handleDateChange('createDateEnd', date)}
                />
              </div>
            </div>
            <SheetFooter>
              <SheetClose asChild>
                <Button variant="secondary" disabled={isSubmitting} onClick={removeAllFilters}>
                  Remover todos os Filtros
                </Button>
              </SheetClose>
              <SheetClose asChild>
                <Button disabled={isSubmitting} onClick={submitFilters}>
                  Aplicar
                </Button>
              </SheetClose>
            </SheetFooter>
          </SheetContent>
        </Sheet>
        {countFilledData(appliedFilters) !== 0 && (
          <div className="w-8 h-8 flex items-center justify-center rounded-[50%] bg-teal-700 text-white select-none">
            {countFilledData(appliedFilters)}
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        {(['refundId', 'endToEndIdentification', 'refundIdentification', 'infractionReportId', 'requestingParticipant', 'contestedParticipant', 'refundTransactionIdentification', 'status', 'reason', 'analysisResult', 'createDateStart', 'createDateEnd'] as FilterKey[]).map((key) => (
          appliedFilters[key] && (
            <Badge variant="secondary" onClick={() => handleRemoveFilter(key)} className="cursor-pointer">
              {filterLabels[key]}: {appliedFilters[key]} ✕
            </Badge>
          )
        ))}
      </div>
    </div>
  )
}

const filterLabels: { [key in FilterKey]: string } = {
  refundId: "ID do Reembolso",
  endToEndIdentification: "Identificação End-to-End",
  refundIdentification: "Identificação do Reembolso",
  infractionReportId: "ID do Relatório de Infração",
  requestingParticipant: "Participante Solicitante",
  contestedParticipant: "Participante Contestante",
  refundTransactionIdentification: "Identificação da Transação",
  status: "Status",
  reason: "Razão",
  analysisResult: "Resultado da Análise",
  createDateStart: "Data de Início",
  createDateEnd: "Data de Fim",
}
