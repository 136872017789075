import { createRoot } from "react-dom/client"
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom"
import { getUserDataFromLocalStorage, isLoggedIn } from "./utils/storage/auth"

// => Páginas de Autenticação
import Login from "./app/(public)/auth/page"
// => Páginas de Recorrência a Erros
import NotFound404 from "./app/(public)/404/page"
// => Páginas de Clientes
import Owners from "./app/(private)/owner/page"
import OwnerDetails from "./app/(private)/owner/[id]/details/page"
import OwnerIntegration from "./app/(private)/owner/[id]/integrations/page"
import OwnerActivities from "./app/(private)/owner/[id]/activities/page"
import OwnerBanks from "./app/(private)/owner/[id]/other-banks/page"
import OwnerAdresses from "./app/(private)/owner/[id]/adresses/page"
import OwnerBlocked from "./app/(private)/owner/blocked/page"
import OwnerBlockDetails from "./app/(private)/owner/blocked/[id]/page"
import OwnerBlocksByIdDetails from "./app/(private)/owner/[id]/blocked/page"
import CreateOwner from "./app/(private)/owner/create/page"
import CreateBank from "./app/(private)/owner/[id]/other-banks/create-bank/page"
// => Páginas de Contas
import Accounts from "./app/(private)/account/page"
import AccountDetails from "./app/(private)/account/[id]/details/page"
import AccountExtract from "./app/(private)/account/[id]/extract/page"
import AccountActivity from "./app/(private)/account/[id]/activities/page"
import AccountSettings from "./app/(private)/account/[id]/settings/page"
import AccountRiskRules from "./app/(private)/account/[id]/risk-rules/page"
import AccountRiskRulesDetails from "./app/(private)/account/[id]/risk-rules/[id]/page"
import AccountCreateRiskRules from "./app/(private)/account/[id]/risk-rules/create/page"
import CreateAccount from "./app/(private)/account/create/page"
// => Páginas de Transações Financeiras
import Transactions from "./app/(private)/transactions/page"
import TransactionDetails from "./app/(private)/transactions/[id]/details/page"
import TransactionTrajectory from "./app/(private)/transactions/[id]/trajectory/page"
import TransactionE2E from "./app/(private)/transactions/[id]/end-to-end-trajectory/page"
import ReportInfraction from "./app/(private)/transactions/[id]/report-infraction/page"
// => Páginas de Configurações
import MyProfile from "./app/(private)/profile/page"
import NewPassword from "./app/(private)/profile/new-password/page"
// => Páginas de Usuários da Plataforma
import Users from "./app/(private)/users/page"
import UserById from "./app/(private)/users/[id]/page"
import CreateNewUser from "./app/(private)/users/create/page"
// => Páginas de Alertas
import Alerts from "./app/(private)/alerts/page"
import AlertDetails from "./app/(private)/alerts/[id]/details/page"
import AlertComments from "./app/(private)/alerts/[id]/comments/page"
import AlertHistoric from "./app/(private)/alerts/[id]/historic/page"
// => Páginas de Infração
import Infractions from "./app/(private)/infraction/page"
import InfractionById from "./app/(private)/infraction/[id]/page"
// => Páginas de MED
import Refund from "./app/(private)/refund/page"
import RefundById from "./app/(private)/refund/[id]/page"
// => Páginas de Regras de Risco
import RiskRules from "./app/(private)/risk-rules/page"
import RiskRulesDetails from "./app/(private)/risk-rules/[id]/page"
import CreateRiskRule from "./app/(private)/risk-rules/create/page"

import './global.css'

const root = createRoot(
  document.getElementById('root') as HTMLElement
)

const PrivateRoute = ({ element, superRequired }: any) => {
  const authStatus = isLoggedIn()

  if (authStatus) {
    const user: any = getUserDataFromLocalStorage()
    const userType = user.user_type

    if (superRequired) {
      return authStatus && userType === "Super" ? element : <Navigate to="/owner" />
    }

    return element
  }

  return <Navigate to="/auth" />
}

const PublicRoute = ({ element }: any) => {
  const authStatus = isLoggedIn()
  return !authStatus ? element : <Navigate to="/owner" />
}

const router = createBrowserRouter([
  // Todas as rotas públicas: Não necessitam de Autenticação para acessar
  {
    path: "/auth",
    element: <PublicRoute element={<Login />} />
  },
  {
    path: "/:slug",
    element: <NotFound404 />
  },
  // Todas as rotas privadas: Necessitam de Autenticação para acesso, caso não haja, faz um redirecionamento de página
  {
    path: "/",
    element: <Navigate to="/owner" />
  },
  {
    path: "/owner",
    element: <PrivateRoute element={<Owners />} />
  },
  {
    path: "/owner/details/:id",
    element: <PrivateRoute element={<OwnerDetails />} />
  },
  {
    path: "/owner/activities/:id",
    element: <PrivateRoute element={<OwnerActivities />} />
  },
  {
    path: "/owner/other-banks/:id",
    element: <PrivateRoute element={<OwnerBanks />} />
  },
  {
    path: "/owner/other-banks/create-bank/:id",
    element: <PrivateRoute element={<CreateBank />} />
  },
  {
    path: "/owner/adresses/:id",
    element: <PrivateRoute element={<OwnerAdresses />} />
  },
  {
    path: "/owner/integrations/:id",
    element: <PrivateRoute element={<OwnerIntegration />} />
  },
  {
    path: "/owner/blocked/historic/:id",
    element: <PrivateRoute element={<OwnerBlocksByIdDetails />} />
  },
  {
    path: "/owner/blocked",
    element: <PrivateRoute element={<OwnerBlocked />} />
  },
  {
    path: "/owner/blocked/:id",
    element: <PrivateRoute element={<OwnerBlockDetails />} />
  },
  {
    path: "/owner/create",
    element: <PrivateRoute element={<CreateOwner />} />
  },
  {
    path: "/account",
    element: <PrivateRoute element={<Accounts />} />
  },
  {
    path: "/account/details/:id",
    element: <PrivateRoute element={<AccountDetails />} />
  },
  {
    path: "/account/activities/:id",
    element: <PrivateRoute element={<AccountActivity />} />
  },
  {
    path: "/account/extract/:id",
    element: <PrivateRoute element={<AccountExtract />} />
  },
  {
    path: "/account/settings/:id",
    element: <PrivateRoute element={<AccountSettings />} />
  },
  {
    path: "/account/risk/:id",
    element: <PrivateRoute element={<AccountRiskRules />} />
  },
  {
    path: "/account/risk/details/:riskruleId/:accountId",
    element: <PrivateRoute element={<AccountRiskRulesDetails />} />
  },
  {
    path: "/account/risk/create/:id",
    element: <PrivateRoute element={<AccountCreateRiskRules />} />
  },
  {
    path: "/account/create",
    element: <PrivateRoute element={<CreateAccount />} />
  },
  {
    path: "/transaction",
    element: <PrivateRoute element={<Transactions />} />
  },
  {
    path: "/transaction/details/:id",
    element: <PrivateRoute element={<TransactionDetails />} />
  },
  {
    path: "/transaction/trajectory/:id",
    element: <PrivateRoute element={<TransactionTrajectory />} />
  },
  {
    path: "/transaction/end-to-end-trajectory/:id",
    element: <PrivateRoute element={<TransactionE2E />} />
  },
  {
    path: "/transaction/report-infraction/:id",
    element: <PrivateRoute element={<ReportInfraction />} />
  },
  {
    path: "/alert",
    element: <PrivateRoute element={<Alerts />} />
  },
  {
    path: "/alert/details/:id",
    element: <PrivateRoute element={<AlertDetails />} />
  },
  {
    path: "/alert/comments/:id",
    element: <PrivateRoute element={<AlertComments />} />
  },
  {
    path: "/alert/historic/:id",
    element: <PrivateRoute element={<AlertHistoric />} />
  },
  {
    path: "/infraction",
    element: <PrivateRoute element={<Infractions />} />
  },
  {
    path: "/infraction/:id",
    element: <PrivateRoute element={<InfractionById />} />
  },
  {
    path: "/refund",
    element: <PrivateRoute element={<Refund />} />
  },
  {
    path: "/refund/:id",
    element: <PrivateRoute element={<RefundById />} />
  },
  {
    path: "/risk-rules",
    element: <PrivateRoute element={<RiskRules />} />
  },
  {
    path: "/risk-rules/details/:id",
    element: <PrivateRoute element={<RiskRulesDetails />} />
  },
  {
    path: "/risk-rules/create",
    element: <PrivateRoute element={<CreateRiskRule />} />
  },
  {
    path: "/user",
    element: <PrivateRoute superRequired element={<Users />} />
  },
  {
    path: "/user/:id",
    element: <PrivateRoute superRequired element={<UserById />} />
  },
  {
    path: "/user/create",
    element: <PrivateRoute superRequired element={<CreateNewUser />} />
  },
  {
    path: "/profile",
    element: <PrivateRoute element={<MyProfile />} />
  },
  {
    path: "/profile/new-password",
    element: <PrivateRoute element={<NewPassword />} />
  }
])

root.render(<RouterProvider router={router} />)
