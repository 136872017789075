import { ChangeEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { PlatformTemplate } from "../../../components/layout/template"
import { getUserDataFromLocalStorage, saveUserDataToLocalStorage } from "../../../utils/storage/auth"
import { CircleUserRound, Lock } from "lucide-react"
import { Button } from "../../../components/ui/button"
import { Input } from "../../../components/ui/input"
import { Label } from "../../../components/ui/label"
import { Skeleton } from "../../../components/ui/skeleton"
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table"
import { updateMyUser } from "../../../api/endpoints/auth"
import Swal from "sweetalert2"

export default function MyProfile() {
  const [user, setUser] = useState<any>()

  useEffect(() => {
    const userByLocalStorage: any = getUserDataFromLocalStorage()
    setUser(userByLocalStorage)
  }, [])

  return user ? <MyProfileContent user={user} /> : <Skeleton className="w-full h-full" />
}

const MyProfileContent = ({ user }: { user: any }) => {
  const navigate = useNavigate()
  const [profile, setProfile] = useState(user.user)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const handleSavedNewProfile = async () => {
    setIsEdit(true)
    const response = await updateMyUser({ email: profile.email, roles: profile.roles })

    if (response.status !== 200) {
      return Swal.fire({
        icon: "error",
        title: "Não foi possível realizar a alteração",
        confirmButtonColor: "var(--gray-500)",
      })
    }

    // Atualizando o LocalStorage
    let userLocalStorage = user
    userLocalStorage.user.email = profile.email
    saveUserDataToLocalStorage(userLocalStorage)
    
    return setIsEdit(false)
  }

  return (
    <PlatformTemplate>
      <div className="w-full h-full flex items-center justify-center">
        <div className="w-2/5 flex flex-col gap-10">
          <div className="flex items-center gap-3">
            <CircleUserRound className="text-slate-700" size={40} />
            <h1 className="text-slate-700 font-medium text-[20px]">Perfil de Usuário</h1>
          </div>
          <div className="flex flex-col gap-5">
            <div className="h-12 flex items-center justify-between">
              <h1 className="font-medium text-lg">Informações Gerais</h1>
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="name">Nome de Usuário</Label>
              <Input
                id="name"
                value={profile.userName}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setProfile({ ...profile, userName: event.target.value })}
                disabled
              />
            </div>
            <div className="flex w-full items-end justify-between gap-2">
              <div className="flex flex-col gap-2 flex-1">
                <Label htmlFor="email">E-mail</Label>
                <Input
                  id="email"
                  value={profile.email}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setProfile({ ...profile, email: event.target.value })}
                  disabled={!isEdit}
                />
              </div>
              {!isEdit ? <Button onClick={() => setIsEdit(true)}>Editar</Button> : <Button disabled={!isEdit} onClick={() => handleSavedNewProfile()}>Salvar</Button>}
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="type">Tipo de Usuário</Label>
              <Input
                id="type"
                value={profile.type}
                disabled
              />
            </div>
            <Table>
              <TableCaption>Conforme o tipo de usuário, você terá acessos específicos na plataforma.</TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead>Acessos</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  {user.user.roles.map((role: any) => (
                    <TableCell>{role}</TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
            <div className="w-full flex gap-2 items-center justify-end my-5">
              <Button onClick={() => navigate("/profile/new-password")} variant="secondary" className="flex items-center justify-center gap-2"><Lock size={15} />Alterar senha</Button>
            </div>
          </div>
        </div>
      </div>
    </PlatformTemplate>
  )
}