import React, { ChangeEvent, useState } from "react"
import { countFilledData } from "../../../../utils/helpers/calculate"
import { getAlerts } from "../../../../api/endpoints/alert"
import { trimSpaces } from "../../../../utils/helpers/trimSpaces"
import { Input } from "../../../../components/ui/input"
import { Label } from "../../../../components/ui/label"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from "../../../../components/ui/sheet"
import { handleEnterKeyDown } from "../../../../utils/helpers/keyboard"
import { Button } from "../../../../components/ui/button"
import { Badge } from "../../../../components/ui/badge"
import { Filter, Search } from "lucide-react"
import { Filters } from "../page"
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem
} from "../../../../components/ui/select"

type FilterKey = keyof Filters

interface TableFiltersAlertProps {
  filters: Filters
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
  setAlerts: React.Dispatch<React.SetStateAction<any[]>>
  pageIndex: number
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  appliedFilters: Filters
  setAppliedFilters: React.Dispatch<React.SetStateAction<Filters>>
}

export const TableFiltersAlert: React.FC<TableFiltersAlertProps> = ({
  filters, setFilters, setAlerts, pageIndex, setPageIndex, setTotalPages, pageSize, appliedFilters, setAppliedFilters
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitFilters = async () => {
    setIsSubmitting(true)

    const response = await getAlerts({ ...filters, pageIndex: 1, pageSize })

    if (response?.data?.items) {
      setAlerts(response?.data?.items)
      setAppliedFilters(filters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setAlerts([])
      setAppliedFilters(filters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const removeAllFilters = async () => {
    const resetFilters: Filters = {
      alertId: '',
      referenceId: '',
      status: '',
      type: '',
      scope: '',
      priority: '',
      accountId: '',
      ownerId: '',
      openedUserId: '',
      closedUserId: '',
      createDateStart: '',
      createDateEnd: '',
      updateDateStart: '',
      updateDateEnd: '',
      resolutionDateStart: '',
      resolutionDateEnd: '',
    }
    setFilters(resetFilters)

    const response = await getAlerts({ pageIndex: 1, pageSize })

    if (response?.data?.items) {
      setAlerts(response?.data?.items)
      setAppliedFilters(resetFilters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setAlerts([])
      setAppliedFilters(resetFilters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const handleRemoveFilter = (key: FilterKey) => {
    const newFilters = { ...filters, [key]: '' }
    setFilters(newFilters)
    if (countFilledData(newFilters) === 0) {
      removeAllFilters()
    } else {
      submitFilters()
    }
  }

  const handleInputChange = (key: FilterKey, value: string) => {
    setFilters({ ...filters, [key]: trimSpaces(value) })
  }

  const handleSelectChange = (key: FilterKey, value: string) => {
    setFilters({ ...filters, [key]: value })
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="flex w-2/3 gap-2">
        <Input
          type="text"
          onKeyDown={handleEnterKeyDown(submitFilters)}
          placeholder="Procurar pelo ID do Alerta..."
          disabled={isSubmitting}
          value={filters.alertId}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('alertId', e.target.value)}
          className="max-w-sm"
        />
        <Button disabled={isSubmitting || !filters.alertId.trim()} onClick={submitFilters} variant="default">
          <Search size={20} />
        </Button>
        <Sheet>
          <SheetTrigger>
            <Button disabled={isSubmitting} className="flex gap-2">
              <Filter size={15} />
              Filtros
            </Button>
          </SheetTrigger>
          <SheetContent className="flex flex-col gap-8">
            <SheetHeader>
              <SheetTitle className="flex items-center gap-2">
                <Filter size={20} />
                Filtros
              </SheetTitle>
              <SheetDescription>
                Utilize os filtros personalizados para encontrar dados que está buscando na tabela
              </SheetDescription>
            </SheetHeader>
            <div className="flex flex-col overflow-y-auto px-1 gap-5">
              {(['alertId', 'referenceId', 'status', 'type', 'scope', 'priority', 'accountId', 'ownerId', 'openedUserId', 'closedUserId'] as FilterKey[]).map((key) => (
                <div key={key}>
                  <Label>{filterLabels[key]}</Label>
                  {key === 'status' || key === 'type' || key === 'scope' || key === 'priority' ? (
                    <Select onValueChange={(value) => handleSelectChange(key, value)}>
                      <SelectTrigger>
                        <SelectValue placeholder={`Selecione ${filterLabels[key]}`} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>{filterLabels[key]}</SelectLabel>
                          {key === 'status' && <>
                            <SelectItem value="PENDING">Pendente</SelectItem>
                            <SelectItem value="OPEN">Aberto</SelectItem>
                            <SelectItem value="CLOSED">Fechado</SelectItem>
                          </>}
                          {key === 'type' && <>
                            <SelectItem value="AVERAGE_TICKET">Average Ticket</SelectItem>
                          </>}
                          {key === 'scope' && <>
                            <SelectItem value="ACCOUNT">Contas</SelectItem>
                            <SelectItem value="OWNER">Clientes</SelectItem>
                            <SelectItem value="TRANSACTION">Transações</SelectItem>
                            <SelectItem value="INFRACTION">Infrações</SelectItem>
                            <SelectItem value="REFUND">MED</SelectItem>
                          </>}
                          {key === 'priority' && <>
                            <SelectItem value="LOW">Baixo</SelectItem>
                            <SelectItem value="MEDIUM">Médio</SelectItem>
                            <SelectItem value="HIGH">Alto</SelectItem>
                            <SelectItem value="CRITICAL">Crítico</SelectItem>
                          </>}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  ) : (
                    <Input
                      type="text"
                      value={filters[key]}
                      onChange={(e) => handleInputChange(key, e.target.value)}
                    />
                  )}
                </div>
              ))}
            </div>
            <SheetFooter>
              <SheetClose asChild>
                <Button variant="secondary" disabled={isSubmitting} onClick={removeAllFilters}>
                  Remover todos os Filtros
                </Button>
              </SheetClose>
              <SheetClose asChild>
                <Button disabled={isSubmitting} onClick={submitFilters}>
                  Aplicar
                </Button>
              </SheetClose>
            </SheetFooter>
          </SheetContent>
        </Sheet>
        {countFilledData(appliedFilters) !== 0 && (
          <div className="w-8 h-8 flex items-center justify-center rounded-[50%] bg-teal-700 text-white select-none">
            {countFilledData(appliedFilters)}
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        {(['alertId', 'referenceId', 'status', 'type', 'scope', 'priority', 'accountId', 'ownerId', 'openedUserId', 'closedUserId', 'createDateStart', 'createDateEnd', 'updateDateStart', 'updateDateEnd', 'resolutionDateStart', 'resolutionDateEnd'] as FilterKey[]).map((key) => (
          appliedFilters[key] && (
            <TagsFilter
              key={key}
              filterKey={key}
              filterValue={appliedFilters[key]}
              removeFilter={handleRemoveFilter}
            />
          )
        ))}
      </div>
    </div>
  )
}

const filterLabels: { [key in FilterKey]: string } = {
  alertId: "ID do Alerta",
  referenceId: "ID de Referência",
  status: "Status",
  type: "Tipo",
  scope: "Escopo",
  priority: "Prioridade",
  accountId: "ID da Conta",
  ownerId: "ID do Dono",
  openedUserId: "ID do Usuário que Abriu",
  closedUserId: "ID do Usuário que Fechou",
  createDateStart: "Data de Criação (Início)",
  createDateEnd: "Data de Criação (Fim)",
  updateDateStart: "Data de Atualização (Início)",
  updateDateEnd: "Data de Atualização (Fim)",
  resolutionDateStart: "Data de Resolução (Início)",
  resolutionDateEnd: "Data de Resolução (Fim)",
}

interface TagsFilterProps {
  filterKey: FilterKey
  filterValue: string
  removeFilter: (key: FilterKey) => void
}

const TagsFilter: React.FC<TagsFilterProps> = ({ filterKey, filterValue, removeFilter }) => (
  <Badge variant="secondary" onClick={() => removeFilter(filterKey)} className="cursor-pointer">
    {filterLabels[filterKey]}: {filterValue} ✕
  </Badge>
)
