import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { formatDocumentCnpjNumber, formatDocumentCpfNumber } from "../../../../../utils/helpers/formated"
import { getPath } from "../../../../../utils/helpers/path"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { ternaryOperator } from "../../../../../utils/helpers/validate"
import { getOwnersById } from "../../../../../api/endpoints/owner"
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../components/ui/table"
import {
  Card,
  CardContent
} from "../../../../../components/ui/card"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { TabsOwner } from "../_components/tabs-owner"

export default function OwnerDetails() {
  const [owner, setOwner] = useState<any>()

  const fetchOwnerById = async () => {
    const response: any = await getOwnersById(getPath())
    if (response?.data) {
      return setOwner(response?.data)
    }
  }

  useEffect(() => {
    fetchOwnerById()
  }, [])

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsOwner value="details" />
          {owner ?
            <OwnerDetailsContent owner={owner} /> :
            <Skeleton className="w-full h-full" />
          }
        </div>
      </div>
    </PlatformTemplate>
  )
}

const OwnerDetailsContent = ({ owner }: { owner: any }) => {
  return (
    <Card>
      <CardContent className="flex flex-col gap-10 p-5">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-end">
            <h2 className="text-xl font-semibold my-5 capitalize">{ternaryOperator(owner.name, owner.name, 'Não Informado')}</h2>
          </div>
          <div className="w-full flex items-center justify-between">
            <p className="font-medium">Tipo de Documento:</p>
            <p>{ternaryOperator(owner.documentType, owner.documentType, 'Não Informado')}</p>
          </div>
          <div className="w-full flex items-center justify-between">
            <p className="font-medium">Número do Documento:</p>
            <p>{ternaryOperator(owner.documentNumber, owner.documentType === "CPF" ? formatDocumentCpfNumber(owner.documentNumber) : formatDocumentCnpjNumber(owner.documentNumber), 'Não Informado')}</p>
          </div>
          <div className="w-full flex items-center justify-between">
            <p className="font-medium">Status da Conta do Cliente:</p>
            <p className={ternaryOperator(owner.enabled, 'px-2 py-1 text-green-600', 'px-2 py-1 text-red-600')}>{ternaryOperator(owner.enabled, 'Habilitado', 'Desabilitado')}</p>
          </div>
        </div>
        <AccountsByOwner ownerId={owner.id} accounts={owner.account} />
      </CardContent>
    </Card>
  )
}

const AccountsByOwner = ({ accounts, ownerId }: { accounts: any, ownerId: string }) => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center justify-between">
        <h2 className="font-semibold text-lg">Contas</h2>
      </div>
      <Table>
        <TableCaption>Todas as contas vinculadas a esse cliente.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Agência</TableHead>
            <TableHead>Número da Conta</TableHead>
            <TableHead>Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {accounts?.map((account: any) => (
            <TableRow onClick={() => navigate(`/account/details/${account.accountId}`)} className="cursor-pointer">
              <TableCell>{account.branch}</TableCell>
              <TableCell>{account.accountNumber}</TableCell>
              <TableCell className={`${account.status === "Active" ? "text-green-500 font-medium" : "text-red-500 font-medium"}`}>{ternaryOperator(account.status === "Active", "Ativa", "Bloqueado")}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}