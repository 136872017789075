import React, {
  useEffect,
  useState,
  ChangeEvent,
  FormEvent
} from "react";
import { PlatformTemplate } from "../../../../components/layout/template";
import {
  activateRiskRule,
  deactivateRiskRule,
  getRiskRuleById,
  getRiskRuleTypes,
  updateRiskRule
} from "../../../../api/endpoints/risk-rules";
import { getPath } from "../../../../utils/helpers/path";
import { Skeleton } from "../../../../components/ui/skeleton";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem
} from "../../../../components/ui/select";
import { Switch } from "../../../../components/ui/switch";
import {
  Loader2,
  CircleMinus,
  Plus,
  Trash2
} from "lucide-react";
import Swal from "sweetalert2";

interface RiskRuleDetailsProps {
  riskRuleId: string;
  isActive: boolean;
  scope: string;
  type: string;
  name: string;
  description: string;
  expression: string;
  action: string;
  createDate: string;
  updateDate: string;
}

const SCOPE_OPTIONS = [
  { value: "ACCOUNT", label: "Conta" },
  { value: "TRANSACTION", label: "Transação" },
  { value: "INFRACTION", label: "Infração" }
];

const TYPE_OPTIONS = [
  { value: "AVERAGE_TICKET", label: "Ticket Médio" },
  { value: "MAXIMUM_VALUE_PER_TRANSFER", label: "Valor Máximo por Transferência" },
  { value: "MAXIMUM_TRANSFER_AMOUNT", label: "Valor Máximo de Transferência" },
  { value: "MAXIMUM_TRANSACTION_AMOUNT", label: "Valor Máximo de Transação" },
  { value: "TRANSFER_RECEIVED", label: "Transferência Recebida" },
  { value: "MED_LIMIT_FOR_ACCOUNT", label: "Limite Médio por Conta" },
  { value: "MED_LIMIT_PER_PAYER", label: "Limite Médio por Pagador" },
  { value: "AUTOMATIC_SETTLEMENT_AMOUNT_LIMIT_DAILY", label: "Limite Diário de Liquidação Automática" },
  { value: "AUTOMATIC_SETTLEMENT_AMOUNT_LIMIT_WEEKLY", label: "Limite Semanal de Liquidação Automática" },
  { value: "AUTOMATIC_SETTLEMENT_AMOUNT_LIMIT_MONTHLY", label: "Limite Mensal de Liquidação Automática" },
  { value: "WITHDRAWAL_AMOUNT_LIMIT_DAILY", label: "Limite Diário de Saque" },
  { value: "WITHDRAWAL_AMOUNT_LIMIT_WEEKLY", label: "Limite Semanal de Saque" },
  { value: "WITHDRAWAL_AMOUNT_LIMIT_MONTHLY", label: "Limite Mensal de Saque" },
  { value: "WITHDRAWAL_QUANTITY_LIMIT", label: "Limite de Quantidade de Saque" }
];

const ACTION_OPTIONS = [
  { value: "BLOCK", label: "Bloquear" },
  { value: "ALERT", label: "Alertar" }
];

export const CONDITIONS_OPTIONS = [
  { value: 'greater_than', label: 'maior que' },
  { value: 'less_than', label: 'menor que' },
  { value: 'equal_than', label: 'igual a' },
  { value: 'greater_equal_than', label: 'maior ou igual a' },
  { value: 'less_equal_than', label: 'menor ou igual a' },
  { value: 'different_than', label: 'diferente de' }
];

interface ExpressionsProps {
  type: string;
  value: number;
}

export default function RiskRulesDetails() {
  const [isUpdateData, setIsUpdateData] = useState<boolean>(false);
  const [isSubmitActivation, setIsSubmitActivation] = useState<boolean>(false);
  const [riskRule, setRiskRule] = useState<RiskRuleDetailsProps | null>(null);
  const [originalRiskRule, setOriginalRiskRule] = useState<RiskRuleDetailsProps | null>(null);
  const [isActiveChanged, setIsActiveChanged] = useState<boolean>(false);
  const [types, setTypes] = useState<string[]>([]);
  const [operator, setOperator] = useState<'OR' | 'AND'>();
  const [expressionsLabels, setExpressionLabels] = useState<ExpressionsProps[]>([]);
  const [typeExpression, setTypeExpression] = useState<string>('');
  const [valueExpression, setValueExpression] = useState<number | ''>('');

  useEffect(() => {
    getRiskRule();
  }, []);

  useEffect(() => {
    if (riskRule?.scope) {
      fetchTypes(riskRule.scope);
    }
  }, [riskRule?.scope]);

  const getRiskRule = async () => {
    const response = await getRiskRuleById({ id: getPath() });
    if (response?.data) {
      formatRiskRulesExpressions(response.data.expression);
      setRiskRule(response.data);
      setOriginalRiskRule(response.data);
      setIsActiveChanged(false);
    }
  };

  const fetchTypes = async (scope: string) => {
    const response = await getRiskRuleTypes(scope);
    if (response.status === 200) {
      setTypes(response?.data?.types);
    }
  };

  const formatRiskRulesExpressions = (expression: string) => {
    const matchesOperator = expression.split('(')[0];
    if (matchesOperator === 'OR' || matchesOperator === 'AND') {
      setOperator(matchesOperator);
    }

    const regExp = /\(([^)]+)\)/;
    const matchesRegEx = regExp.exec(expression);

    if (matchesRegEx?.length) {
      const allExpressions = matchesRegEx[1];
      const allExpressionsToArray = allExpressions.split(";");

      if (allExpressionsToArray.length) {
        const numberPattern = /\d+/g;
        const stringPattern = /[^0-9+]+/;

        const newLabels: ExpressionsProps[] = [];
        allExpressionsToArray.forEach((expression: string) => {
          const value = expression.match(numberPattern);
          const allLabelInArray = expression.match(stringPattern);
          if (allLabelInArray?.length) {
            const allLabel = allLabelInArray[0];
            const allLabelArray = allLabel.split(':');
            const label = allLabelArray[1];
            newLabels.push({ value: Number(value), type: label });
          }
        });
        setExpressionLabels(newLabels);
      }
    }
  };

  const handleUpdateRiskRules = async (e: FormEvent) => {
    e.preventDefault();
    setIsUpdateData(true);
    if (riskRule) {
      await updateRiskRule({ id: getPath(), data: { ...riskRule, expression: generateExpressionString() } });
      setIsUpdateData(false);
      setOriginalRiskRule(riskRule);
    }
  };

  const generateExpressionString = () => {
    if (expressionsLabels.length === 0) return '';
    const operatorString = operator ? `${operator}(` : '';
    const expressionsString = expressionsLabels.map(exp => `key:${exp.type}:${exp.value}`).join(';');
    return `${operatorString}${expressionsString})`;
  };

  const handleIsActiveRiskRule = async () => {
    setIsSubmitActivation(true);
    if (riskRule) {
      if (riskRule.isActive) {
        await deactivateRiskRule({ id: getPath() });
        setRiskRule({ ...riskRule, isActive: false });
        setIsActiveChanged(true);
      } else {
        await activateRiskRule({ id: getPath() });
        setRiskRule({ ...riskRule, isActive: true });
        setIsActiveChanged(true);
      }
    }
    setIsSubmitActivation(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setRiskRule((prevRiskRule) =>
      prevRiskRule ? { ...prevRiskRule, [name]: value } : null
    );
  };

  const handleSelectChange = (name: string, value: string) => {
    setRiskRule((prevRiskRule) =>
      prevRiskRule ? { ...prevRiskRule, [name]: value } : null
    );
  };

  const handleChangeExpressions = ({ type, value }: { type: string, value: number }) => {
    setExpressionLabels((prevLabels) => [...prevLabels, { type, value }]);
  };

  const handleRemoveExpression = (index: number) => {
    if (expressionsLabels.length === 1) {
      return Swal.fire({
        icon: "warning",
        title: "É necessário ao menos uma expressão!",
        confirmButtonColor: "var(--gray-500)",
      })
    }
    setExpressionLabels((prevLabels) => {
      const newLabels = prevLabels.filter((_, i) => i !== index);
      return newLabels;
    });
  };

  const fetchTypesByScope = async (event: string) => {
    if (riskRule) {
      setRiskRule({ ...riskRule, scope: event });
      const response = await getRiskRuleTypes(event);
      if (response.status === 200) {
        setTypes(response.data.types);
      }
    }
  };

  const isChanged = () => {
    if (!originalRiskRule || !riskRule) return false;
    const { isActive, ...restOriginal } = originalRiskRule;
    const { isActive: currentIsActive, ...restCurrent } = riskRule;
    return JSON.stringify(restCurrent) !== JSON.stringify(restOriginal);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (["e", "E", "+", "-", "."].includes(event.key)) {
      event.preventDefault();
    }
  };

  if (!riskRule) {
    return (
      <PlatformTemplate>
        <Skeleton className="h-full w-full" />
      </PlatformTemplate>
    );
  }

  return (
    <PlatformTemplate>
      <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-lg border">
        <h2 className="text-xl mb-10 font-semibold text-neutral-600">Detalhes da Regra de Risco</h2>
        <form onSubmit={handleUpdateRiskRules} className="flex items-start justify-between gap-5">
          <div className="w-1/2 h-full flex flex-col">
            <div className="flex flex-col gap-1 mb-4">
              <label className="block text-sm font-medium text-gray-700">Nome</label>
              <Input
                name="name"
                value={riskRule.name}
                onChange={handleChange}
                className="mt-1 block w-full"
                disabled={isSubmitActivation || isUpdateData}
              />
            </div>
            <div className="flex flex-col gap-1 mb-4">
              <label className="block text-sm font-medium text-gray-700">Descrição</label>
              <Input
                name="description"
                value={riskRule.description}
                onChange={handleChange}
                className="mt-1 block w-full"
                disabled={isSubmitActivation || isUpdateData}
              />
            </div>
            <div className="flex flex-col gap-1 mb-4">
              <label className="block text-sm font-medium text-gray-700">Scope</label>
              <Select
                disabled={isSubmitActivation || isUpdateData}
                value={riskRule.scope}
                onValueChange={(event) => fetchTypesByScope(event)}
              >
                <SelectTrigger>
                  <SelectValue>
                    {SCOPE_OPTIONS.find((option) => option.value === riskRule.scope)?.label}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Scope</SelectLabel>
                    {SCOPE_OPTIONS.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-col gap-1 mb-4">
              <label className="block text-sm font-medium text-gray-700">Tipo</label>
              <Select
                disabled={isSubmitActivation || isUpdateData}
                value={riskRule.type}
                onValueChange={(value) => handleSelectChange("type", value)}
              >
                <SelectTrigger>
                  <SelectValue>
                    {TYPE_OPTIONS.find((option) => option.value === riskRule.type)?.label}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Tipo</SelectLabel>
                    {types.map((type) => {
                      const typeOption = TYPE_OPTIONS.find((typeOption) => type === typeOption.value);
                      if (typeOption) {
                        return (
                          <SelectItem key={typeOption.value} value={typeOption.value}>
                            {typeOption.label}
                          </SelectItem>
                        );
                      }
                      return null;
                    })}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-col gap-1 mb-4">
              <label className="block text-sm font-medium text-gray-700">Ação</label>
              <Select
                disabled={isSubmitActivation || isUpdateData}
                value={riskRule.action}
                onValueChange={(value) => handleSelectChange("action", value)}
              >
                <SelectTrigger>
                  <SelectValue>
                    {ACTION_OPTIONS.find((option) => option.value === riskRule.action)?.label}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Ação</SelectLabel>
                    {ACTION_OPTIONS.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="border w-1/2 h-full flex flex-col gap-5 p-5 rounded-md">
            <h1 className="font-semibold text-neutral-500">Configuração de Expressões</h1>
            <div className="">
              <Select disabled value={operator} onValueChange={(event: 'OR' | 'AND') => setOperator(event)}>
                <SelectTrigger>
                  <SelectValue placeholder="Operador:" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Operador</SelectLabel>
                    <SelectItem value='AND'>E</SelectItem>
                    <SelectItem value='OR'>OU</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center gap-1">
              <Select onValueChange={(event) => setTypeExpression(event)}>
                <SelectTrigger>
                  <SelectValue placeholder="Condições:" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>Condições</SelectLabel>
                    {CONDITIONS_OPTIONS.map((condition) => (
                      <SelectItem key={condition.value} value={condition.value}>{condition.label}</SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
              <Input
                placeholder="Valor"
                className="w-20 appearance:textfield [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                type="number"
                value={valueExpression}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value === '' || Number(value) >= 0) {
                    setValueExpression(value === '' ? '' : Number(value));
                  }
                }}
                onKeyDown={handleKeyDown}
              />
              <Button
                disabled={typeExpression === '' || valueExpression === ''}
                onClick={() => handleChangeExpressions({ type: typeExpression, value: Number(valueExpression) })}
                variant="default">
                <Plus size={20} />
              </Button>
            </div>
            <div className="w-full min-h-10 max-h-[200px] border rounded-md flex flex-col items-center justify-start overflow-auto">
              {expressionsLabels.length !== 0 ? (
                expressionsLabels.map((expression, index) => (
                  <div
                    key={index}
                    className="w-full border-b last:border-b-0 flex items-center justify-between p-2"
                  >
                    <div className="flex items-center gap-2 text-neutral-500">
                      {CONDITIONS_OPTIONS.map((condition) => {
                        if (condition.value === expression.type) {
                          return <span key={condition.value}>{condition.label}</span>;
                        }
                        return null;
                      })}:
                      <span className="text-neutral-600">{expression.value}</span>
                    </div>
                    <Button variant="ghost" onClick={() => handleRemoveExpression(index)}>
                      <Trash2 size={15} />
                    </Button>
                  </div>
                ))
              ) : (
                <p className="w-full h-10 flex items-center justify-center text-sm text-neutral-500">Nenhuma expressão.</p>
              )}
            </div>
            <div className="flex flex-col items-end gap-10 mt-4">
              <div className="flex items-center gap-2">
                <p>Ativar Regra:</p>
                <Switch disabled={isSubmitActivation} checked={riskRule.isActive} onClick={handleIsActiveRiskRule} />
              </div>
              {isChanged() && (
                <div className="flex items-center justify-between">
                  <Button disabled={isUpdateData} type="submit" variant="outline">
                    {!isUpdateData ? 'Atualizar' : <span className="flex items-center gap-2"><Loader2 className="w-3 h-3 animate-spin" />Atualizando</span>}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </PlatformTemplate>
  );
}