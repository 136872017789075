import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Input } from '../../../../../components/ui/input'
import { Skeleton } from '../../../../../components/ui/skeleton'
import { Button } from "../../../../../components/ui/button"
import { getOwnersById, updateOwnerGenerateApiKey } from '../../../../../api/endpoints/owner'
import { getPath } from '../../../../../utils/helpers/path'
import { Card, CardContent } from '../../../../../components/ui/card'
import { PlatformTemplate } from '../../../../../components/layout/template'
import { TabsOwner } from '../_components/tabs-owner'
import { Eye, EyeOff, KeyRound, Loader2 } from 'lucide-react'
import Swal from 'sweetalert2'

interface OwnerIntegrationProps {
  userName: string
  password: string
}

export default function OwnerIntegration() {
  const [owner, setOwner] = useState<OwnerIntegrationProps>()
  const [name, setName] = useState<string>('')
  const [integrations, setIntegrations] = useState({
    clientId: '',
    clientSecret: ''
  })

  const fetchOwnerById = async () => {
    const response = await getOwnersById(getPath())
    if (response?.data?.user) {
      setName(response?.data?.name)
      setOwner(response?.data?.user)
      setIntegrations({
        ...integrations,
        clientId: response?.data?.user?.userName,
        clientSecret: response?.data?.user?.password
      })
    }

    return null
  }

  useEffect(() => {
    fetchOwnerById()
  }, [])

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsOwner value="integrations" />
          {owner ? <OwnerIntegrationContent name={name} integrations={integrations} setIntegrations={setIntegrations} /> : <Skeleton className="w-full h-full" />}
        </div>
      </div>
    </PlatformTemplate>
  )
}

const OwnerIntegrationContent = ({ name, integrations, setIntegrations }: {
  name: string,
  integrations: { clientId: string, clientSecret: string },
  setIntegrations: Dispatch<SetStateAction<{ clientId: string, clientSecret: string }>>
}) => {
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [showMask, setShowMask] = useState<boolean>(true)
  const mask = "********-****-****-****-************"

  const handleNewApiKey = async () => {
    setIsSubmit(true)

    const response = await updateOwnerGenerateApiKey({ ownerId: getPath() })

    if (response.status === 200) {
      setIntegrations({ ...integrations, clientSecret: response?.data?.user?.password })
      Swal.fire({
        icon: "success",
        title: "Nova API Key gerada com sucesso!",
        confirmButtonColor: "var(--gray-500)",
      })
    }

    if (response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: "Algo inesperado aconteceu. Não foi possível gerar uma nova Api Key",
        confirmButtonColor: "var(--gray-500)",
      })
    }

    return setIsSubmit(false)
  }

  return (
    <div className='flex items-center justify-center'>
      <div className="w-2/3 flex flex-col gap-8 p-10">
        <div className='flex items-center justify-between'>
          <h1 className='text-lg font-semibold text-zinc-600'>Integrações de {name}</h1>
          <Button variant="secondary" onClick={() => setShowMask(!showMask)}>{showMask ? <EyeOff size={20} /> : <Eye size={20} />}</Button>
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="font-semibold">Client ID</h2>
          <div className="flex items-center justify-between gap-2">
            <Input value={!showMask ? integrations.clientId : mask} />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="font-semibold">Client Secret</h2>
          <div className="flex items-center justify-between gap-2">
            <Input value={!showMask ? integrations.clientSecret : mask} />
            {isSubmit ?
              <Loader2 className="mr-2 h-8 w-8 animate-spin" /> :
              <Button onClick={handleNewApiKey}><KeyRound size={15} className='mr-2' />Gerar nova Chave</Button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
