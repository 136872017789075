import { useNavigate } from "react-router-dom"
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "../../../../../components/ui/tabs"
import { Landmark } from "lucide-react"
import { getPath } from "../../../../../utils/helpers/path"

export const TabsAccount = ({ value }: { value: string }) => {
  const navigate = useNavigate()

  return (
    <Tabs value={value} className="flex flex-col gap-3">
      <div className="w-full flex items-center py-3 gap-2">
        <Landmark size={20} />
        <h1 className="text-[20px] font-semibold">Contas</h1>
      </div>
      <TabsList className="grid w-full grid-cols-5">
        <TabsTrigger value="details" onClick={() => navigate(`/account/details/${getPath()}`)}>Detalhes</TabsTrigger>
        <TabsTrigger value="extract" onClick={() => navigate(`/account/extract/${getPath()}`)}>Extrato</TabsTrigger>
        <TabsTrigger value="settings" onClick={() => navigate(`/account/settings/${getPath()}`)}>Configurações</TabsTrigger>
        <TabsTrigger value="activities" onClick={() => navigate(`/account/activities/${getPath()}`)}>Atividades</TabsTrigger>
        <TabsTrigger value="risk-rules" onClick={() => navigate(`/account/risk/${getPath()}`)}>Regras de Risco</TabsTrigger>
      </TabsList>
    </Tabs>
  )
}
