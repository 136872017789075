import React, { useState } from "react"
import { countFilledData } from "../../../../utils/helpers/calculate"
import { getTransactions } from "../../../../api/endpoints/transaction"
import { trimSpaces } from "../../../../utils/helpers/trimSpaces"
import { Input } from "../../../../components/ui/input"
import { Label } from "../../../../components/ui/label"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from "../../../../components/ui/sheet"
import { handleEnterKeyDown } from "../../../../utils/helpers/keyboard"
import { Button } from "../../../../components/ui/button"
import { Badge } from "../../../../components/ui/badge"
import { Filter, Search } from "lucide-react"
import { TransactionProps, Filters } from "../page"
import { DatePicker } from "../../../../components/ui/date-picker"
import { format } from "date-fns"

type FilterKey = keyof Filters

interface TableFiltersTransactionProps {
  filters: Filters
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
  setTransactions: React.Dispatch<React.SetStateAction<any[]>>
  pageIndex: number
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  appliedFilters: Filters
  setAppliedFilters: React.Dispatch<React.SetStateAction<Filters>>
}

export const TableFiltersTransaction: React.FC<TableFiltersTransactionProps> = ({
  filters, setFilters, setTransactions, pageIndex, setPageIndex, setTotalPages, pageSize, appliedFilters, setAppliedFilters
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitFilters = async () => {
    if (!filters.transactionId.trim() && !filters.debtorName.trim() && !filters.creditorName.trim() && !filters.createDateStart && !filters.createDateEnd) return
    setIsSubmitting(true)

    const response: any = await getTransactions({ ...filters, pageIndex: 1, pageSize })

    if (response?.data?.items) {
      setTransactions(response?.data?.items)
      setAppliedFilters(filters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setTransactions([])
      setAppliedFilters(filters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const removeAllFilters = async () => {
    const resetFilters: Filters = {
      transactionId: '',
      debtorName: '',
      creditorName: '',
      createDateStart: '',
      createDateEnd: '',
    }
    setFilters(resetFilters)

    const response: any = await getTransactions({ pageIndex: 1, pageSize })

    if (response?.data?.items) {
      setTransactions(response?.data?.items)
      setAppliedFilters(resetFilters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setTransactions([])
      setAppliedFilters(resetFilters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const handleRemoveFilter = (key: FilterKey) => {
    const newFilters = { ...filters, [key]: '' }
    setFilters(newFilters)
    if (countFilledData(newFilters) === 0) {
      removeAllFilters()
    } else {
      submitFilters()
    }
  }

  const handleInputChange = (key: FilterKey, value: string) => {
    setFilters({ ...filters, [key]: trimSpaces(value) })
  }

  const handleDateChange = (key: FilterKey, value: Date | undefined) => {
    const formattedDate = value ? format(value, "yyyy-MM-dd") : ""
    setFilters({ ...filters, [key]: formattedDate })
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="flex w-2/3 gap-2">
        <Input
          type="text"
          onKeyDown={handleEnterKeyDown(submitFilters)}
          placeholder="Procurar pelo ID da Transação..."
          disabled={isSubmitting}
          value={filters.transactionId}
          onChange={(e) => handleInputChange('transactionId', e.target.value)}
          className="max-w-sm"
        />
        <Button disabled={isSubmitting || !filters.transactionId.trim()} onClick={submitFilters} variant="default">
          <Search size={20} />
        </Button>
        <Sheet>
          <SheetTrigger>
            <Button disabled={isSubmitting} className="flex gap-2">
              <Filter size={15} />
              Filtros
            </Button>
          </SheetTrigger>
          <SheetContent className="flex flex-col gap-8">
            <SheetHeader>
              <SheetTitle className="flex items-center gap-2">
                <Filter size={20} />
                Filtros
              </SheetTitle>
              <SheetDescription>
                Utilize os filtros personalizados para encontrar dados que está buscando na tabela
              </SheetDescription>
            </SheetHeader>
            <div className="flex flex-col overflow-y-auto px-1 gap-5">
              {(['transactionId', 'debtorName', 'creditorName'] as FilterKey[]).map((key) => (
                <div key={key}>
                  <Label>{filterLabels[key]}</Label>
                  <Input
                    type="text"
                    value={filters[key]}
                    onChange={(e) => handleInputChange(key, e.target.value)}
                  />
                </div>
              ))}
              <div className="flex flex-col gap-2">
                <Label>Data de Início</Label>
                <DatePicker
                  selectedDate={filters.createDateStart ? new Date(filters.createDateStart) : undefined}
                  onDateChange={(date) => handleDateChange('createDateStart', date)}
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label>Data de Fim</Label>
                <DatePicker
                  selectedDate={filters.createDateEnd ? new Date(filters.createDateEnd) : undefined}
                  onDateChange={(date) => handleDateChange('createDateEnd', date)}
                />
              </div>
            </div>
            <SheetFooter>
              <SheetClose asChild>
                <Button variant="secondary" disabled={isSubmitting} onClick={removeAllFilters}>
                  Remover todos os Filtros
                </Button>
              </SheetClose>
              <SheetClose asChild>
                <Button disabled={isSubmitting} onClick={submitFilters}>
                  Aplicar
                </Button>
              </SheetClose>
            </SheetFooter>
          </SheetContent>
        </Sheet>
        {countFilledData(appliedFilters) !== 0 && (
          <div className="w-8 h-8 flex items-center justify-center rounded-[50%] bg-teal-700 text-white select-none">
            {countFilledData(appliedFilters)}
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        {(['transactionId', 'debtorName', 'creditorName', 'createDateStart', 'createDateEnd'] as FilterKey[]).map((key) => (
          appliedFilters[key] && (
            <TagsFilter
              key={key}
              filterKey={key}
              filterValue={appliedFilters[key]}
              removeFilter={handleRemoveFilter}
            />
          )
        ))}
      </div>
    </div>
  )
}

const filterLabels: { [key in FilterKey]: string } = {
  transactionId: "ID da Transação",
  debtorName: "Nome do Devedor",
  creditorName: "Nome do Credor",
  createDateStart: "Data de Início",
  createDateEnd: "Data de Fim",
}

interface TagsFilterProps {
  filterKey: FilterKey
  filterValue: string
  removeFilter: (key: FilterKey) => void
}

const TagsFilter: React.FC<TagsFilterProps> = ({ filterKey, filterValue, removeFilter }) => (
  <Badge variant="secondary" onClick={() => removeFilter(filterKey)} className="cursor-pointer">
    {filterLabels[filterKey]}: {filterValue} ✕
  </Badge>
)
