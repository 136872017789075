import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getOwnerBlocks } from "../../../../api/endpoints/owner"
import { Skeleton } from "../../../../components/ui/skeleton"
import { PlatformTemplate } from "../../../../components/layout/template"
import { Undo2, UserRoundX } from "lucide-react"
import { Button } from "../../../../components/ui/button"
import TableOwnerBlocks from "./_components/table-owner-blocked"

export type OwnerBlockProps = {
  ownerBlockId: string
  ownerId: string
  blockDate: string
  unblockDate: string | null
  blockUserId: string
  unblockUserId: string | null
  active: boolean
}

export type Filters = {
  ownerBlockId: string
  ownerId: string
  blockDateStart: string
  blockDateEnd: string
  unblockDateStart: string
  unblockDateEnd: string
  blockUserId: string
  unblockUserId: string
  active: string
  [key: string]: string
}

export default function OwnerBlocks() {
  const navigate = useNavigate()
  const [ownerBlocks, setOwnerBlocks] = useState<OwnerBlockProps[]>()
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [filters, setFilters] = useState<Filters>({
    ownerBlockId: '',
    ownerId: '',
    blockDateStart: '',
    blockDateEnd: '',
    unblockDateStart: '',
    unblockDateEnd: '',
    blockUserId: '',
    unblockUserId: '',
    active: '',
  })
  const [appliedFilters, setAppliedFilters] = useState<Filters>(filters)

  const fetchOwnerBlocks = async (pageIndex: number, pageSize: number, filters: Filters) => {
    const response = await getOwnerBlocks({ pageIndex, pageSize, ...filters })
    setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
    if (response?.data?.items) {
      setOwnerBlocks(response?.data?.items)
    } else {
      setOwnerBlocks([])
    }
  }

  useEffect(() => {
    fetchOwnerBlocks(pageIndex, pageSize, appliedFilters)
  }, [pageIndex, pageSize, appliedFilters])

  return (
    <PlatformTemplate>
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex items-center justify-between">
          <div className="w-full flex items-center py-3 gap-4">
            <Button onClick={() => navigate("/owner")} variant="outline">
              <Undo2 size={20} />
            </Button>
            <div className="flex items-center gap-2">
              <UserRoundX size={20} />
              <h1 className="text-[20px] font-semibold">Bloqueados</h1>
            </div>
          </div>
        </div>
        {ownerBlocks ? (
          <TableOwnerBlocks
            ownerBlocks={ownerBlocks}
            setOwnerBlocks={setOwnerBlocks}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            filters={filters}
            setFilters={setFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
        ) : (
          <Skeleton className="w-full h-full" />
        )}
      </div>
    </PlatformTemplate>
  )
}
