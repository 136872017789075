import React, { useEffect, useState } from "react"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { getPath } from "../../../../../utils/helpers/path"
import { addAlertComment, getAlertById, getAlertComments } from "../../../../../api/endpoints/alert"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { TabsAlert } from "../_components/tabs-alert"
import { Card, CardContent } from "../../../../../components/ui/card"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../../../../components/ui/accordion"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import { Button } from "../../../../../components/ui/button"
import { Loader2, Plus } from "lucide-react"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "../../../../../components/ui/alert-dialog"
import { Textarea } from "../../../../../components/ui/textarea"
import { IsEmpty } from "../../../../../utils/helpers/validate"
import Swal from "sweetalert2"

interface AlertCommentProps {
  id: string,
  comment: string,
  createDate: string
  userName: string,
  alertId: string,
  userId: string,
}

interface AlertProps {
  alertId: string,
  scope: string,
  type: string,
  referenceId: string,
  accountId: string,
  accountNumber: string,
  ownerId: string,
  ownerName: string,
  priority: string,
  status: string,
  expression: string,
  value: string,
  resolution: string,
  resolutionDate: string,
  openedUserId: string,
  openedUserName: string,
  closedUserId: string,
  closedUserName: string,
  comments: [
    {
      id: string,
      alertId: string,
      comment: string,
      userId: string,
      userName: string,
      createDate: string
    }
  ],
  histories: [
    {
      id: string,
      alertId: string,
      action: string,
      oldStatus: string,
      newStatus: string,
      userId: string,
      userName: string,
      createDate: string
    },
    {
      id: string,
      alertId: string,
      action: string,
      oldStatus: string,
      newStatus: string,
      userId: string,
      userName: string,
      createDate: string
    }
  ],
  createDate: string,
  updateDate: string
}

export default function AlertComments() {
  const [newComment, setNewComment] = useState<string>('')
  const [comment, setComment] = useState<AlertCommentProps[]>()
  const [alert, setAlert] = useState<AlertProps>()
  const [isSubmit, setIsSubmit] = useState<boolean>(false)

  const fetchAlertComments = async () => {
    const response = await getAlertComments({ alertId: getPath() })
    if (response?.data) {
      return setComment(response?.data)
    }
  }

  const fetchAlert = async () => {
    const response = await getAlertById({ alertId: getPath() })
    if (response?.data) {
      return setAlert(response.data)
    }
  }

  useEffect(() => {
    fetchAlert()
    fetchAlertComments()
  }, [])

  const handleNewComment = async () => {
    setIsSubmit(true)

    if (IsEmpty(newComment)) {
      setIsSubmit(false)
      return Swal.fire({
        icon: "error",
        title: "Preencha o campo antes de enviar!",
        confirmButtonColor: "var(--gray-500)",
      })
    }

    const response = await addAlertComment({ alertId: getPath(), comment: newComment })

    if (response.status === 200) {
      setNewComment('')
      await fetchAlertComments()
      setIsSubmit(false)
      return Swal.fire({
        icon: "success",
        title: "Comentário adicionado!",
        confirmButtonColor: "var(--gray-500)",
      })
    } else {
      setIsSubmit(false)
      return Swal.fire({
        icon: "error",
        title: "Não foi possível realizar a operação.",
        confirmButtonColor: "var(--gray-500)",
      })
    }
  }

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsAlert value="comments" />
          {alert && comment ?
            <div className="my-10">
              {alert.status === "OPEN" &&
                <div className="w-full flex items-center justify-end mb-5">
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <Button disabled={isSubmit} variant="default" className="capitalize">
                        {isSubmit ? <span className="flex items-center gap-2"><Loader2 className="w-5 h-5 animate-spin" />Enviando...</span> : "adicionar comentário"}
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle className='flex items-center gap-2'>Novo comentário</AlertDialogTitle>
                        <AlertDialogDescription className='flex flex-col gap-2'>
                          <Textarea rows={5} value={newComment} onChange={(event) => setNewComment(event.target.value)} />
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancelar</AlertDialogCancel>
                        <AlertDialogAction onClick={handleNewComment}>Enviar</AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </div>
              }
              {comment.map((item: any, index: number) => (
                <div key={index}>
                  {index !== 0 && (
                    <div className="h-5 px-10">
                      <div className="w-1 h-full bg-gray-300"></div>
                    </div>
                  )}
                  <Card>
                    <CardContent className="flex items-center justify-between py-3 px-5">
                      <Accordion type="single" collapsible className="w-full">
                        <AccordionItem value="item-1">
                          <AccordionTrigger className="flex items-center gap-5">
                            <div className="w-full flex items-center justify-between">
                              <p className="w-3/4 text-gray-700 text-justify">{item.comment}</p>
                              <p className="w-1/4 font-semibold flex items-center justify-end">{convertUTCToUserTimezone(item.createDate, getUserTimezoneOffset())}</p>
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div className="w-full flex flex-col gap-6 p-4 bg-white">
                              <div className="w-full flex justify-between items-center gap-5  border-b pb-2">
                                <h3 className="font-semibold text-gray-700">Comentário:</h3>
                                <p className="text-gray-600 text-end">{item.comment}</p>
                              </div>
                              <div className="w-full flex justify-between items-center gap-5 border-b pb-2">
                                <h3 className="font-semibold text-gray-700">Usuário:</h3>
                                <p className="text-gray-600">{item.userName}</p>
                              </div>
                              <div className="w-full flex justify-between items-center gap-5 border-b pb-2">
                                <h3 className="font-semibold text-gray-700">ID do Usuário:</h3>
                                <p className="w-1/2 text-end text-gray-600">{item.userId}</p>
                              </div>
                              <div className="w-full flex justify-between items-center gap-5">
                                <h3 className="font-semibold text-gray-700">ID do Alerta:</h3>
                                <p className="text-gray-600">{item.alertId}</p>
                              </div>
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </div> :
            <Skeleton className="h-full w-full" />
          }
          {comment?.length === 0 &&
            <div className="h-[300px] w-full flex items-center justify-center font-semibold text-xl bg-neutral-100 shadow">
              Nenhum Comentário ainda :(
            </div>}
        </div>
      </div>
    </PlatformTemplate>
  )
}