/**
 * Converte uma data UTC para o fuso horário do usuário.
 * @param utcDate - Data em UTC (exemplo: "2024-06-24T12:11:11Z").
 * @param timezoneOffset - Deslocamento do fuso horário do usuário (exemplo: "-03:00:00" ou "03:00:00").
 * @returns Data convertida para o fuso horário do usuário no formato dd/MM/yyyy HH:mm:ss.
 */
export function convertUTCToUserTimezone(dateString: string, timezoneOffset: string | null): string {
    // Converte a string de data para um objeto Date
    const date = new Date(dateString);

    // Se o timezoneOffset for nulo, define como "+00:00"
    if (timezoneOffset === null) {
        timezoneOffset = "+00:00";
    }

    // Calcula o offset em minutos a partir do timezone string
    const offsetSign = timezoneOffset[0] === '-' ? -1 : 1;
    const [hours, minutes] = timezoneOffset.slice(1).split(':').map(Number);
    const offsetMinutes = offsetSign * (hours * 60 + minutes);

    // Ajusta a data com o offset
    date.setMinutes(date.getMinutes() + offsetMinutes);

    // Formata a data e hora no padrão dd/mm/yyyy hh:mm:ss
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Mês é zero-indexado
    const year = date.getUTCFullYear();
    const hoursStr = String(date.getUTCHours()).padStart(2, '0');
    const minutesStr = String(date.getUTCMinutes()).padStart(2, '0');
    const secondsStr = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hoursStr}:${minutesStr}:${secondsStr}`;
}