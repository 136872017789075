import { useState } from "react"
import { PlatformTemplate } from "../../../../components/layout/template"
import { Steps } from "./_components/step-owner"

export type CreateOwnerProps = {
  name: string,
  fantasyName: string,
  documentType: string,
  documentNumber: string,
  email: string,
  createAccount: boolean,
  address: {
    street: string,
    number: string,
    complement: string,
    neighborhood: string,
    city: string,
    stateCode: string,
    postalCode: string
  }
}

export default function CreateOwner() {
  const [owner, setOwner] = useState<CreateOwnerProps>({
    'name': '',
    'fantasyName': '',
    'documentType': "CNPJ",
    'documentNumber': '',
    'email': '',
    'createAccount': true,
    'address': {
      'street': '',
      'number': '',
      'complement': '',
      'neighborhood': '',
      'city': '',
      'stateCode': '',
      'postalCode': ''
    }
  })
  const [stepPosition, setStepPosition] = useState<number>(0)
  
  return (
    <PlatformTemplate>
      <div className="w-full h-full flex flex-col items-center justify-center">
        <Steps
          owner={owner}
          setOwner={setOwner}
          stepPosition={stepPosition}
          setStepPosition={setStepPosition}
        />
      </div>
    </PlatformTemplate>
  )
}
