import api from '../index'
import { HeadersAccessToken } from '../config'

/**
 * => Interface que define os parâmetros para obter proprietários.
 */
interface GetOwnersProps {
    pageIndex?: number
    pageSize?: number
    id?: string
    name?: string
    email?: string
    documentNumber?: string
}

/**
 * => Função para obter proprietários com base nos parâmetros fornecidos.
 * @param params Parâmetros para filtragem de proprietários.
 * @returns Lista de proprietários ou erro.
 */
export const getOwners = async (params: GetOwnersProps) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const queryParams = {
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
        id: params.id,
        name: params.name,
        email: params.email,
        documentNumber: params.documentNumber,
    }

    const urlParams = Object.entries(queryParams)
        .filter(([_, value]) => (typeof value === 'string' ? value.trim() !== '' : value !== undefined && value !== null))
        .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
        .join('&')

    const url = `/v1/owner/?${urlParams}`

    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para obter um proprietário pelo ID.
 * @param id ID do proprietário.
 * @returns Dados do proprietário ou erro.
 */
export const getOwnersById = async (id: string) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/owner/${id}`
    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para obter contas de liquidação por ID do proprietário.
 * @param ownerId ID do proprietário.
 * @returns Contas de liquidação ou erro.
 */
export const getSettlementAccountsByOwnerId = async (ownerId: string) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/owner/${ownerId}/settlement_accounts`
    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função criar uma api key nova.
 * @param ownerId ID do proprietário.
 * @returns Criação da nova key ou erro.
 */
export const updateOwnerGenerateApiKey = async ({ ownerId }: { ownerId: string }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `v1/owner/${ownerId}/generateapikey`
    try {
        return await api.put(url, {}, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para criar um novo proprietário.
 * @param owner Dados do novo proprietário.
 * @returns Resultado da criação ou erro.
 */
export const createOwner = async (owner: any) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/owner`
    try {
        return await api.post(url, owner, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para obter a visualização das contas de liquidação por chave de visualização.
 * @param ownerId ID do proprietário.
 * @param key Chave de visualização.
 * @returns Visualização das contas de liquidação ou erro.
 */
export const getSettlementAccountsPreviewKey = async ({ ownerId, key }: { ownerId: string, key: string }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/owner/${ownerId}/settlement_accounts/preview/${key}`
    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para criar um banco adicional para um proprietário.
 * @param ownerId ID do proprietário.
 * @param key Chave do banco.
 * @returns Resultado da criação ou erro.
 */
export const createOtherBankByOwnerId = async ({ ownerId, key }: { ownerId: string, key: string }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/owner/${ownerId}/settlement_accounts/${key}`
    try {
        return await api.post(url, {}, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para deletar um banco adicional de um proprietário.
 * @param ownerId ID do proprietário.
 * @param key Chave do banco.
 * @returns Resultado da deleção ou erro.
 */
export const deleteOtherBankByOwnerId = async ({ ownerId, key }: { ownerId: string, key: string }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/owner/${ownerId}/settlement_accounts/${key}`
    try {
        return await api.delete(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para atualizar o endereço de um proprietário.
 * @param id ID do proprietário.
 * @param owner Dados do endereço a serem atualizados.
 * @returns Resultado da atualização ou erro.
 */
export const updateAddressOwner = async (id: string, owner: any) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/owner/${id}`
    try {
        return await api.put(url, owner, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para obter a atividade de um proprietário pelo ID.
 * @param id ID do proprietário.
 * @param pageIndex Índice da página.
 * @param pageSize Tamanho da página.
 * @returns Atividade do proprietário ou erro.
 */
export const getOwnerActivityById = async (id: string, pageIndex?: number, pageSize?: number) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const queryParams = {
        pageIndex: pageIndex,
        pageSize: pageSize,
    }

    const urlParams = Object.entries(queryParams)
        .filter(([_, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
        .join('&')

    const url = `/v1/owner/${id}/activity?${urlParams}`

    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para bloquear um proprietário.
 * @param id ID do proprietário.
 * @returns Resultado do bloqueio ou erro.
 */
export const blockOwner = async ({ id, reason }: { id: string, reason: string }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/owner/${id}/Block`
    try {
        return await api.post(url, { reason }, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para desbloquear um proprietário.
 * @param id ID do proprietário.
 * @returns Resultado do desbloqueio ou erro.
 */
export const unblockOwner = async ({ id, blockId, reason }: { id: string | undefined, blockId: string | undefined, reason: string }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/owner/${id}/unblock/${blockId}`
    try {
        return await api.post(url, { reason }, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Interface que define os parâmetros para obter bloqueios de proprietários.
 */
interface GetOwnerBlocksProps {
    pageIndex?: number
    pageSize?: number
    ownerBlockId?: string
    ownerId?: string
    blockDateStart?: string
    blockDateEnd?: string
    unblockDateStart?: string
    unblockDateEnd?: string
    blockUserId?: string
    unblockUserId?: string
    active?: string
}

/**
 * => Função para obter bloqueios de proprietários com base nos parâmetros fornecidos.
 * @param params Parâmetros para filtragem de bloqueios de proprietários.
 * @returns Lista de bloqueios de proprietários ou erro.
 */
export const getOwnerBlocks = async (params: GetOwnerBlocksProps) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const queryParams = {
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
        ownerBlockId: params.ownerBlockId,
        ownerId: params.ownerId,
        blockDateStart: params.blockDateStart,
        blockDateEnd: params.blockDateEnd,
        unblockDateStart: params.unblockDateStart,
        unblockDateEnd: params.unblockDateEnd,
        blockUserId: params.blockUserId,
        unblockUserId: params.unblockUserId,
        active: params.active,
    }

    const urlParams = Object.entries(queryParams)
        .filter(([_, value]) => (typeof value === 'string' ? value.trim() !== '' : value !== undefined && value !== null))
        .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
        .join('&')

    const url = `/v1/owner/blocks?${urlParams}`

    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para obter detalhes de um bloqueio de proprietário pelo ID.
 * @param id ID do bloqueio.
 * @returns Detalhes do bloqueio ou erro.
 */
export const getOwnerBlockById = async (id: string) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/owner/blocks/${id}`
    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para obter todos os bloqueios de um proprietário.
 * @param id ID do proprietário.
 * @returns Lista de bloqueios do proprietário ou erro.
 */
export const getOwnerBlocksByOwnerId = async (id: string) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/owner/${id}/blocks`
    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}
