// => auth.ts
import api from '../index'
import { HeadersAccessToken } from '../config'

/**
 * => Interface que define os parâmetros de autenticação.
 */
export interface AuthProps {
  userName: string | undefined,
  password: string | undefined,
  email: string | undefined,
  type: string | undefined,
  roles: string[] | undefined,
  timezoneId: number | undefined
}

/**
 * => Função para fazer a validação do Usuário logado.
 * @param params Parâmetros para autenticar.
 * @returns Informações do Usuário atualizadas ou erro.
 */
export const authCheck = async () => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = '/v1/auth/check'

  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error) {
    return error
  }
}

/**
 * => Interface que define os parâmetros para obter usuários.
 */
export interface GetUsersProps {
  pageIndex?: number
  pageSize?: number
  id?: string
  userName?: string
  email?: string
  type?: '' | 'Super' | 'Admin' | 'User'
  roles?: string
}

/**
 * => Função para obter usuários com base nos parâmetros fornecidos.
 * @param params Parâmetros para filtragem de usuários.
 * @returns Lista de usuários ou erro.
 */
export const getUsers = async (params: GetUsersProps = {}) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const queryParams = {
    pageIndex: params.pageIndex,
    pageSize: params.pageSize,
    id: params.id,
    userName: params.userName,
    email: params.email,
    type: params.type,
    roles: params.roles
  }

  const urlParams = Object.entries(queryParams)
    .filter(([_, value]) => (typeof value === 'string' ? value.trim() !== '' : value !== undefined && value !== null))
    .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
    .join('&')

  const url = `/v1/auth/user?${urlParams}`

  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para obter um usuário pelo ID.
 * @param userId ID do usuário.
 * @returns Dados do usuário ou erro.
 */
export const getUserById = async ({ userId }: { userId: string }) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/auth/user/${userId}`
  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para obter os papéis padrão.
 * @returns Lista de papéis ou erro.
 */
export const getRoles = async () => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = '/v1/auth/default-roles'
  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Interface que define as propriedades de um usuário.
 */
export type UserProps = {
  userName: string
  password: string
  email: string
  type: string
  roles: string[]
  timezoneId: number
}

/**
 * => Função para criar um novo usuário.
 * @param user Dados do novo usuário.
 * @returns Resultado da criação ou erro.
 */
export const createUser = async (user: UserProps) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = '/v1/auth/register'
  try {
    return await api.post(url, user, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para atualizar um usuário pelo ID.
 * @param userId ID do usuário.
 * @param user Dados do usuário a serem atualizados.
 * @returns Resultado da atualização ou erro.
 */
export const updateUserById = async (userId: string, user: {
  email: string
  roles: string[] | []
}) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/auth/user/${userId}`
  try {
    return await api.put(url, user, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para atualizar a senha de um usuário pelo ID.
 * @param userId ID do usuário.
 * @param password Dados da nova senha.
 * @returns Resultado da atualização ou erro.
 */
export const updateUserNewPasswordById = async (userId: string, password: {
  oldPassword: string
  newPassword: string
}) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/auth/user/${userId}/password`
  try {
    return await api.put(url, password, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para atualizar o usuário logado.
 * @param user Dados do usuário a serem atualizados.
 * @returns Resultado da atualização ou erro.
 */
export const updateMyUser = async (user: {
  email?: string
  roles?: string[] | []
}) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = '/v1/auth/user'
  try {
    return await api.put(url, user, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para atualizar a senha do usuário logado.
 * @param password Dados da nova senha.
 * @returns Resultado da atualização ou erro.
 */
export const updateMyNewPassword = async (password: {
  oldPassword: string
  newPassword: string
}) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/auth/user/password`
  try {
    return await api.put(url, password, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}