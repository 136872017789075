import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { deleteOtherBankByOwnerId, getSettlementAccountsByOwnerId } from "../../../../../api/endpoints/owner"
import { getPath } from "../../../../../utils/helpers/path"
import { Card, CardContent } from "../../../../../components/ui/card"
import { formatDocumentCnpjNumber, formatDocumentCpfNumber } from "../../../../../utils/helpers/formated"
import { Button } from "../../../../../components/ui/button"
import { Frown, Trash2 } from "lucide-react"
import { PlatformTemplate } from "../../../../../components/layout/template"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "../../../../../components/ui/alert-dialog"
import Swal from "sweetalert2"
import { TabsOwner } from "../_components/tabs-owner"

export default function OwnerBanks() {
  const [banks, setBanks] = useState<[]>()

  const fetchOwnerSettlementAccounts = async () => {
    const response = await getSettlementAccountsByOwnerId(getPath())
    if (response?.data) {
      return setBanks(response?.data)
    }

    return setBanks([])
  }

  useEffect(() => {
    fetchOwnerSettlementAccounts()
  }, [])

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsOwner value="other-banks" />
          {banks ? <OwnerBanksContent fetchOwnerSettlementAccounts={fetchOwnerSettlementAccounts} banks={banks} setBanks={setBanks} /> : <Skeleton className="w-full h-full" />}
        </div>
      </div>
    </PlatformTemplate>
  )
}

const OwnerBanksContent = ({ banks, setBanks, fetchOwnerSettlementAccounts }: { banks: any[], setBanks: any, fetchOwnerSettlementAccounts: any }) => {
  const navigate = useNavigate()

  if (banks.length === 0) {
    return (
      <div className="w-full h-[350px] bg-slate-50 rounded-xl flex flex-col items-center justify-center gap-10 font-semibold">
        <div className="flex items-center justify-center gap-2 text-zinc-500 text-lg">
          Nenhum Banco Encontrado! <Frown size={25} />
        </div>
        <Button onClick={() => navigate(`/owner/other-banks/create-bank/${getPath()}`)}>Cadastrar Novo Banco</Button>
      </div>
    )
  }

  const handleDeleteBank = async (key: string) => {
    try {
      await deleteOtherBankByOwnerId({ ownerId: getPath(), key: key })
      return await fetchOwnerSettlementAccounts()
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Ops... não foi possível excluir, tente novamente mais tarde!",
        confirmButtonColor: "var(--gray-500)",
      })
    }
  }

  return (
    <div className="flex flex-col gap-3 py-3">
      <div className="flex items-center justify-end">
        <Button onClick={() => navigate(`/owner/other-banks/create-bank/${getPath()}`)}>Cadastrar novo banco</Button>
      </div>
      {banks.map((bank: any, index: number) => (
        <Card key={index}>
          <CardContent className="flex flex-col gap-5 py-5">
            <div className="flex items-center justify-between">
              <h1 className="font-semibold">{bank.name}</h1>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button variant="ghost"><Trash2 size={20} /></Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Tem certeza de que deseja excluir?</AlertDialogTitle>
                    <AlertDialogDescription>
                      Essa ação é irreversível, você terá de cadastrar o Banco novamente para recuperar.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancelar</AlertDialogCancel>
                    <AlertDialogAction className="bg-transparent p-0">
                      <Button variant="destructive" onClick={() => handleDeleteBank(bank.settlementAccountId)}>Excluir</Button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center justify-between">
                <p className="font-medium">Tipo de Documento:</p>
                <p>{bank.documentType}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-medium">Número do Documento:</p>
                <p>{bank.documentType === "CPF" ? formatDocumentCpfNumber(bank.documentNumber) : formatDocumentCnpjNumber(bank.documentNumber)}</p>
              </div>
            </div>
            <div className="flex flex-col gap-2 rounded-lg bg-slate-50 p-5 ">
              <div className="flex items-center justify-between">
                <p className="font-medium">Banco:</p>
                <p>{bank.bankName}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-medium">Código do Banco:</p>
                <p>{bank.bankCode}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-medium">Agência:</p>
                <p>{bank.branchNumber}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-medium">Número da Conta:</p>
                <p>{bank.accountNumber}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-medium">ISPB:</p>
                <p>{bank.bankIspb}</p>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  )
}
