// => Interface para representar os dados do usuário autenticado
export interface AuthUserData {
  access_token: string
  expires_in: number
  user_type: string
  user: {
    id: string
    userName: string
    email: string
    type: string
    password: string
    roles: string[]
    timezoneOffset: string
  }
}

// => Função para salvar os dados do usuário autenticado no localStorage
export function saveUserDataToLocalStorage(userData: AuthUserData): void {
  localStorage.setItem('userData', JSON.stringify(userData))
}

// => Função para obter os dados do usuário autenticado do localStorage
export function getUserDataFromLocalStorage(): AuthUserData | null {
  const userDataJSON = localStorage.getItem('userData')
  if (userDataJSON) {
    return JSON.parse(userDataJSON)
  } else {
    return null
  }
}

// => Função para remover os dados do usuário autenticado do localStorage
export function removeUserDataFromLocalStorage(): void {
  localStorage.removeItem('userData')
}

// => Função para verificar se o usuário está autenticado com base nos dados do localStorage
export function isUserAuthenticated(): boolean {
  return !!localStorage.getItem('userData')
}

// => Função para fazer logout
export function logout(): void {
  removeUserDataFromLocalStorage()
}

// => Função para verificar se existe um usuário logado no localStorage
export function isLoggedIn(): boolean {
  return isUserAuthenticated()
}

// => Função para obter o timezoneOffset do usuário autenticado
export function getUserTimezoneOffset(): string {
  const userData = getUserDataFromLocalStorage()
  if (userData) {
    return userData.user.timezoneOffset
  }

  return ''
}