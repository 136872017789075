import React, { useState } from "react"
import { countFilledData } from "../../../../utils/helpers/calculate"
import { getOwners } from "../../../../api/endpoints/owner"
import { trimSpaces } from "../../../../utils/helpers/trimSpaces"
import { Input } from "../../../../components/ui/input"
import { Label } from "../../../../components/ui/label"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from "../../../../components/ui/sheet"
import { handleEnterKeyDown } from "../../../../utils/helpers/keyboard"
import { Button } from "../../../../components/ui/button"
import { Badge } from "../../../../components/ui/badge"
import { Filter, Search } from "lucide-react"
import { OwnerFilterProps } from "./table-owner"
import { formatDocumentCpfNumber, formatDocumentCnpjNumber } from "../../../../utils/helpers/formated"

type FilterKey = keyof OwnerFilterProps

interface TableFiltersOwnerProps {
  filters: OwnerFilterProps
  setFilters: React.Dispatch<React.SetStateAction<OwnerFilterProps>>
  setOwners: React.Dispatch<React.SetStateAction<any[]>>
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  appliedFilters: OwnerFilterProps
  setAppliedFilters: React.Dispatch<React.SetStateAction<OwnerFilterProps>>
}

export const TableFiltersOwner: React.FC<TableFiltersOwnerProps> = ({ filters, setFilters, setOwners, setPageIndex, setTotalPages, pageSize, appliedFilters, setAppliedFilters }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitFilters = async () => {
    if (!filters.id.trim() && !filters.name.trim() && !filters.email.trim() && !filters.documentNumber.trim()) return
    setIsSubmitting(true)

    const response: any = await getOwners({ ...filters, pageIndex: 1, pageSize })

    if (response?.data?.items) {
      setOwners(response?.data?.items)
      setAppliedFilters(filters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setOwners([])
      setAppliedFilters(filters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const removeAllFilters = async () => {
    const resetFilters: OwnerFilterProps = { id: '', name: '', email: '', documentNumber: '' }
    setFilters(resetFilters)
    const response: any = await getOwners({ pageIndex: 1, pageSize })
    if (response?.data?.items) {
      setOwners(response?.data?.items)
      setAppliedFilters(resetFilters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setOwners([])
      setAppliedFilters(resetFilters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const handleRemoveFilter = (key: FilterKey) => {
    const newFilters = { ...filters, [key]: '' }
    setFilters(newFilters)
    if (countFilledData(newFilters) === 0) {
      removeAllFilters()
    } else {
      submitFilters()
    }
  }

  const handleInputChange = (key: FilterKey, value: string) => {
    let formattedValue = value
    if (key === 'documentNumber') {
      const cleanedValue = value.replace(/\D/g, '')
      formattedValue = cleanedValue.length <= 11 ? formatDocumentCpfNumber(value) : formatDocumentCnpjNumber(value)
    }
    setFilters({ ...filters, [key]: key === "name" ? formattedValue : trimSpaces(formattedValue) })
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="flex w-2/3 gap-2">
        <Input
          type="text"
          onKeyDown={handleEnterKeyDown(submitFilters)}
          placeholder="Procurar pelo número de Documento"
          disabled={isSubmitting}
          value={filters.documentNumber}
          onChange={(e) => handleInputChange('documentNumber', e.target.value)}
          className="max-w-sm"
        />
        <Button disabled={isSubmitting || !filters.documentNumber.trim()} onClick={submitFilters} variant="default">
          <Search size={20} />
        </Button>
        <Sheet>
          <SheetTrigger>
            <Button disabled={isSubmitting} className="flex gap-2">
              <Filter size={15} />
              Filtros
            </Button>
          </SheetTrigger>
          <SheetContent className="flex flex-col gap-8">
            <SheetHeader>
              <SheetTitle className="flex items-center gap-2">
                <Filter size={20} />
                Filtros
              </SheetTitle>
              <SheetDescription>
                Utilize os filtros personalizados para encontrar dados que está buscando na tabela
              </SheetDescription>
            </SheetHeader>
            <div className="flex flex-col overflow-y-auto px-1 gap-5">
              {(['id', 'name', 'email', 'documentNumber'] as FilterKey[]).map((key) => (
                <div key={key}>
                  <Label>{filterLabels[key]}</Label>
                  <Input
                    type="text"
                    value={filters[key]}
                    onChange={(e) => handleInputChange(key, e.target.value)}
                  />
                </div>
              ))}
            </div>
            <SheetFooter>
              <SheetClose asChild>
                <Button variant="secondary" disabled={isSubmitting} onClick={removeAllFilters}>
                  Remover todos os Filtros
                </Button>
              </SheetClose>
              <SheetClose asChild>
                <Button disabled={isSubmitting} onClick={submitFilters}>
                  Aplicar
                </Button>
              </SheetClose>
            </SheetFooter>
          </SheetContent>
        </Sheet>
        {countFilledData(appliedFilters) !== 0 && (
          <div className="w-8 h-8 flex items-center justify-center rounded-[50%] bg-teal-700 text-white select-none">
            {countFilledData(appliedFilters)}
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        {(['id', 'name', 'email', 'documentNumber'] as FilterKey[]).map((key) => (
          appliedFilters[key] && (
            <TagsFilter
              key={key}
              filterKey={key}
              filterValue={appliedFilters[key]}
              removeFilter={handleRemoveFilter}
            />
          )
        ))}
      </div>
    </div>
  )
}

const filterLabels: { [key in FilterKey]: string } = {
  id: "ID",
  name: "Nome",
  email: "Email",
  documentNumber: "Número do Documento",
}

interface TagsFilterProps {
  filterKey: FilterKey
  filterValue: string
  removeFilter: (key: FilterKey) => void
}

const TagsFilter: React.FC<TagsFilterProps> = ({ filterKey, filterValue, removeFilter }) => (
  <Badge variant="secondary" onClick={() => removeFilter(filterKey)} className="cursor-pointer">
    {filterLabels[filterKey]}: {filterValue} ✕
  </Badge>
)
