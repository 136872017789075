import React, { useState } from "react"
import { getUsers } from "../../../../api/endpoints/auth"
import { Input } from "../../../../components/ui/input"
import { Label } from "../../../../components/ui/label"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../../../components/ui/sheet"
import { Button } from "../../../../components/ui/button"
import { Badge } from "../../../../components/ui/badge"
import { Filter, Search } from "lucide-react"
import { Filters } from "../page"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../components/ui/select"
import { countFilledData } from "../../../../utils/helpers/calculate"
import { handleEnterKeyDown } from "../../../../utils/helpers/keyboard"

type FilterKey = keyof Filters

interface TableFiltersUserProps {
  filters: Filters
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
  setUsers: React.Dispatch<React.SetStateAction<any[]>>
  pageIndex: number
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  appliedFilters: Filters
  setAppliedFilters: React.Dispatch<React.SetStateAction<Filters>>
}

export const TableFiltersUser: React.FC<TableFiltersUserProps> = ({
  filters, setFilters, setUsers, pageIndex, setPageIndex, setTotalPages, pageSize, appliedFilters, setAppliedFilters
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitFilters = async () => {
    setIsSubmitting(true)
    const response: any = await getUsers({ ...filters, pageIndex: 1, pageSize })
    if (response?.data?.items) {
      setUsers(response?.data?.items)
      setAppliedFilters(filters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setUsers([])
      setAppliedFilters(filters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const removeAllFilters = async () => {
    const resetFilters: Filters = {
      userName: '',
      email: '',
      type: '',
      roles: '',
    }
    setFilters(resetFilters)

    const response = await getUsers({ pageIndex: 1, pageSize })

    if (response?.data?.items) {
      setUsers(response?.data?.items)
      setAppliedFilters(resetFilters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setUsers([])
      setAppliedFilters(resetFilters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const handleInputChange = (key: FilterKey, value: string) => {
    setFilters({ ...filters, [key]: value })
  }

  const handleRemoveFilter = (key: FilterKey) => {
    const newFilters = { ...filters, [key]: '' }
    setFilters(newFilters)
    if (countFilledData(newFilters) === 0) {
      removeAllFilters()
    } else {
      submitFilters()
    }
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="flex w-2/3 gap-2">
        <Input
          type="text"
          onKeyDown={handleEnterKeyDown(submitFilters)}
          placeholder="Procurar pelo Nome de Usuário..."
          disabled={isSubmitting}
          value={filters.userName}
          onChange={(e) => handleInputChange('userName', e.target.value)}
          className="max-w-sm"
        />
        <Button disabled={isSubmitting || !filters.userName.trim()} onClick={submitFilters} variant="default">
          <Search size={20} />
        </Button>
        <Sheet>
          <SheetTrigger>
            <Button disabled={isSubmitting} className="flex gap-2">
              <Filter size={15} />
              Filtros
            </Button>
          </SheetTrigger>
          <SheetContent className="flex flex-col gap-8">
            <SheetHeader>
              <SheetTitle className="flex items-center gap-2">
                <Filter size={20} />
                Filtros
              </SheetTitle>
              <SheetDescription>
                Utilize os filtros personalizados para encontrar dados que está buscando na tabela
              </SheetDescription>
            </SheetHeader>
            <div className="flex flex-col overflow-y-auto px-1 gap-5">
              {(['userName', 'email'] as FilterKey[]).map((key) => (
                <div key={key}>
                  <Label>{filterLabels[key]}</Label>
                  <Input
                    type="text"
                    value={filters[key]}
                    onChange={(e) => handleInputChange(key, e.target.value)}
                  />
                </div>
              ))}
              <div>
                <Label>Tipo</Label>
                <Select onValueChange={(value) => handleInputChange('type', value as Filters['type'])}>
                  <SelectTrigger>
                    <SelectValue placeholder="Selecione o Tipo" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Super">Super</SelectItem>
                    <SelectItem value="Customer">Cliente</SelectItem>
                    <SelectItem value="Application">Aplicação</SelectItem>
                    <SelectItem value="Operator">Operador</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <SheetFooter>
              <SheetClose asChild>
                <Button variant="secondary" disabled={isSubmitting} onClick={removeAllFilters}>
                  Remover todos os Filtros
                </Button>
              </SheetClose>
              <SheetClose asChild>
                <Button disabled={isSubmitting} onClick={submitFilters}>
                  Aplicar
                </Button>
              </SheetClose>
            </SheetFooter>
          </SheetContent>
        </Sheet>
        {countFilledData(appliedFilters) !== 0 && (
          <div className="w-8 h-8 flex items-center justify-center rounded-[50%] bg-teal-700 text-white select-none">
            {countFilledData(appliedFilters)}
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        {(['userName', 'email', 'roles', 'type'] as FilterKey[]).map((key) => (
          appliedFilters[key] && (
            <Badge variant="secondary" onClick={() => handleRemoveFilter(key)} className="cursor-pointer">
              {filterLabels[key]}: {appliedFilters[key]} ✕
            </Badge>
          )
        ))}
      </div>
    </div>
  )
}

const filterLabels: { [key in FilterKey]: string } = {
  userName: "Nome de Usuário",
  email: "Email",
  type: "Tipo",
  roles: ""
}
