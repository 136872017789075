import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getOwners } from "../../../api/endpoints/owner"
import { Skeleton } from "../../../components/ui/skeleton"
import { PlatformTemplate } from "../../../components/layout/template"
import { CircleX, Plus, UserRoundX, Users } from "lucide-react"
import { Button } from "../../../components/ui/button"
import TableOwners from "./_components/table-owner"

export type OwnerProps = {
    id: string
    name: string
    email: string
    documentType: string
    documentNumber: string
}

export type Filters = {
    id: string
    name: string
    email: string
    documentNumber: string
}

export default function Owners() {
    const navigate = useNavigate()
    const [owners, setOwners] = useState<OwnerProps[]>()
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [filters, setFilters] = useState<Filters>({
        id: '',
        name: '',
        email: '',
        documentNumber: ''
    })
    const [appliedFilters, setAppliedFilters] = useState<Filters>(filters)

    const fetchOwners = async (pageIndex: number, pageSize: number, filters: Filters) => {
        const response: any = await getOwners({ pageIndex, pageSize, ...filters })
        setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
        if (response?.data?.items) {
            setOwners(response?.data?.items)
        } else {
            setOwners([])
        }
    }

    useEffect(() => {
        fetchOwners(pageIndex, pageSize, appliedFilters)
    }, [pageIndex, pageSize, appliedFilters])

    return (
        <PlatformTemplate>
            <div className="w-full h-full flex flex-col">
                <div className="w-full flex items-center justify-between">
                    <div className="w-full flex items-center py-3 gap-2">
                        <Users size={20} />
                        <h1 className="text-[20px] font-semibold">Clientes</h1>
                    </div>
                    <div className="flex items-center gap-3">
                        <Button variant="default" className="gap-1" onClick={() => navigate("/owner/create")}>
                            <Plus size={20} />
                        </Button>
                        <Button variant="outline" className="gap-1" onClick={() => navigate("/owner/blocked")}>
                            <UserRoundX size={20} />
                        </Button>
                    </div>
                </div>
                {owners ? (
                    <TableOwners
                        owners={owners}
                        setOwners={setOwners}
                        pageIndex={pageIndex}
                        setPageIndex={setPageIndex}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalPages={totalPages}
                        setTotalPages={setTotalPages}
                        filters={filters}
                        setFilters={setFilters}
                        appliedFilters={appliedFilters}
                        setAppliedFilters={setAppliedFilters}
                    />
                ) : (
                    <Skeleton className="w-full h-full" />
                )}
            </div>
        </PlatformTemplate>
    )
}
