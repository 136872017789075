// => timezone.ts
import api from ".."
import { HeadersAccessToken } from "../config"

// Interface do timezone
export interface TimezoneProps {
  id: number,
  identifier: string,
  baseUtcOffset: string,
  daylightName: string,
  standardName: string,
  supportsDaylightSavingTime: boolean,
  displayName: string
}

/**
 * => Função para obter todos os timezones disponíveis.
 * @param Nenhum.
 * @returns Timezones disponíveis ou erro.
 */
export const getTimezone = async () => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = '/v1/timezone'

  try {
    return (await api.get(url, { headers: { Authorization: access_token } })).data
  } catch (error: any) {
    return error
  }
}