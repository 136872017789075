import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/ui/button"
import NotFoundImg from "../../../assets/404-img.svg"

export default function NotFound404() {
  const navigate = useNavigate()

  return (
    <div
      className="w-full h-screen bg-black bg-center flex items-center justify-center"
    >
      <div className="w-full h-full flex items-center justify-center bg-opacity-50 bg-black">
        <div className="w-[750px] flex gap-5 bg-opacity-20 bg-black p-8 rounded-lg">
          <div className="w-1/2 flex flex-col gap-2">
            <h1 className="text-9xl text-white">404</h1>
            <h1 className="text-3xl text-zinc-100">Página não encontrada!</h1>
            <p className="text-sm text-zinc-300 mb-6">
              Desculpe, a página que você está procurando não existe.
              Pode ter sido removida, o nome foi alterado ou está temporariamente indisponível.
            </p>
            <div className="flex items-center">
              <Button onClick={() => navigate("/owner")} variant="destructive">
                Voltar para Página inicial
              </Button>
            </div>
          </div>
          <img src={NotFoundImg} className="w-1/2 h-auto bg-neutral-300 rounded-s-full" />
        </div>
      </div>
    </div>
  );
}
