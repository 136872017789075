import { useEffect, useState } from "react"
import { getInfractions, InfractionStatus, InfractionReason, InfractionSituation, AnalysisResult } from "../../../api/endpoints/refund"
import { Skeleton } from "../../../components/ui/skeleton"
import { PlatformTemplate } from "../../../components/layout/template"
import TableInfractions from "./_components/table-infractions"
import { AlertTriangle } from "lucide-react"

export type InfractionProps = {
  infractionId: string,
  endToEndIdentification: string,
  reporterIspb: string,
  counterPartIspb: string,
  infractionIdentification: string,
  status: string,
  reason: string,
  situation: string,
  analysisResult: string,
  createDateStart: string,
  createDateEnd: string
}

export type Filters = {
  infractionId: string
  reporterIspb: string
  counterPartIspb: string
  status: InfractionStatus | ''
  reason: InfractionReason | ''
  situation: InfractionSituation | ''
  analysisResult: AnalysisResult | ''
  createDateStart: string
  createDateEnd: string
}

export default function Infractions() {
  const [infractions, setInfractions] = useState<InfractionProps[]>()
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [filters, setFilters] = useState<Filters>({
    infractionId: '',
    reporterIspb: '',
    counterPartIspb: '',
    status: '',
    reason: '',
    situation: '',
    analysisResult: '',
    createDateStart: '',
    createDateEnd: ''
  })
  const [appliedFilters, setAppliedFilters] = useState<Filters>(filters)

  const fetchInfractions = async (pageIndex: number, pageSize: number, filters: Filters) => {
    const response: any = await getInfractions({ pageIndex, pageSize, ...filters })
    setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
    if (response?.data?.items) {
      setInfractions(response?.data?.items)
    } else {
      setInfractions([])
    }
  }

  useEffect(() => {
    fetchInfractions(pageIndex, pageSize, appliedFilters)
  }, [pageIndex, pageSize, appliedFilters])

  return (
    <PlatformTemplate>
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex items-center gap-2 py-3">
          <AlertTriangle size={20} />
          <h1 className="text-[20px] font-semibold">Infrações</h1>
        </div>
        {infractions ? (
          <TableInfractions
            infractions={infractions}
            setInfractions={setInfractions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            filters={filters}
            setFilters={setFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
        ) : (
          <Skeleton className="w-full h-full" />
        )}
      </div>
    </PlatformTemplate>
  )
}
