import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../../components/ui/table";
import {
  ColumnDef,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronDown, Fingerprint, Library, MoreHorizontal, RefreshCw } from "lucide-react";
import { Button } from "../../../../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../../../components/ui/dropdown-menu";
import { RiskRuleProps, Filters } from "../page";
import { getAccountRiskRules } from "../../../../../../api/endpoints/account";
import { Skeleton } from "../../../../../../components/ui/skeleton";
import { getUserTimezoneOffset } from "../../../../../../utils/storage/auth";
import { convertUTCToUserTimezone } from "../../../../../../utils/helpers/date";
import { TableFiltersRiskRule } from "./table-filter-risk-rules";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { getPath } from "../../../../../../utils/helpers/path";

const SCOPE_OPTIONS = [
  { value: "ACCOUNT", label: "Conta" },
  { value: "TRANSACTION", label: "Transação" },
  { value: "INFRACTION", label: "Infração" }
];

const TYPE_OPTIONS = [
  { value: "AVERAGE_TICKET", label: "Ticket Médio" },
  { value: "MAXIMUM_VALUE_PER_TRANSFER", label: "Valor Máximo por Transferência" },
];

const ACTION_OPTIONS = [
  { value: "BLOCK", label: "Bloquear" },
  { value: "ALERT", label: "Alertar" }
];

const ActionCell = ({ riskRule }: { riskRule: RiskRuleProps }) => {
  const navigate = useNavigate();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Abrir Menu</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Ações</DropdownMenuLabel>
        <DropdownMenuItem
          onClick={() => navigator.clipboard.writeText(riskRule.riskRuleId)}
        >
          <Fingerprint size={15} className="mr-2" />Copiar ID da Regra
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate(`/account/risk/details/${riskRule.riskRuleId}/${getPath()}`)}>
          <Library size={15} className="mr-2" />Ver Detalhamentos
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const getLabel = (value: string, options: { value: string, label: string }[]): string => {
  const option = options.find(option => option.value === value);
  return option ? option.label : value;
};

export const columns: ColumnDef<RiskRuleProps>[] = [
  {
    accessorKey: "createDate",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Data de Criação
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="capitalize">{convertUTCToUserTimezone(row.getValue("createDate"), getUserTimezoneOffset())}</div>
    ),
  },
  {
    accessorKey: "updateDate",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Data de Atualização
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="capitalize">{convertUTCToUserTimezone(row.getValue("updateDate"), getUserTimezoneOffset())}</div>
    ),
  },
  {
    accessorKey: "name",
    header: "Nome",
    cell: ({ row }) => (
      <div className="min-w-48 capitalize">{row.getValue("name")}</div>
    ),
  },
  {
    accessorKey: "description",
    header: "Descrição",
    cell: ({ row }) => (
      <div className="min-w-48 capitalize">{row.getValue("description")}</div>
    ),
  },
  {
    accessorKey: "scope",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Escopo
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="capitalize">{getLabel(row.getValue("scope"), SCOPE_OPTIONS)}</div>
    ),
  },
  {
    accessorKey: "type",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Tipo
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="capitalize">{getLabel(row.getValue("type"), TYPE_OPTIONS)}</div>
    ),
  },
  {
    accessorKey: "isActive",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className={`capitalize ${row.getValue("isActive") ? "text-green-500 font-medium" : "text-red-500 font-medium"}`}>
        {row.getValue("isActive") ? "Ativo" : "Inativo"}
      </div>
    ),
  },
  {
    accessorKey: "action",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Ação
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div>{getLabel(row.getValue("action"), ACTION_OPTIONS)}</div>
    ),
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <ActionCell riskRule={row.original} />,
  },
];

export interface RiskRuleFilterProps {
  id: string;
  scope: string;
  type: string;
  name: string;
  expression: string;
  action: string;
  [key: string]: string;
}

export default function TableRiskRules({
  riskRules,
  setRiskRules,
  pageIndex,
  setPageIndex,
  pageSize,
  setPageSize,
  totalPages,
  setTotalPages,
  filters,
  setFilters,
  appliedFilters,
  setAppliedFilters,
  accountId
}: {
  riskRules: RiskRuleProps[];
  setRiskRules: any;
  pageIndex: number;
  setPageIndex: any;
  pageSize: number;
  setPageSize: any;
  totalPages: number;
  setTotalPages: any;
  filters: Filters;
  setFilters: any;
  appliedFilters: Filters;
  setAppliedFilters: any;
  accountId: string;
}) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const [onRefresh, setOnRefresh] = useState<boolean>(false);
  const table = useReactTable({
    data: riskRules,
    columns,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility,
      rowSelection,
    },
  });

  const handleRefreshTable = async (pageIndex: number, pageSize: number, filters: Filters) => {
    setOnRefresh(true);
    const response = await getAccountRiskRules(accountId, { pageIndex, pageSize, ...filters });
    setTotalPages(Math.ceil(response?.data?.totalCount / pageSize));
    if (response?.data?.items) {
      setRiskRules(response?.data?.items);
      setOnRefresh(false);
    } else {
      setRiskRules([]);
      setOnRefresh(false);
    }
  };

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <div className="w-full h-auto flex items-center gap-2">
          <TableFiltersRiskRule
            accountId={accountId}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setTotalPages={setTotalPages}
            filters={filters}
            setFilters={setFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            setRiskRules={setRiskRules}
          />
        </div>
        <DropdownMenu>
          <div className="flex items-center justify-center gap-2">
            <Button onClick={() => handleRefreshTable(pageIndex, pageSize, filters)} variant="secondary">
              <RefreshCw size={20} className={`${onRefresh && 'animate-spin'}`} />
            </Button>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                Colunas <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
          </div>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        {!onRefresh ?
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    Sem resultados
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table> :
          <Skeleton className="w-full h-[525px]" />}
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="font-semibold text-sm text-zinc-600">
          {pageIndex} de {totalPages}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPageIndex((old: number) => Math.max(old - 1, 1))}
            disabled={pageIndex === 1}
          >
            Anterior
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPageIndex((old: number) => old + 1)}
            disabled={pageIndex === totalPages}
          >
            Próximo
          </Button>
        </div>
      </div>
    </div>
  );
}
