import React, { ChangeEvent, useState } from "react"
import { countFilledData } from "../../../../utils/helpers/calculate"
import { getRiskRules } from "../../../../api/endpoints/risk-rules"
import { trimSpaces } from "../../../../utils/helpers/trimSpaces"
import { Input } from "../../../../components/ui/input"
import { Label } from "../../../../components/ui/label"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from "../../../../components/ui/sheet"
import { handleEnterKeyDown } from "../../../../utils/helpers/keyboard"
import { Button } from "../../../../components/ui/button"
import { Badge } from "../../../../components/ui/badge"
import { Filter, Search } from "lucide-react"
import { RiskRuleFilterProps } from "./table-risk-rules"
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem
} from "../../../../components/ui/select"

type FilterKey = keyof RiskRuleFilterProps

interface TableFiltersRiskRuleProps {
  filters: RiskRuleFilterProps
  setFilters: React.Dispatch<React.SetStateAction<RiskRuleFilterProps>>
  setRiskRules: React.Dispatch<React.SetStateAction<any[]>>
  pageIndex: number
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  appliedFilters: RiskRuleFilterProps
  setAppliedFilters: React.Dispatch<React.SetStateAction<RiskRuleFilterProps>>
}

export const TableFiltersRiskRule: React.FC<TableFiltersRiskRuleProps> = ({
  filters, setFilters, setRiskRules, pageIndex, setPageIndex, setTotalPages, pageSize, appliedFilters, setAppliedFilters
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitFilters = async () => {
    if (!filters.id.trim() && !filters.scope.trim() && !filters.type.trim() && !filters.name.trim() && !filters.expression.trim() && !filters.action.trim() && !filters.createDateStart.trim() && !filters.createDateEnd.trim() && !filters.updateDateStart.trim() && !filters.updateDateEnd.trim()) return
    setIsSubmitting(true)

    const response = await getRiskRules({ ...filters, pageIndex: 1, pageSize })

    if (response?.data?.items) {
      setRiskRules(response?.data?.items)
      setAppliedFilters(filters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setRiskRules([])
      setAppliedFilters(filters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const removeAllFilters = async () => {
    const resetFilters: RiskRuleFilterProps = {
      id: '',
      scope: '',
      type: '',
      name: '',
      expression: '',
      action: '',
      createDateStart: '',
      createDateEnd: '',
      updateDateStart: '',
      updateDateEnd: ''
    }
    setFilters(resetFilters)

    const response: any = await getRiskRules({ pageIndex: 1, pageSize })

    if (response?.data?.items) {
      setRiskRules(response?.data?.items)
      setAppliedFilters(resetFilters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setRiskRules([])
      setAppliedFilters(resetFilters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const handleRemoveFilter = (key: FilterKey) => {
    const newFilters = { ...filters, [key]: '' }
    setFilters(newFilters)
    if (countFilledData(newFilters) === 0) {
      removeAllFilters()
    } else {
      submitFilters()
    }
  }

  const handleInputChange = (key: FilterKey, value: string) => {
    setFilters({ ...filters, [key]: trimSpaces(value) })
  }

  const handleSelectChange = (key: FilterKey, value: string) => {
    setFilters({ ...filters, [key]: value })
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="flex w-2/3 gap-2">
        <Input
          type="text"
          onKeyDown={handleEnterKeyDown(submitFilters)}
          placeholder="Procurar pelo Nome da Regra..."
          disabled={isSubmitting}
          value={filters.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('name', e.target.value)}
          className="max-w-sm"
        />
        <Button disabled={isSubmitting || !filters.name.trim()} onClick={submitFilters} variant="default">
          <Search size={20} />
        </Button>
        <Sheet>
          <SheetTrigger>
            <Button disabled={isSubmitting} className="flex gap-2">
              <Filter size={15} />
              Filtros
            </Button>
          </SheetTrigger>
          <SheetContent className="flex flex-col gap-8">
            <SheetHeader>
              <SheetTitle className="flex items-center gap-2">
                <Filter size={20} />
                Filtros
              </SheetTitle>
              <SheetDescription>
                Utilize os filtros personalizados para encontrar dados que está buscando na tabela
              </SheetDescription>
            </SheetHeader>
            <div className="flex flex-col overflow-y-auto px-1 gap-5">
              <div>
                <Label>Escopo</Label>
                <Select onValueChange={(value) => handleSelectChange('scope', value)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Selecione o Escopo" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Escopo</SelectLabel>
                      <SelectItem value="ACCOUNT">CONTA</SelectItem>
                      <SelectItem value="TRANSACTION">TRANSAÇÃO</SelectItem>
                      <SelectItem value="INFRACTION">INFRAÇÃO</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label>Tipo</Label>
                <Select onValueChange={(value) => handleSelectChange('type', value)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Selecione o Tipo" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Tipo</SelectLabel>
                      <SelectItem value="AVERAGE_TICKET">TICKET MÉDIO</SelectItem>
                      <SelectItem value="MAXIMUM_VALUE_PER_TRANSFER">VALOR MÁXIMO POR TRANSFERÊNCIA</SelectItem>
                      <SelectItem value="MAXIMUM_TRANSFER_AMOUNT">VALOR MÁXIMO DE TRANSFERÊNCIA</SelectItem>
                      <SelectItem value="MAXIMUM_TRANSACTION_AMOUNT">VALOR MÁXIMO DE TRANSAÇÃO</SelectItem>
                      <SelectItem value="TRANSFER_RECEIVED">TRANSFERÊNCIA RECEBIDA</SelectItem>
                      <SelectItem value="MED_LIMIT_FOR_ACCOUNT">LIMITE MÉDIO PARA CONTA</SelectItem>
                      <SelectItem value="MED_LIMIT_PER_PAYER">LIMITE MÉDIO POR PAGADOR</SelectItem>
                      <SelectItem value="AUTOMATIC_SETTLEMENT_AMOUNT_LIMIT_DAILY">LIMITE DIÁRIO DE LIQUIDAÇÃO AUTOMÁTICA</SelectItem>
                      <SelectItem value="AUTOMATIC_SETTLEMENT_AMOUNT_LIMIT_WEEKLY">LIMITE SEMANAL DE LIQUIDAÇÃO AUTOMÁTICA</SelectItem>
                      <SelectItem value="AUTOMATIC_SETTLEMENT_AMOUNT_LIMIT_MONTHLY">LIMITE MENSAL DE LIQUIDAÇÃO AUTOMÁTICA</SelectItem>
                      <SelectItem value="WITHDRAWAL_AMOUNT_LIMIT_DAILY">LIMITE DIÁRIO DE SAQUE</SelectItem>
                      <SelectItem value="WITHDRAWAL_AMOUNT_LIMIT_WEEKLY">LIMITE SEMANAL DE SAQUE</SelectItem>
                      <SelectItem value="WITHDRAWAL_AMOUNT_LIMIT_MONTHLY">LIMITE MENSAL DE SAQUE</SelectItem>
                      <SelectItem value="WITHDRAWAL_QUANTITY_LIMIT">LIMITE DE QUANTIDADE DE SAQUE</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label>Ação</Label>
                <Select onValueChange={(value) => handleSelectChange('action', value)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Selecione a Ação" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Ação</SelectLabel>
                      <SelectItem value="BLOCK">BLOQUEIO</SelectItem>
                      <SelectItem value="ALERT">ALERTA</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <SheetFooter>
              <SheetClose asChild>
                <Button variant="secondary" disabled={isSubmitting} onClick={removeAllFilters}>
                  Remover todos os Filtros
                </Button>
              </SheetClose>
              <SheetClose asChild>
                <Button disabled={isSubmitting} onClick={submitFilters}>
                  Aplicar
                </Button>
              </SheetClose>
            </SheetFooter>
          </SheetContent>
        </Sheet>
        {countFilledData(appliedFilters) !== 0 && (
          <div className="w-8 h-8 flex items-center justify-center rounded-[50%] bg-teal-700 text-white select-none">
            {countFilledData(appliedFilters)}
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        {(['id', 'scope', 'type', 'name', 'expression', 'action', 'createDateStart', 'createDateEnd', 'updateDateStart', 'updateDateEnd'] as FilterKey[]).map((key) => (
          appliedFilters[key] && (
            <TagsFilter
              key={key}
              filterKey={key}
              filterValue={appliedFilters[key]}
              removeFilter={handleRemoveFilter}
            />
          )
        ))}
      </div>
    </div>
  )
}

const filterLabels: { [key in FilterKey]: string } = {
  id: "ID da Regra",
  scope: "Escopo",
  type: "Tipo",
  name: "Nome",
  expression: "Expressão",
  action: "Ação",
  createDateStart: "Data de Criação Início",
  createDateEnd: "Data de Criação Fim",
  updateDateStart: "Data de Atualização Início",
  updateDateEnd: "Data de Atualização Fim",
}

interface TagsFilterProps {
  filterKey: FilterKey
  filterValue: string
  removeFilter: (key: FilterKey) => void
}

const TagsFilter: React.FC<TagsFilterProps> = ({ filterKey, filterValue, removeFilter }) => (
  <Badge variant="secondary" onClick={() => removeFilter(filterKey)} className="cursor-pointer">
    {filterLabels[filterKey]}: {filterValue} ✕
  </Badge>
)
