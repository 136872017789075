import { Dispatch, SetStateAction } from "react"
import { OwnerStepGeneralInformations } from "./owner-general-informations"
import { OwnerStepAddress } from "./owner-address"
import { OwnerStepConfirmation } from "./owner-confirmation"

export const Steps = ({
  owner,
  setOwner,
  stepPosition,
  setStepPosition,
}: {
  owner: any
  setOwner: Dispatch<SetStateAction<any>>
  stepPosition: number
  setStepPosition: Dispatch<SetStateAction<number>>
}) => {
  if (stepPosition === 0) {
    return (
      <OwnerStepGeneralInformations
        owner={owner}
        setOwner={setOwner}
        setStepPosition={setStepPosition}
      />
    )
  }

  if (stepPosition === 1) {
    return (
      <OwnerStepAddress
        owner={owner}
        setOwner={setOwner}
        setStepPosition={setStepPosition}
      />
    )
  }

  if (stepPosition === 2) {
    return (
      <OwnerStepConfirmation
        owner={owner}
        setStepPosition={setStepPosition}
      />
    )
  }

  return null
}
