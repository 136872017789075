import React, { ChangeEvent, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  SCOPE_OPTIONS,
  ACTION_OPTIONS,
  CONDITIONS_OPTIONS,
  TYPE_OPTIONS
} from "./options"
import {
  CreateRiskRulesProps,
  ActionProps,
  ExpressionsProps,
  ScopeProps,
  TypeProps
} from "./types"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from "../../../../../../components/ui/select"
import { PlatformTemplate } from "../../../../../../components/layout/template"
import { Input } from "../../../../../../components/ui/input"
import { Button } from "../../../../../../components/ui/button"
import { createAccountRiskRule } from "../../../../../../api/endpoints/account"
import { getRiskRuleTypes } from "../../../../../../api/endpoints/risk-rules"
import { CircleMinus, Loader2, Plus } from "lucide-react"
import { IsEmptyObject } from "../../../../../../utils/helpers/validate"
import { getPath } from "../../../../../../utils/helpers/path"
import Swal from "sweetalert2"
import { TabsAccount } from "../../_components/tabs-account"

export default function CreateAccountRiskRule() {
  const navigate = useNavigate()
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [types, setTypes] = useState<[]>([])
  const [isTypes, setIsTypes] = useState<boolean>(false)
  const [operator, setOperator] = useState<'OR' | 'AND'>('AND')
  const [expressions, setExpressions] = useState<string[]>([])
  const [expressionsLabels, setExpressionLabels] = useState<ExpressionsProps[]>([])
  const [typeExpression, setTypeExpression] = useState<string>('')
  const [valueExpression, setValueExpression] = useState<number | ''>('')
  const [newRiskRule, setNewRiskRule] = useState<CreateRiskRulesProps>({
    name: '',
    description: '',
    scope: "ACCOUNT",
    type: "AUTOMATIC_SETTLEMENT_AMOUNT_LIMIT_DAILY",
    expression: '',
    action: "BLOCK",
  })

  useEffect(() => {
    handleConditions()
  }, [expressions, operator])

  useEffect(() => {
    const fetchTypes = async () => {
      if (newRiskRule.scope) {
        const response = await getRiskRuleTypes(newRiskRule.scope)
        if (response.status === 200) {
          setTypes(response?.data?.types)
          setIsTypes(true)
        }
      }
    }
    fetchTypes()
  }, [newRiskRule.scope])

  const handleChangeExpressions = ({ type, value }: { type: string, value: number }) => {
    const newExpression = `key:${type}:${value}`
    setExpressionLabels(prevLabels => [...prevLabels, { type, value }])
    setExpressions(prevExpressions => [...prevExpressions, newExpression])
  }

  const handleRemoveExpression = (index: number) => {
    setExpressionLabels(prevLabels => {
      const newLabels = [...prevLabels]
      newLabels.splice(index, 1)
      setExpressions(newLabels.map(exp => `key:${exp.type}:${exp.value}`))
      return newLabels
    })
  }

  const handleSubmitNewRiskRule = async () => {
    setIsSubmit(true)

    if (IsEmptyObject(newRiskRule)) {
      Swal.fire({
        icon: "error",
        title: "Preencha todos os campos antes de enviar.",
        confirmButtonColor: "var(--gray-500)",
      })
      setIsSubmit(false)
      return
    }

    const response = await createAccountRiskRule({ accountId: getPath(), data: newRiskRule })
    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Regra de Risco criada com Sucesso!",
        confirmButtonColor: "var(--gray-500)",
      })
      return navigate(`/account/risk/details/${response.data.riskRuleId}/${getPath()}`)
    } else {
      setIsSubmit(false)
      return Swal.fire({
        icon: "error",
        title: "Não foi possível realizar a operação.",
        confirmButtonColor: "var(--gray-500)",
      })
    }
  }

  const handleConditions = () => {
    setNewRiskRule(prevRiskRule => ({
      ...prevRiskRule,
      expression: `${operator}(${expressions.join(';')})`,
    }))
  }

  const fetchTypesByScope = async (event: string) => {
    setIsTypes(false)
    setNewRiskRule({ ...newRiskRule, scope: event as keyof ScopeProps })
    const response = await getRiskRuleTypes(event)
    if (response.status === 200) {
      setTypes(response?.data?.types)
      setIsTypes(true)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'e' || event.key === 'E' || event.key === '+' || event.key === '-' || event.key === '.') {
      event.preventDefault()
    }
  }

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsAccount value="risk-rules" />
          <div className="w-full h-full flex flex-col gap-5">
            <div className="h-full w-full flex items-center justify-center">
              <div className="w-full flex flex-col gap-10 items-center justify-center">
                <div className="w-2/3 flex items-center justify-center">
                  <h1 className="text-2xl font-semibold mt-10">Criar nova Regra</h1>
                </div>
                <div className="w-1/2 flex flex-col gap-3 mb-20">
                  <Input
                    type="text"
                    placeholder="Nome"
                    value={newRiskRule.name}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setNewRiskRule({ ...newRiskRule, name: event.target.value })
                    }
                    className="text-base"
                  />
                  <Input
                    type="text"
                    placeholder="Descrição"
                    value={newRiskRule.description}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setNewRiskRule({ ...newRiskRule, description: event.target.value })
                    }
                    className="text-base"
                  />
                  <Select onValueChange={(event) => fetchTypesByScope(event)}>
                    <SelectTrigger>
                      <SelectValue placeholder="Escopo" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Escopo</SelectLabel>
                        {SCOPE_OPTIONS.map((scope) => (
                          <SelectItem key={scope.value} value={scope.value}>{scope.label}</SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <Select disabled={!isTypes} onValueChange={(value) =>
                    setNewRiskRule({ ...newRiskRule, type: value as keyof TypeProps })
                  }>
                    <SelectTrigger>
                      <SelectValue placeholder="Tipo" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Tipo</SelectLabel>
                        {types.map((type) => {
                          const typeOption = TYPE_OPTIONS.find((typeOption) => type === typeOption.value)
                          if (typeOption) {
                            return (<SelectItem key={typeOption.value} value={typeOption.value}>{typeOption.label}</SelectItem>)
                          }
                          return null
                        })}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <Select onValueChange={(value) =>
                    setNewRiskRule({ ...newRiskRule, action: value as keyof ActionProps })
                  }>
                    <SelectTrigger>
                      <SelectValue placeholder="Ação" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Ação</SelectLabel>
                        {ACTION_OPTIONS.map((action) => (
                          <SelectItem key={action.value} value={action.value}>{action.label}</SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <div className="border w-full flex flex-col gap-5 p-5 rounded-md">
                    <h1 className="font-semibold text-neutral-500">Configuração de Expressões</h1>
                    <div className="">
                      <Select onValueChange={(event: 'OR' | 'AND') => setOperator(event)}>
                        <SelectTrigger>
                          <SelectValue placeholder="Operador:" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Operador</SelectLabel>
                            <SelectItem value='AND'>E</SelectItem>
                            <SelectItem value='OR'>OU</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="flex items-center gap-1">
                      <Select onValueChange={(event) => setTypeExpression(event)}>
                        <SelectTrigger>
                          <SelectValue placeholder="Condições:" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Condições</SelectLabel>
                            {CONDITIONS_OPTIONS.map((condition) => (
                              <SelectItem key={condition.value} value={condition.value}>{condition.label}</SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <Input
                        placeholder="Valor"
                        className="w-20 appearance:textfield [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                        type="number"
                        value={valueExpression}
                        onChange={(event) => {
                          const value = event.target.value
                          if (value === '' || Number(value) >= 0) {
                            setValueExpression(value === '' ? '' : Number(value))
                          }
                        }}
                        onKeyDown={handleKeyDown}
                      />
                      <Button
                        disabled={typeExpression === '' || valueExpression === ''}
                        onClick={() => handleChangeExpressions({ type: typeExpression, value: Number(valueExpression) })}
                        variant="default">
                        <Plus size={20} />
                      </Button>
                    </div>
                    <div className="w-full min-h-10 max-h-[200px] border rounded-md flex flex-col items-center justify-start overflow-auto">
                      {expressionsLabels.length !== 0 ? (
                        expressionsLabels.map((expression, index) => (
                          <div
                            key={index}
                            className="w-full border-b last:border-b-0 flex items-center justify-between p-2"
                          >
                            <div className="flex items-center gap-2 text-neutral-500">
                              {CONDITIONS_OPTIONS.map((condition) => {
                                if (condition.value === expression.type) {
                                  return <span key={condition.value}>{condition.label}</span>
                                }
                              })}:
                              <span className="font-semibold text-neutral-600">{expression.value}</span>
                            </div>
                            <Button variant="ghost" onClick={() => handleRemoveExpression(index)}>
                              <CircleMinus size={15} />
                            </Button>
                          </div>
                        ))
                      ) : (
                        <p className="w-full h-10 flex items-center justify-center text-sm text-neutral-500">Nenhuma expressão.</p>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center justify-end">
                    <Button disabled={isSubmit} type="submit" onClick={handleSubmitNewRiskRule}>
                      {!isSubmit ? 'Enviar' : <span className="flex items-center gap-2"><Loader2 className="w-4 h-4 animate-spin" /> Enviando...</span>}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PlatformTemplate>
  )
}