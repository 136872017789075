import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import { getTransactionById } from "../../../../../api/endpoints/transaction"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { getPath } from "../../../../../utils/helpers/path"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import { formatToBRL } from "../../../../../utils/helpers/formated"
import { exportToPdf } from "../../../../../utils/helpers/export"
import { Button } from "../../../../../components/ui/button"
import { Download, Fingerprint, Loader2, ShieldAlert } from "lucide-react"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { TransactionProps } from "../../page"
import { TabsTransaction } from "../_components/tabs-transaction"
import Logo from "../../../../../assets/logo-black.svg"

export default function TransactionDetails() {
  const transactionId = getPath()
  const [transaction, setTransaction] = useState<TransactionProps | {}>()
  const [isExport, setIsExport] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(true)

  const fetchTransactionById = async () => {
    setIsExport(true)
    const response = await getTransactionById({ transactionId })
    if (response?.data) {
      setTransaction(response?.data)
    } else {
      setTransaction({})
    }

    setIsLoading(false)
    setIsExport(false)
  }

  useEffect(() => {
    fetchTransactionById()
  }, [])

  const handleExportToPdf = async () => {
    setIsExport(true)
    setTransaction(undefined)
    const response = await getTransactionById({ transactionId })
    if (response?.data) {
      setTransaction(response?.data)
      setTimeout(() => {
        exportToPdf("transaction", "transaction")
      }, 200)
    }
    setIsExport(false)
  }

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsTransaction value="details" />
          {!isLoading ? <TransactionByIdContent transaction={transaction} onExportToPdf={handleExportToPdf} isExport={isExport} /> : <Skeleton className="w-full h-full" />}
        </div>
      </div>
    </PlatformTemplate>
  )
}

const TransactionByIdContent = ({ transaction, onExportToPdf, isExport }: { transaction: any, onExportToPdf: () => void, isExport: boolean }) => {
  const ISPB = process.env.REACT_APP_BANK_ISPB
  const navigate = useNavigate()

  if (!transaction) {
    return (
      <div className="w-full flex flex-col items-center justify-center gap-5 py-10">
        <div className="w-full px-20">
          <div id="transaction" className="w-full h-[300px] flex items-center justify-center">
            <Loader2 className="mr-2 h-12 w-12 animate-spin" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full flex flex-col items-center justify-center gap-5 py-10">
      <div className="w-full px-20 overflow-x-auto">
        <div className="w-full flex items-center justify-end gap-2">
          <Button className="flex gap-2" onClick={onExportToPdf} variant="outline" disabled={isExport}>
            {isExport ? "Exportando..." : <>Exportar para PDF<Download size={15} /></>}
          </Button>
          {transaction.method === "Dict" && transaction.creditorIspb !== ISPB &&
            <Button variant="destructive" onClick={() => navigate(`/transaction/report-infraction/${transaction.endToEndIdentification}`)} className="flex gap-2">Relatar Infração<ShieldAlert size={15} /></Button>
          }
        </div>
        <div id="transaction" className="shadow-md w-full min-w-[700px] flex flex-col gap-7 p-10">
          <div className="flex items-center justify-between">
            <img src={Logo} />
          </div>
          <div className="w-full flex items-center justify-between border-b border-gray-200 py-8">
            <p className="font-medium">Identificador da Transação:</p>
            <div className="flex items-center justify-end gap-2">
              <Fingerprint size={20} className="text-zinc-950" />
              <p className="text-zinc-950">{getPath()}</p>
            </div>
          </div>
          <div className="flex flex-col gap-2 pb-2 border-b-2 border-slate-400 border-dotted text-gray-600">
            <h1 className="text-sky-800 font-semibold">Informações Gerais da Transação</h1>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Data de Criação:</p>
              <p>{convertUTCToUserTimezone((transaction.createDate), getUserTimezoneOffset())}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Método de Transação:</p>
              <p>{transaction.method}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Etapa:</p>
              <p>{transaction.status}</p>
            </div>
            <div className="w-full flex items-center justify-between py-1">
              <p className="font-medium">Identificador E2E:</p>
              <p>{transaction.endToEndIdentification}</p>
            </div>
          </div>
          <div className="flex flex-col gap-2 pb-2 border-b-2 border-slate-400 border-dotted text-gray-600">
            <h1 className="text-sky-800 font-semibold">Informações do Credor</h1>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Nome:</p>
              <p>{transaction.creditorName}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Agência:</p>
              <p>{transaction.creditorAccountBranch}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Conta:</p>
              <p>{transaction.creditorAccountNumber}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Tipo de Conta:</p>
              <p>{transaction.creditorAccountType}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Identificador:</p>
              <p>{transaction.creditorIdentification}</p>
            </div>
            <div className="w-full flex items-center justify-between py-1">
              <p className="font-medium">ISPB</p>
              <p>{transaction.creditorIspb}</p>
            </div>
          </div>
          <div className="flex flex-col gap-2 pb-2 border-b-2 border-slate-400 border-dotted text-gray-600">
            <h1 className="text-sky-800 font-semibold">Informações do Devedor</h1>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Nome:</p>
              <p>{transaction.debtorName}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Agência:</p>
              <p>{transaction.debtorAccountBranch}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Conta:</p>
              <p>{transaction.debtorAccountNumber}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Tipo de Conta:</p>
              <p>{transaction.debtorAccountType}</p>
            </div>
            <div className="w-full flex items-center justify-between border-b border-gray-200 py-1">
              <p className="font-medium">Identificador:</p>
              <p>{transaction.debtorIdentification}</p>
            </div>
            <div className="w-full flex items-center justify-between py-1">
              <p className="font-medium">ISPB:</p>
              <p>{transaction.debtorIspb}</p>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <h1 className="font-semibold">{formatToBRL(transaction.amount)}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}
