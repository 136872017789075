import { Button } from "../../../../components/ui/button"
import { ReceiptText } from "lucide-react"

export default function PopUpAuth({ setPopUp, content }: { setPopUp: any, content: 'terms' | 'policy' }) {

  return (
    <div className="w-full h-full fixed bg-zinc-950 bg-opacity-80 flex justify-center py-20">
      <div className="w-1/2 h-auto drop-shadow-2xl bg-gray-100 rounded-lg p-10 overflow-y-auto">
        {content === 'terms' ? <TermsAndConditions setPopUp={setPopUp} /> : <PrivacyPolicy setPopUp={setPopUp} />}
      </div>
    </div>
  )
}

const TermsAndConditions = ({ setPopUp }: { setPopUp: any }) => {
  return (
    <div className="flex flex-col gap-10">
      <h1 className="flex gap-2 items-center font-bold text-xl"><ReceiptText size={20} /> Termos e Condições</h1>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">1. Aceitação dos Termos</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p>Ao utilizar nosso software de gestão interna de Clientes, Contas internas de finanças, informações de transações e autenticação de usuário, você concorda em cumprir e estar vinculado aos seguintes Termos e Condições. Se você não concorda com qualquer parte destes termos, não deve utilizar nosso software.</p>
          <p>Ao utilizar nosso software de gestão interna de Clientes, Contas internas de finanças, informações de transações e autenticação de usuário, você concorda em cumprir e estar vinculado aos seguintes Termos e Condições. Se você não concorda com qualquer parte destes termos, não deve utilizar nosso software.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">2. Definições</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p><span className="text-zinc-950">Usuário:</span> Qualquer pessoa ou entidade que acessa e utiliza o software.</p>
          <p><span className="text-zinc-950">Software:</span> Aplicação de gestão interna de Clientes, Contas internas de finanças, informações de transações e autenticação de usuário.</p>
          <p><span className="text-zinc-950">Dados do Cliente:</span> Informações pessoais e financeiras dos clientes geridas pelo software.</p>
          <p><span className="text-zinc-950">Dados do Usuário:</span> Informações sobre o usuário que utiliza o software, incluindo credenciais de autenticação e registros de acesso.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">3. Uso do Software</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p><span className="text-zinc-950 font-medium ml-2">3.1.</span> O software é destinado para uso interno de gestão e não deve ser utilizado para fins ilegais ou não autorizados.</p>
          <p><span className="text-zinc-950 font-medium ml-2">3.2.</span> Cada usuário deve ter um conjunto de acessos específicos, e é responsável por manter a confidencialidade de suas credenciais de login.</p>
          <p><span className="text-zinc-950 font-medium ml-2">3.3.</span> É proibido o compartilhamento de acessos entre diferentes usuários.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">4. Responsabilidades do Usuário</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p><span className="text-zinc-950 font-medium ml-2">4.1.</span> Os usuários são responsáveis por garantir que todas as informações inseridas no software sejam precisas e atualizadas.</p>
          <p><span className="text-zinc-950 font-medium ml-2">4.2.</span> Os usuários devem utilizar o software em conformidade com todas as leis e regulamentos aplicáveis.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">5. Direitos de Propriedade Intelectual</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p>O software, incluindo todos os seus componentes e atualizações, é de propriedade exclusiva da [Nome da Empresa], e é protegido por leis de direitos autorais e outros direitos de propriedade intelectual.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">6. Limitação de Responsabilidade</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p>Em nenhuma circunstância a [Nome da Empresa] será responsável por quaisquer danos diretos, indiretos, incidentais, especiais ou consequenciais decorrentes do uso ou incapacidade de uso do software.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">7. Modificações dos Termos</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p>A [Nome da Empresa] reserva-se o direito de modificar estes Termos e Condições a qualquer momento. Quaisquer alterações serão notificadas aos usuários através do software.</p>
        </div>
      </div>
      <div className="w-full flex items-center justify-end">
        <Button variant="default" onClick={() => setPopUp({ active: false })}>Fechar</Button>
      </div>
    </div>
  )
}

const PrivacyPolicy = ({ setPopUp }: { setPopUp: any }) => {
  return (
    <div className="flex flex-col gap-10">
      <h1 className="flex gap-2 items-center font-bold text-xl"><ReceiptText size={20} /> Política de Privacidade</h1>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">1. Introdução</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p>Esta Política de Privacidade descreve como a [Nome da Empresa] coleta, usa e protege as informações pessoais e financeiras dos clientes e usuários do software de gestão interna.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">2. Coleta de Informações</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p><span className="text-zinc-950 font-medium ml-2">2.1.</span> Informações dos Clientes: Inclui dados pessoais como nome, endereço, informações de contato e detalhes financeiros.</p>
          <p><span className="text-zinc-950 font-medium ml-2">2.2.</span> Informações dos Usuários: Inclui credenciais de login, histórico de acesso e outras informações relevantes para a autenticação e autorização de uso do software.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">3. Uso das Informações</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p><span className="text-zinc-950 font-medium ml-2">3.1.</span> As informações coletadas são utilizadas para fornecer e melhorar os serviços do software, incluindo gestão de contas, transações financeiras e autenticação de usuários.</p>
          <p><span className="text-zinc-950 font-medium ml-2">3.2.</span> As informações podem ser utilizadas para comunicação com os usuários e clientes sobre atualizações, manutenção e outros assuntos relevantes ao uso do software.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">4. Proteção de Dados</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p><span className="text-zinc-950 font-medium ml-2">4.1.</span> A Lyra Network implementa medidas de segurança adequadas para proteger as informações pessoais e financeiras contra acesso não autorizado, alteração, divulgação ou destruição.</p>
          <p><span className="text-zinc-950 font-medium ml-2">4.2.</span> Apenas funcionários autorizados têm acesso às informações, e esse acesso é limitado ao necessário para realizar suas funções.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">5. Compartilhamento de Informações</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p>A Lyra Network não compartilha informações pessoais ou financeiras dos clientes e usuários com terceiros, exceto quando necessário para cumprir com obrigações legais ou mediante consentimento explícito do usuário ou cliente.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">6. Retenção de Dados</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p>As informações pessoais e financeiras serão mantidas pelo período necessário para cumprir os objetivos descritos nesta Política de Privacidade, ou conforme exigido por lei.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">7. Direitos dos Usuários</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p>Os usuários têm o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Para exercer esses direitos, os usuários devem entrar em contato com felipe.baptista@veripag.com.br</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">8. Alterações na Política de Privacidade</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p>A Lyra Network pode atualizar esta Política de Privacidade periodicamente. Quaisquer alterações serão comunicadas aos usuários através do software.</p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-semibold">9. Contato</p>
        <div className="flex flex-col gap-2 text-zinc-600">
          <p>Para perguntas ou preocupações sobre esta Política de Privacidade, entre em contato com felipe.baptista@veripag.com.br</p>
        </div>
      </div>
      <div className="w-full flex items-center justify-end">
        <Button onClick={() => setPopUp({ active: false })} variant="default">Fechar</Button>
      </div>
    </div>
  )
}