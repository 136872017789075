import { ChangeEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { UserProps, createUser, getRoles } from "../../../../api/endpoints/auth"
import { IsEmptyObjectExceptKeys, validateEmail } from "../../../../utils/helpers/validate"
import { PlatformTemplate } from "../../../../components/layout/template"
import { Input } from "../../../../components/ui/input"
import { Button } from "../../../../components/ui/button"
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select"
import { Loader2 } from "lucide-react"
import { TimezoneProps, getTimezone } from "../../../../api/endpoints/timezone"
import Swal from "sweetalert2"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "../../../../components/ui/alert-dialog"
import { Checkbox } from "../../../../components/ui/checkbox"

export default function CreateNewUser() {
  const navigate = useNavigate()
  const [repeatPassword, setRepeatPassword] = useState<string>("")
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  // O timezone definido por padrão é o gt-3
  const defaultTimezoneId = 34
  const [newUser, setNewUser] = useState<UserProps>({
    userName: "",
    password: "",
    email: "",
    type: "",
    roles: [],
    timezoneId: defaultTimezoneId
  })
  const [timezone, setTimezone] = useState<TimezoneProps[]>([])
  const [roles, setRoles] = useState([])

  const handleCreateNewUser = async () => {
    setIsSubmit(true)

    if (!IsEmptyObjectExceptKeys(newUser, ['roles'])) {
      Swal.fire({
        icon: "error",
        title: "Preencha os campos obrigatórios antes de enviar.",
        confirmButtonColor: "var(--gray-500)",
      })
      return setIsSubmit(false)
    }

    if (!validateEmail(newUser.email)) {
      Swal.fire({
        icon: "error",
        title: "O E-mail é inválido.",
        confirmButtonColor: "var(--gray-500)",
      })
      return setIsSubmit(false)
    }

    if (newUser.password !== repeatPassword) {
      Swal.fire({
        icon: "error",
        title: "As senhas devem ser iguais!",
        confirmButtonColor: "var(--gray-500)",
      })
      return setIsSubmit(false)
    }

    const response = await createUser({
      userName: newUser.userName,
      password: newUser.password,
      email: newUser.email,
      type: newUser.type,
      roles: newUser.roles,
      timezoneId: Number(newUser.timezoneId)
    })

    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Usuário criado com sucesso!",
        showConfirmButton: false,
        timer: 1500
      })

      return navigate('/user')
    } else {
      Swal.fire({
        icon: "error",
        title: "As senhas devem ser iguais!",
        confirmButtonColor: "var(--gray-500)",
      })

      return setIsSubmit(false)
    }
  }

  const fetchAllTimezone = async () => {
    const response: TimezoneProps[] = await getTimezone()
    return setTimezone(response)
  }

  const fetchRoles = async () => {
    const response: any = await getRoles()
    return setRoles(response?.data?.roles)
  }

  useEffect(() => {
    fetchAllTimezone()
    fetchRoles()
  }, [])

  return (
    <PlatformTemplate>
      <div className="w-full h-full flex items-center justify-center">
        <div className="w-1/2 flex flex-col items-center justify-center gap-10">
          <h1 className="text-2xl font-semibold">Criar novo Usuário</h1>
          <div className="w-2/3 flex flex-col gap-3">
            <Input
              placeholder="Nome de usuário"
              value={newUser.userName}
              onChange={(event) => setNewUser({ ...newUser, userName: event.target.value })}
            />
            <Input
              placeholder="E-mail"
              value={newUser.email}
              onChange={(event) => setNewUser({ ...newUser, email: event.target.value })}
            />
            <Input
              placeholder="Senha"
              value={newUser.password}
              type="password"
              onChange={(event) => setNewUser({ ...newUser, password: event.target.value })}
            />
            <Input
              placeholder="Repita a sua senha"
              value={repeatPassword}
              type="password"
              onChange={(event) => setRepeatPassword(event.target.value)}
            />
            <Select onValueChange={(event) => setNewUser({ ...newUser, timezoneId: Number(event) })}>
              <SelectTrigger>
                <SelectValue placeholder="Fuso Horário" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Tipos</SelectLabel>
                  {timezone.map((item) => (
                    <SelectItem value={item.id.toString()}>{item.displayName}</SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <Select onValueChange={(event) => setNewUser({ ...newUser, type: event })}>
              <SelectTrigger>
                <SelectValue placeholder="Tipo" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Tipos</SelectLabel>
                  <SelectItem value="Operator">Operador</SelectItem>
                  <SelectItem value="Super">Super</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
            <Permissions roles={roles} newUser={newUser} setNewUser={setNewUser} />
          </div>
          <div className="w-2/3 flex items-center justify-end gap-2">
            {isSubmit ? <Loader2 className="mr-2 h-8 w-8 animate-spin text-slate-500" /> : <Button onClick={handleCreateNewUser}>Criar</Button>}
          </div>
        </div>
      </div>
    </PlatformTemplate>
  )
}
const Permissions = ({ roles, newUser, setNewUser }: { roles: any, newUser: UserProps, setNewUser: any }) => {
  const handleRoleChange = (role: string, isChecked: boolean) => {
    let updatedRoles;
    if (isChecked) {
      updatedRoles = [...newUser.roles, role];
    } else {
      updatedRoles = newUser.roles.filter(roleValue => roleValue !== role);
    }
    setNewUser({ ...newUser, roles: updatedRoles });
  }

  if (newUser.type === "Operator") {
    return (
      <div className="">
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <p className="cursor-pointer text-sm text-zinc-600 hover:underline">Configurar permissões</p>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Configurar permissões</AlertDialogTitle>
              <AlertDialogDescription className="flex flex-col gap-5">
                <p>As permissões configuradas serão aplicadas ao usuário do tipo operador. Somente os itens selecionados serão mostrados na plataforma para o usuário criado.</p>
                <div className="flex flex-col items-start gap-2">
                  {roles.map((role: any, index: number) => (
                    <div className="flex items-center space-x-2" key={index}>
                      <input
                        className="cursor-pointer accent-black"
                        type="checkbox"
                        checked={newUser.roles.includes(role)}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => handleRoleChange(role, event.target.checked)}
                        id={`role-${index}`}
                      />
                      <label
                        htmlFor={`role-${index}`}
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        {role}
                      </label>
                    </div>
                  ))}
                </div>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogAction>Concluir</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    )
  }

  return null
}
