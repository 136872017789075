
import { useEffect, useState, ChangeEvent } from 'react'
import { getOwnersById, updateAddressOwner } from '../../../../../api/endpoints/owner'
import { getPath } from '../../../../../utils/helpers/path'
import { formatCEP } from '../../../../../utils/helpers/formated'
import { getAddressByCep } from '../../../../../api/endpoints/viacep'
import { IsEmptyObjectExceptKeys, ternaryOperator } from '../../../../../utils/helpers/validate'
import { Button } from '../../../../../components/ui/button'
import { Input } from '../../../../../components/ui/input'
import { Skeleton } from '../../../../../components/ui/skeleton'
import { Loader2 } from 'lucide-react'
import { TabsOwner } from '../_components/tabs-owner'
import { PlatformTemplate } from '../../../../../components/layout/template'
import Swal from 'sweetalert2'

export default function OwnerAdresses() {
  const [ownerId, setOwnerId] = useState<string>()
  const [owner, setOwner] = useState<any>()

  const fetchOwnerById = async () => {
    const response = await getOwnersById(getPath())

    if (response?.data) {
      const ownerData = response?.data
      const formattedResponse = {
        name: ownerData.name,
        fantasyName: ownerData.fantasyName || "",
        address: {
          street: ownerData.address.street,
          number: ownerData.address.number,
          complement: ownerData.address.complement || "",
          neighborhood: ownerData.address.neighborhood,
          city: ownerData.address.city,
          stateCode: ownerData.address.stateCode,
          postalCode: ownerData.address.postalCode
        }
      }

      setOwnerId(ownerData.id)
      return setOwner(formattedResponse)
    }

    return setOwner([])
  }

  useEffect(() => {
    fetchOwnerById()
  }, [])

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsOwner value="adresses" />
          {owner ?
            <OwnerAddressContent ownerId={ownerId} owner={owner} setOwner={setOwner} /> :
            <Skeleton className="w-full h-full" />}
        </div>
      </div>
    </PlatformTemplate>
  )
}

const OwnerAddressContent = ({ ownerId, owner, setOwner }: { ownerId: any, owner: any, setOwner: any }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isSubmit, setIsSubmit] = useState<boolean>(false)

  const handleSaveNewAddress = async () => {
    setIsSubmit(true)
    const isValid = IsEmptyObjectExceptKeys(owner, ['name', 'fantasyName', 'complement'])

    if (isValid) {
      const result: any = await updateAddressOwner(ownerId, owner)

      if (result.error) {
        setIsSubmit(false)
        return Swal.fire({
          icon: "error",
          title: "Não foi possível realizar as alterações.",
          text: result.message,
          confirmButtonColor: "var(--gray-500)",
        })
      }

      if (result.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Alteração realizada com sucesso",
          showConfirmButton: false,
          timer: 1500
        })
        return setTimeout(() => {
          setIsSubmit(false)
          setIsEdit(false)
        }, 1500)
      }
    }

    setIsSubmit(false)
    setIsEdit(false)
    return Swal.fire({
      icon: "error",
      title: "Não foi possível realizar a alteração",
      text: "Verifique se todos os campos obrigatórios foram preenchidos.",
      confirmButtonColor: "var(--gray-500)",
    })
  }

  const handleChangeCep = (e: ChangeEvent<HTMLInputElement>) => {
    const newCEPValue = formatCEP(e.target.value)
    setOwner({ ...owner, address: { ...owner.address, postalCode: newCEPValue, } })

    if (newCEPValue.length === 9) {
      fetchAddressByCep(newCEPValue)
    }
  }

  const fetchAddressByCep = async (cep: string) => {
    const response = await getAddressByCep(cep)
    setOwner({
      ...owner, address: {
        ...owner.address,
        street: ternaryOperator(
          response.logradouro,
          response.logradouro,
          owner.address.street
        ),
        stateCode: ternaryOperator(
          response.uf,
          response.uf,
          owner.stateCode
        ),
        postalCode: ternaryOperator(
          response.cep,
          response.cep,
          owner.address.postalCode
        ),
        city: ternaryOperator(
          response.localidade,
          response.localidade,
          owner.address.city
        ),
        neighborhood: ternaryOperator(
          response.bairro,
          response.bairro,
          owner.address.neighborhood
        ),
      }
    })
  }

  return (
    <div className="py-10 px-2 flex items-center justify-center">
      <div className="w-2/3 flex flex-col gap-5">
        <div className="flex items-center justify-between">
          <h1 className='text-lg font-semibold text-zinc-600'>Informações de Endereço</h1>
          {!isEdit && <Button onClick={() => setIsEdit(true)}>Editar</Button>}
        </div>
        <div className="flex flex-col gap-5">
          <Input
            placeholder='CEP'
            type='text'
            value={formatCEP(owner.address.postalCode)}
            onChange={handleChangeCep}
            disabled={!isEdit}
          />
          <Input
            placeholder='Rua'
            type='text'
            value={owner.address.street}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setOwner({ ...owner, address: { ...owner.address, street: event.target.value } })}
            disabled={!isEdit}
          />
          <Input
            placeholder='Número'
            type='text'
            value={owner.address.number}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setOwner({ ...owner, address: { ...owner.address, number: event.target.value } })}
            disabled={!isEdit}
          />
          <Input
            placeholder='Bairro'
            type='text'
            value={owner.address.neighborhood}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setOwner({ ...owner, address: { ...owner.address, neighborhood: event.target.value } })}
            disabled={!isEdit}
          />
          <Input
            placeholder='Cidade'
            type='text'
            value={owner.address.city}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setOwner({ ...owner, address: { ...owner.address, city: event.target.value } })}
            disabled={!isEdit}
          />
          <Input
            placeholder='UF'
            type='text'
            value={owner.address.stateCode}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setOwner({ ...owner, address: { ...owner.address, stateCode: event.target.value } })}
            disabled={!isEdit}
          />
          <Input
            placeholder='Complemento'
            type='text'
            value={owner.address.complement}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setOwner({ ...owner, address: { ...owner.address, complement: event.target.value } })}
            disabled={!isEdit}
          />
          {isEdit &&
            <div className="flex items-center justify-center">
              {!isSubmit ?
                <div className="flex items-center justify-center"><Button onClick={() => handleSaveNewAddress()}>Salvar</Button></div>
                : <Loader2 className="mr-2 h-8 w-8 animate-spin" />}
            </div>
          }
        </div>
      </div>
    </div>
  )
}