import { Dispatch, SetStateAction, useState } from "react"
import { Button } from "../../../../../components/ui/button"
import { IsEmptyObjectExceptKeys } from "../../../../../utils/helpers/validate"
import Swal from "sweetalert2"
import { createOwner } from "../../../../../api/endpoints/owner"
import { useNavigate } from "react-router-dom"
import { Card, CardContent } from "../../../../../components/ui/card"
import { Loader2 } from "lucide-react"

export const OwnerStepConfirmation = ({
  owner,
  setStepPosition,
}: {
  owner: any;
  setStepPosition: Dispatch<SetStateAction<number>>
}) => {
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleCreateNewOwnerAndAccount = async () => {
    setIsSubmit(true)
    const isValid = IsEmptyObjectExceptKeys(owner.address, ['complement'])

    if (isValid) {
      const result: any = await createOwner(owner)

      if (result.error) {
        setIsSubmit(false)
        return Swal.fire({
          icon: "error",
          title: "Não foi possível realizar o cadastro.",
          text: result.message,
          confirmButtonColor: "var(--gray-500)",
        })
      }

      if (result.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Cliente e Conta criados com sucesso!",
          showConfirmButton: false,
          timer: 1500
        })
        return setTimeout(() => {
          setIsSubmit(false)
          navigate('/owner')
        }, 1500)
      }
    }

    setIsSubmit(false)
    return Swal.fire({
      icon: "error",
      title: "Não foi possível realizar o cadastro.",
      confirmButtonColor: "var(--gray-500)",
    })
  }

  return (
    <div className="w-1/2 flex flex-col items-center justify-center gap-5">
      <h1 className="w-2/3 text-center text-2xl font-semibold">Confirmação</h1>
      <Card className="w-2/3">
        <CardContent className="w-full h-full overflow-y-auto p-5">
          <div>
            <div className="my-3">
              <h1 className="text-medium font-semibold">Informações Gerais</h1>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium">Nome:</p>
                <p className="text-sm">{owner.name ? owner.name : <span className="text-red-500">Não Informado</span>}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium">Tipo de Documento:</p>
                <p className="text-sm">{owner.documentType ? owner.documentType : <span className="text-red-500">Não Informado</span>}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium">Número do Documento:</p>
                <p className="text-sm">{owner.documentNumber ? owner.documentNumber : <span className="text-red-500">Não Informado</span>}</p>
              </div>
              <div className="text-sm flex items-center justify-between">
                <p className="font-medium">Criação de conta automaticamente:</p>
                <p className="text-sm">{owner.createAccount ? 'Sim' : 'Não'}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium">E-mail:</p>
                <p className="text-sm">{owner.email ? owner.email : <span className="text-red-500">Não Informado</span>}</p>
              </div>
            </div>
            <div className="my-3">
              <h1 className="text-medium font-semibold">Informações de Endereço</h1>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium">CEP: </p>
                <p className="text-sm">{owner.address.postalCode ? owner.address.postalCode : <span className="text-red-500">Não Informado</span>}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium">Rua:</p>
                <p className="text-sm">{owner.address.street ? owner.address.street : <span className="text-red-500">Não Informado</span>}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium">Número:</p>
                <p className="text-sm">{owner.address.number ? owner.address.number : <span className="text-red-500">Não Informado</span>}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium">Complemento:</p>
                <p className="text-sm">{owner.address.complement ? owner.address.complement : <span className="text-red-500">Não Informado</span>}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium">Bairro:</p>
                <p className="text-sm">{owner.address.neighborhood ? owner.address.neighborhood : <span className="text-red-500">Não Informado</span>}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium">Cidade:</p>
                <p className="text-sm">{owner.address.city ? owner.address.city : <span className="text-red-500">Não Informado</span>}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium">Estado:</p>
                <p className="text-sm">{owner.address.stateCode ? owner.address.stateCode : <span className="text-red-500">Não Informado</span>}</p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="w-2/3 flex items-center justify-between gap-2">
        <Button disabled={isSubmit} className="w-1/2" variant="secondary" onClick={() => setStepPosition(1)}>
          Voltar
        </Button>
        <Button
          disabled={isSubmit}
          className="w-1/2"
          onClick={() => handleCreateNewOwnerAndAccount()}
        >
          {!isSubmit ? <>Criar</> : <> <Loader2 className="mr-2 h-4 w-4 animate-spin" /> Por favor, aguarde</>}
        </Button>
      </div>
    </div>
  )
}