// => alert.ts
import { HeadersAccessToken } from "../config"
import api from ".."

/**
 * => Interface que define as propriedades de um alerta.
 */
export interface AlertProps {
  alertId?: string,
  scope?: string,
  type?: string,
  referenceId?: string,
  accountId?: string,
  accountNumber?: string,
  ownerId?: string,
  ownerName?: string,
  priority?: string,
  status?: string,
  expression?: string,
  value?: string,
  resolution?: string,
  resolutionDate?: string,
  openedUserId?: string,
  openedUserName?: string | null,
  closedUserId?: string,
  closedUserName?: string | null,
  comments?: string | null,
  histories?: string | null,
  createDate?: string,
  updateDate?: string
}

/**
 * => Interface que define os parâmetros para obter alertas.
 */
export interface GetAlertsProps {
  alertId?: string,
  referenceId?: string
  status?: string
  type?: string
  scope?: string
  priority?: string
  accountId?: string
  ownerId?: string
  openedUserId?: string
  closedUserId?: string
  createDateStart?: string
  createDateEnd?: string
  updateDateStart?: string
  updateDateEnd?: string
  resolutionDateStart?: string
  resolutionDateEnd?: string
  pageSize?: number
  pageIndex?: number
}

/**
 * => Função para obter alertas com base nos parâmetros fornecidos.
 * @param params Parâmetros para filtragem de alertas.
 * @returns Lista de alertas ou erro.
 */
export const getAlerts = async (params: GetAlertsProps) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const queryParams = {
    id: params.alertId,
    referenceId: params.referenceId,
    status: params.status,
    type: params.type,
    scope: params.scope,
    priority: params.priority,
    accountId: params.accountId,
    ownerId: params.ownerId,
    openedUserId: params.openedUserId,
    closedUserId: params.closedUserId,
    createDateStart: params.createDateStart,
    createDateEnd: params.createDateEnd,
    updateDateStart: params.updateDateStart,
    updateDateEnd: params.updateDateEnd,
    resolutionDateStart: params.resolutionDateStart,
    resolutionDateEnd: params.resolutionDateEnd,
    pageSize: params.pageSize,
    pageIndex: params.pageIndex
  }

  const urlParams = Object.entries(queryParams)
    .filter(([_, value]) => (typeof value === 'string' ? value.trim() !== '' : value !== undefined && value !== null))
    .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
    .join('&')

  const url = `/v1/alert/?${urlParams}`

  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para obter um alerta pelo ID.
 * @param alertId ID do alerta.
 * @returns Dados do alerta ou erro.
 */
export const getAlertById = async ({ alertId }: { alertId: string }) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/alert/${alertId}`
  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para obter comentários de um alerta.
 * @param alertId ID do alerta.
 * @returns Lista de comentários ou erro.
 */
export const getAlertComments = async ({ alertId }: { alertId: string }) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/alert/${alertId}/comments`
  try {
    return await api.get(url, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para adicionar um comentário a um alerta.
 * @param alertId ID do alerta.
 * @param comment Comentário a ser adicionado.
 * @returns Resultado da adição do comentário ou erro.
 */
export const addAlertComment = async ({ alertId, comment }: { alertId: string, comment: string }) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/alert/${alertId}/comments`

  try {
    return await api.post(url, { comment }, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para abrir um alerta.
 * @param alertId ID do alerta.
 * @returns Resultado da operação ou erro.
 */
export const openAlert = async ({ alertId }: { alertId: string }) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/alert/${alertId}/open`
  try {
    return await api.post(url, {}, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}

/**
 * => Função para fechar um alerta com resolução.
 * @param alertId ID do alerta.
 * @param resolution Resolução do alerta.
 * @returns Resultado da operação ou erro.
 */
export const closeAlert = async ({ alertId, resolution }: { alertId: string, resolution: string }) => {
  const access_token = `Bearer ${HeadersAccessToken()}`
  const url = `/v1/alert/${alertId}/close`

  try {
    return await api.post(url, { resolution }, { headers: { Authorization: access_token } })
  } catch (error: any) {
    return error
  }
}