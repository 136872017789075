import { useNavigate } from "react-router-dom"
import { getPath } from "../../../../../utils/helpers/path"
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "../../../../../components/ui/tabs"
import { ArrowLeftRight } from "lucide-react"

export const TabsTransaction = ({ value }: { value: string }) => {
  const navigate = useNavigate()

  return (
    <Tabs value={value} className="flex flex-col gap-3">
      <div className="w-full flex items-center py-3 gap-2">
        <ArrowLeftRight size={20} />
        <h1 className="text-[20px] font-semibold">Transações</h1>
      </div>
      <TabsList className="grid grid-cols-3">
        <TabsTrigger value="details" onClick={() => navigate(`/transaction/details/${getPath()}`)}>Detalhes</TabsTrigger>
        <TabsTrigger value="trajectory" onClick={() => navigate(`/transaction/trajectory/${getPath()}`)}>Trajetória da Transação</TabsTrigger>
        <TabsTrigger value="end-to-end-trajectory" onClick={() => navigate(`/transaction/end-to-end-trajectory/${getPath()}`)}>Trajetória do E2E</TabsTrigger>
      </TabsList>
    </Tabs>
  )
}
