import { useEffect, useState } from "react"
import { getUsers } from "../../../api/endpoints/auth"
import { Skeleton } from "../../../components/ui/skeleton"
import { PlatformTemplate } from "../../../components/layout/template"
import { User } from "lucide-react"
import TableUsers from "./_components/table-users"

export type UserProps = {
  id: string
  userName: string
  email: string
  type: string
  roles: string[]
}

export type Filters = {
  userName: string
  email: string
  type: '' | 'Super' | 'Admin' | 'User'
  roles: string
}

export default function Users() {
  const [users, setUsers] = useState<UserProps[]>()
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [filters, setFilters] = useState<Filters>({
    userName: '',
    email: '',
    type: '',
    roles: '',
  })
  const [appliedFilters, setAppliedFilters] = useState<Filters>(filters)

  const fetchUsers = async (pageIndex: number, pageSize: number, filters: Filters) => {
    const response: any = await getUsers({ pageIndex, pageSize, ...filters })
    setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
    if (response?.data?.items) {
      setUsers(response?.data?.items)
    } else {
      setUsers([])
    }
  }

  useEffect(() => {
    fetchUsers(pageIndex, pageSize, appliedFilters)
  }, [pageIndex, pageSize, appliedFilters])

  return (
    <PlatformTemplate>
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex items-center py-3 gap-2">
          <User size={20} />
          <h1 className="text-[20px] font-semibold">Usuários</h1>
        </div>
        {users ? (
          <TableUsers
            users={users}
            setUsers={setUsers}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            filters={filters}
            setFilters={setFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
        ) : (
          <Skeleton className="w-full h-full" />
        )}
      </div>
    </PlatformTemplate>
  )
}
