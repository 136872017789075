import { useState } from "react"
import { getPath } from "../../../../../utils/helpers/path"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { IsEmptyObject } from "../../../../../utils/helpers/validate"
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem
} from "../../../../../components/ui/select"
import {
  InfractionSituation,
  createInfraction
} from "../../../../../api/endpoints/refund"
import { Loader2, ShieldAlert } from "lucide-react"
import { Button } from "../../../../../components/ui/button"
import Swal from "sweetalert2"

enum InfractionReason {
  REFUND_REQUEST = 'RefundRequest',
  REFUND_CANCELLED = 'RefundCancelled'
}

interface ReportInfractionProps {
  endToEndIdentification: string
  reason: InfractionReason | ''
  situation: InfractionSituation | ''
}

export default function ReportInfraction() {
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [infraction, setInfraction] = useState<ReportInfractionProps>({
    endToEndIdentification: getPath(),
    reason: '',
    situation: ''
  })

  const handleReportInfraction = async () => {
    setIsSubmit(true)
    if (IsEmptyObject(infraction)) {
      Swal.fire({
        icon: "error",
        title: "Preencha todos os campos para enviar.",
        confirmButtonColor: "var(--gray-500)",
      })

      return setIsSubmit(false)
    }

    const response = await createInfraction(infraction)
    if (response.status === 200) {
      setIsSubmit(false)
      return Swal.fire({
        icon: "success",
        title: "Infração criada com Sucesso!",
        confirmButtonColor: "var(--gray-500)",
      })
    }

    setIsSubmit(false)
    return Swal.fire({
      icon: "error",
      title: "Ocorreu um erro. Tente novamente mais tarde.",
      confirmButtonColor: "var(--gray-500)",
    })
  }

  const changeReason = (value: InfractionReason) => {
    setInfraction({ ...infraction, reason: value })
  }

  const changeSituation = (value: InfractionSituation) => {
    setInfraction({ ...infraction, situation: value })
  }

  return (
    <PlatformTemplate>
      <div className="w-full h-full flex items-center justify-center">
        <div className="w-1/3 flex flex-col gap-10">
          <div className="w-full flex items-center justify-center gap-2">
            <ShieldAlert className="text-red-500" size={20} />
            <h1 className="text-xl text-zinc-700 font-medium">Relato de Infração</h1>
          </div>
          <div className="flex flex-col gap-5">
            <Select onValueChange={changeReason}>
              <SelectTrigger className="text-base">
                <SelectValue placeholder={infraction.reason ? infraction.reason : "Selecione a Razão"} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={InfractionReason.REFUND_REQUEST}>Solicitação de Reembolso</SelectItem>
                <SelectItem value={InfractionReason.REFUND_CANCELLED}>Reembolso Cancelado</SelectItem>
              </SelectContent>
            </Select>
            <Select onValueChange={changeSituation}>
              <SelectTrigger className="text-base">
                <SelectValue placeholder={infraction.situation ? infraction.situation : "Selecione a Situação"} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={InfractionSituation.SCAM}>Golpe</SelectItem>
                <SelectItem value={InfractionSituation.ACCOUNT_TAKEOVER}>Tomada de Conta</SelectItem>
                <SelectItem value={InfractionSituation.COERCION}>Coerção</SelectItem>
                <SelectItem value={InfractionSituation.FRAUDULENT_ACCESS}>Acesso Fraudulento</SelectItem>
                <SelectItem value={InfractionSituation.OTHER}>Outro</SelectItem>
                <SelectItem value={InfractionSituation.UNKNOWN}>Desconhecido</SelectItem>
              </SelectContent>
            </Select>
            <p className="text-sm text-zinc-500 text-justify">Nosso sistema permite que você relate infrações em transações bancárias, selecionando a situação e a razão do reembolso, para garantir que todas as questões financeiras sejam resolvidas de forma justa e eficiente.</p>
          </div>
          <div className="w-full flex items-center justify-center">
            {!isSubmit ?
              <Button onClick={handleReportInfraction} variant="destructive">
                Enviar Relato
              </Button> : <Loader2 className="mr-2 h-8 w-8 animate-spin" />}
          </div>
        </div>
      </div>
    </PlatformTemplate>
  )
}
