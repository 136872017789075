import { useEffect, useState } from "react"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { getOwnerActivityById } from "../../../../../api/endpoints/owner"
import { getPath } from "../../../../../utils/helpers/path"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "../../../../../components/ui/table"
import { Button } from "../../../../../components/ui/button"
import { TabsOwner } from "../_components/tabs-owner"

export default function OwnerActivities() {
  const [owner, setOwner] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  const fetchOwnerById = async (page: number, pageSize: number) => {
    setIsLoading(true)
    const response = await getOwnerActivityById(getPath(), page, pageSize)
    if (response?.data?.items) {
      setIsLoading(false)
      setTotalItems(response?.data?.totalCount)
      return setOwner(response?.data?.items)
    }

    return setOwner([])
  }

  useEffect(() => {
    fetchOwnerById(currentPage, itemsPerPage)
  }, [currentPage, itemsPerPage])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsOwner value="activities" />
          {!isLoading ?
            <OwnerActivitiesContent
              owner={owner}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              onPageChange={handlePageChange}
            /> :
            <Skeleton className="w-full h-full" />}
        </div>
      </div>
    </PlatformTemplate>
  )
}

const OwnerActivitiesContent = ({ owner, currentPage, itemsPerPage, totalItems, onPageChange }:
  { owner: any[], currentPage: number, itemsPerPage: number, totalItems: number, onPageChange: (page: number) => void }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  return (
    <div>
      <Table>
        <TableCaption>Lista de Atividades</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Data</TableHead>
            <TableHead>Ação</TableHead>
            <TableHead>Descrição</TableHead>
            <TableHead>Usuário</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {owner.map((item: any) => (
            <TableRow key={item.ownerId}>
              <TableCell className="font-medium">{convertUTCToUserTimezone(item.createDate, getUserTimezoneOffset())}</TableCell>
              <TableCell>{item.action}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>{item.userName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flex justify-between items-center mt-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
          disabled={currentPage === 1}
        >
          Anterior
        </Button>
        <span>
          Página {currentPage} de {totalPages}
        </span>
        <Button
          variant="outline"
          size="sm"
          onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Próximo
        </Button>
      </div>
    </div>
  )
}
