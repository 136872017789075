import React, { ChangeEvent, useState } from "react"
import { countFilledData } from "../../../../utils/helpers/calculate"
import { getAccounts } from "../../../../api/endpoints/account"
import { trimSpaces } from "../../../../utils/helpers/trimSpaces"
import { Input } from "../../../../components/ui/input"
import { Label } from "../../../../components/ui/label"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from "../../../../components/ui/sheet"
import { handleEnterKeyDown } from "../../../../utils/helpers/keyboard"
import { Button } from "../../../../components/ui/button"
import { Badge } from "../../../../components/ui/badge"
import { Filter, Search } from "lucide-react"
import { AccountFilterProps } from "./table-account"
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem
} from "../../../../components/ui/select"

type FilterKey = keyof AccountFilterProps

interface TableFiltersAccountProps {
  filters: AccountFilterProps
  setFilters: React.Dispatch<React.SetStateAction<AccountFilterProps>>
  setAccounts: React.Dispatch<React.SetStateAction<any[]>>
  pageIndex: number
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  appliedFilters: AccountFilterProps
  setAppliedFilters: React.Dispatch<React.SetStateAction<AccountFilterProps>>
}

export const TableFiltersAccount: React.FC<TableFiltersAccountProps> = ({
  filters, setFilters, setAccounts, pageIndex, setPageIndex, setTotalPages, pageSize, appliedFilters, setAppliedFilters
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitFilters = async () => {
    if (!filters.accountId.trim() && !filters.accountNumber.trim() && !filters.ownerId.trim() && !filters.ownerName.trim() && !filters.ownerDocument.trim() && !filters.status.trim()) return
    setIsSubmitting(true)

    const response: any = await getAccounts({ ...filters, pageIndex: 1, pageSize })

    if (response?.data?.items) {
      setAccounts(response?.data?.items)
      setAppliedFilters(filters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setAccounts([])
      setAppliedFilters(filters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const removeAllFilters = async () => {
    const resetFilters: AccountFilterProps = {
      accountId: '',
      accountNumber: '',
      ownerId: '',
      ownerName: '',
      ownerDocument: '',
      status: '',
      key: '',
      type: ''
    }
    setFilters(resetFilters)

    const response: any = await getAccounts({ pageIndex: 1, pageSize })

    if (response?.data?.items) {
      setAccounts(response?.data?.items)
      setAppliedFilters(resetFilters)
      setTotalPages(Math.ceil(response.data.totalCount / pageSize))
      setPageIndex(1)
      setIsSubmitting(false)
    } else {
      setAccounts([])
      setAppliedFilters(resetFilters)
      setTotalPages(0)
      setPageIndex(1)
      setIsSubmitting(false)
    }
  }

  const handleRemoveFilter = (key: FilterKey) => {
    const newFilters = { ...filters, [key]: '' }
    setFilters(newFilters)
    if (countFilledData(newFilters) === 0) {
      removeAllFilters()
    } else {
      submitFilters()
    }
  }

  const handleInputChange = (key: FilterKey, value: string) => {
    setFilters({ ...filters, [key]: trimSpaces(value) })
  }

  const handleSelectChange = (key: FilterKey, value: string) => {
    setFilters({ ...filters, [key]: value })
  }

  const handleNumericInputChange = (key: string, value: string) => {
    const numericValue = value.replace(/\D/g, '')
    handleInputChange(key, numericValue)
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="flex w-2/3 gap-2">
        <Input
          type="text"
          maxLength={8}
          onKeyDown={handleEnterKeyDown(submitFilters)}
          placeholder="Procurar pelo número da Conta..."
          disabled={isSubmitting}
          value={filters.accountNumber}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleNumericInputChange('accountNumber', e.target.value)}
          className="max-w-sm"
        />
        <Button disabled={isSubmitting || !filters.accountNumber.trim()} onClick={submitFilters} variant="default">
          <Search size={20} />
        </Button>
        <Sheet>
          <SheetTrigger>
            <Button disabled={isSubmitting} className="flex gap-2">
              <Filter size={15} />
              Filtros
            </Button>
          </SheetTrigger>
          <SheetContent className="flex flex-col gap-8">
            <SheetHeader>
              <SheetTitle className="flex items-center gap-2">
                <Filter size={20} />
                Filtros
              </SheetTitle>
              <SheetDescription>
                Utilize os filtros personalizados para encontrar dados que está buscando na tabela
              </SheetDescription>
            </SheetHeader>
            <div className="flex flex-col overflow-y-auto px-1 gap-5">
              {(['accountId', 'accountNumber', 'ownerId', 'ownerName', 'ownerDocument', 'status'] as FilterKey[]).map((key) => (
                <div key={key}>
                  <Label>{filterLabels[key]}</Label>
                  {key === 'status' ? (
                    <Select onValueChange={(value) => handleSelectChange(key, value)}>
                      <SelectTrigger>
                        <SelectValue placeholder="Selecione o Status" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>Status</SelectLabel>
                          <SelectItem value="Active">Ativo</SelectItem>
                          <SelectItem value="Inactive">Inativo</SelectItem>
                          <SelectItem value="Blocked">Bloqueado</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  ) : (
                    <Input
                      type="text"
                      value={filters[key]}
                      onChange={(e) => handleInputChange(key, e.target.value)}
                    />
                  )}
                </div>
              ))}
            </div>
            <SheetFooter>
              <SheetClose asChild>
                <Button variant="secondary" disabled={isSubmitting} onClick={removeAllFilters}>
                  Remover todos os Filtros
                </Button>
              </SheetClose>
              <SheetClose asChild>
                <Button disabled={isSubmitting} onClick={submitFilters}>
                  Aplicar
                </Button>
              </SheetClose>
            </SheetFooter>
          </SheetContent>
        </Sheet>
        {countFilledData(appliedFilters) !== 0 && (
          <div className="w-8 h-8 flex items-center justify-center rounded-[50%] bg-teal-700 text-white select-none">
            {countFilledData(appliedFilters)}
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        {(['accountId', 'accountNumber', 'ownerId', 'ownerName', 'ownerDocument', 'status'] as FilterKey[]).map((key) => (
          appliedFilters[key] && (
            <TagsFilter
              key={key}
              filterKey={key}
              filterValue={appliedFilters[key]}
              removeFilter={handleRemoveFilter}
            />
          )
        ))}
      </div>
    </div>
  )
}

const filterLabels: { [key in FilterKey]: string } = {
  accountId: "ID da Conta",
  accountNumber: "Número da Conta",
  ownerId: "ID do Cliente",
  ownerName: "Nome do Cliente",
  ownerDocument: "Documento do Cliente",
  status: "Status",
}

interface TagsFilterProps {
  filterKey: FilterKey
  filterValue: string
  removeFilter: (key: FilterKey) => void
}

const TagsFilter: React.FC<TagsFilterProps> = ({ filterKey, filterValue, removeFilter }) => (
  <Badge variant="secondary" onClick={() => removeFilter(filterKey)} className="cursor-pointer">
    {filterLabels[filterKey]}: {filterValue} ✕
  </Badge>
)
