import html2canvas from "html2canvas"
import jsPDF from "jspdf"

export const exportToPdf = async (elementId: string, fileName: string): Promise<void> => {
  const input = document.getElementById(elementId) as HTMLElement
  if (!input) {
    throw new Error(`Element with id ${elementId} not found`)
  }

  const canvas = await html2canvas(input)
  const imgData = canvas.toDataURL("image/png")

  const pdf = new jsPDF("p", "mm", "a4")
  const imgWidth = 210
  const pageHeight = 295
  const imgHeight = (canvas.height * imgWidth) / canvas.width
  let heightLeft = imgHeight

  let position = 0

  pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight)
  heightLeft -= pageHeight

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight
    pdf.addPage()
    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight)
    heightLeft -= pageHeight
  }

  pdf.save(`${fileName}.pdf`)
}
