import { ChangeEvent, Dispatch, SetStateAction } from "react"
import { areFieldsFilled, ternaryOperator } from "../../../../../utils/helpers/validate"
import { removeLeadingSpace } from "../../../../../utils/helpers/trimSpaces"
import { getAddressByCep } from "../../../../../api/endpoints/viacep"
import { formatCEP } from "../../../../../utils/helpers/formated"
import { Button } from "../../../../../components/ui/button"
import { Input } from "../../../../../components/ui/input"

export const OwnerStepAddress = ({
  owner,
  setOwner,
  setStepPosition,
}: {
  owner: any;
  setOwner: Dispatch<SetStateAction<any>>
  setStepPosition: Dispatch<SetStateAction<number>>
}) => {
  const fetchAddressByCep = async (cep: string) => {
    const response = await getAddressByCep(cep)
    setOwner({
      ...owner,
      address: {
        ...owner.address,
        street: ternaryOperator(response.logradouro, response.logradouro, owner.address.street),
        stateCode: ternaryOperator(response.uf, response.uf, owner.address.stateCode),
        postalCode: ternaryOperator(response.cep, response.cep, owner.address.postalCode),
        city: ternaryOperator(response.localidade, response.localidade, owner.address.city),
        neighborhood: ternaryOperator(response.bairro, response.bairro, owner.address.neighborhood),
      },
    })
  };

  const handleChangeCep = (e: ChangeEvent<HTMLInputElement>) => {
    const newCEPValue = formatCEP(e.target.value)
    setOwner({ ...owner, address: { ...owner.address, postalCode: newCEPValue } })

    if (newCEPValue.length === 9) {
      fetchAddressByCep(newCEPValue)
    }
  }

  return (
    <div className="flex flex-col items-center justify-center gap-10 w-1/2">
      <div className="w-2/3 flex items-center justify-center">
        <h1 className="text-2xl font-semibold">Informações de Endereço</h1>
      </div>
      <div className="w-2/3 flex flex-col gap-3">
        <Input
          type="text"
          placeholder="CEP"
          value={owner.address.postalCode}
          onChange={handleChangeCep}
        />
        <Input
          type="text"
          placeholder="Rua"
          value={owner.address.street}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setOwner({ ...owner, address: { ...owner.address, street: removeLeadingSpace(event.target.value) } })
          }
        />
        <Input
          type="text"
          placeholder="Bairro"
          value={owner.address.neighborhood}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setOwner({ ...owner, address: { ...owner.address, neighborhood: removeLeadingSpace(event.target.value) } })
          }
        />
        <Input
          type="text"
          placeholder="Cidade"
          value={owner.address.city}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setOwner({ ...owner, address: { ...owner.address, city: removeLeadingSpace(event.target.value) } })
          }
        />
        <Input
          type="text"
          placeholder="Estado"
          value={owner.address.stateCode}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setOwner({ ...owner, address: { ...owner.address, stateCode: removeLeadingSpace(event.target.value) } })
          }
        />
        <Input
          type="number"
          placeholder="Número"
          value={owner.address.number}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setOwner({ ...owner, address: { ...owner.address, number: event.target.value } })
          }
        />
        <Input
          type="text"
          placeholder="Complemento"
          value={owner.address.complement}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setOwner({ ...owner, address: { ...owner.address, complement: removeLeadingSpace(event.target.value) } })
          }
        />
      </div>
      <div className="w-2/3 flex items-center justify-between gap-2">
        <Button className="w-1/2" variant="secondary" onClick={() => setStepPosition(0)}>
          Voltar
        </Button>
        <Button
          className="w-1/2"
          disabled={!areFieldsFilled(owner.address, [
            'street',
            'number',
            'neighborhood',
            'city',
            'stateCode',
            'postalCode'
          ])}
          onClick={() => setStepPosition(2)}
        >
          Avançar
        </Button>
      </div>
    </div>
  )
}
