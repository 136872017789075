import React, { useEffect, useState } from "react"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { getOwnerBlocksByOwnerId } from "../../../../../api/endpoints/owner"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "../../../../../components/ui/accordion"
import { Card, CardContent } from "../../../../../components/ui/card"
import { getPath } from "../../../../../utils/helpers/path"
import { TabsOwner } from "../_components/tabs-owner"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import { Skeleton } from "../../../../../components/ui/skeleton"

interface OwnerBlocksHistoricProps {
  ownerBlockId: string
  ownerId: string
  ownerName: string
  blockReason: string
  blockDate: string
  blockUserId: string
  unblockReason: string
  unblockDate: string
  unblockUserId: string
  active: boolean
}

export default function OwnerBlocksHistoric() {
  const [ownerBlocks, setOwnerBlocks] = useState<OwnerBlocksHistoricProps[] | []>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const getBlocksHistory = async () => {
    try {
      const response = await getOwnerBlocksByOwnerId(getPath())
      if (response?.data) {
        setOwnerBlocks(response.data)
      } else {
        setOwnerBlocks([])
      }
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getBlocksHistory()
  }, [])

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsOwner value="blocked" />
          {loading ? (
            <Skeleton className="w-full h-full" />
          ) : error ? (
            <div className="text-red-500">Erro ao carregar o histórico de bloqueios.</div>
          ) : ownerBlocks.length > 0 ? (
            <div>
              {ownerBlocks.map((item, index) => (
                <div key={index}>
                  {index !== 0 && (
                    <div className="h-5 px-10">
                      <div className="w-1 h-full bg-gray-300"></div>
                    </div>
                  )}
                  <Card>
                    <CardContent className="flex items-center justify-between py-3 px-5">
                      <Accordion type="single" collapsible className="w-full">
                        <AccordionItem value={`item-${index}`}>
                          <AccordionTrigger className="flex items-center gap-5">
                            <div className="w-full flex items-center justify-between">
                              <p className="text-gray-700">{item.blockReason}</p>
                              <p className="font-semibold">
                                {convertUTCToUserTimezone(item.blockDate, getUserTimezoneOffset())}
                              </p>
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div className="w-full flex flex-col gap-6 p-4 bg-white">
                              <div className="w-full flex justify-between items-center gap-5 border-b pb-2">
                                <h3 className="font-semibold text-gray-700">Motivo do bloqueio:</h3>
                                <p className="text-gray-600">{item.blockReason}</p>
                              </div>
                              <div className="w-full flex justify-between items-center gap-5 border-b pb-2">
                                <h3 className="font-semibold text-gray-700">Data do bloqueio:</h3>
                                <p className="text-gray-600">{item.blockDate}</p>
                              </div>
                              <div className="w-full flex justify-between items-center gap-5 border-b pb-2">
                                <h3 className="font-semibold text-gray-700">Usuário do bloqueio:</h3>
                                <p className="text-gray-600">{item.blockUserId}</p>
                              </div>
                              {item.unblockDate && (
                                <>
                                  <div className="w-full flex justify-between items-center gap-5 border-b pb-2">
                                    <h3 className="font-semibold text-gray-700">Motivo do desbloqueio:</h3>
                                    <p className="text-gray-600">{item.unblockReason}</p>
                                  </div>
                                  <div className="w-full flex justify-between items-center gap-5 border-b pb-2">
                                    <h3 className="font-semibold text-gray-700">Data do desbloqueio:</h3>
                                    <p className="text-gray-600">{item.unblockDate}</p>
                                  </div>
                                  <div className="w-full flex justify-between items-center gap-5">
                                    <h3 className="font-semibold text-gray-700">Usuário do desbloqueio:</h3>
                                    <p className="text-gray-600">{item.unblockUserId}</p>
                                  </div>
                                </>
                              )}
                              <div className="w-full flex justify-between items-center gap-5">
                                <h3 className="font-semibold text-gray-700">Ativo:</h3>
                                <p className="text-gray-600">{item.active ? "Sim" : "Não"}</p>
                              </div>
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-gray-500">Nenhum histórico de bloqueio encontrado.</div>
          )}
        </div>
      </div>
    </PlatformTemplate>
  )
}