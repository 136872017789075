import useSWR from 'swr'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PlatformTemplate } from "../../../../components/layout/template"
import {
  AnalysisResult,
  CloseInfractionProps,
  cancelInfraction,
  closeInfraction,
  getInfractionById
} from "../../../../api/endpoints/refund"
import { getPath } from "../../../../utils/helpers/path"
import { Skeleton } from "../../../../components/ui/skeleton"
import { getUserTimezoneOffset } from '../../../../utils/storage/auth'
import { convertUTCToUserTimezone } from "../../../../utils/helpers/date"
import { Card, CardContent } from "../../../../components/ui/card"
import {
  AlertTriangle,
  CircleAlert,
  Loader2,
  X
} from 'lucide-react'
import { Button } from '../../../../components/ui/button'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '../../../../components/ui/alert-dialog'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "../../../../components/ui/dialog"
import { Textarea } from '../../../../components/ui/textarea'
import { IsEmpty } from '../../../../utils/helpers/validate'
import Swal from 'sweetalert2'

// Requisição da Infração
const fetcher = async (url: string) => {
  const infractionId = getPath()
  const response = await getInfractionById(infractionId)
  return response?.data
}

export default function InfractionById() {
  const navigate = useNavigate()
  const ISPB = process.env.REACT_APP_BANK_ISPB
  const { data, error } = useSWR('/v1/refund/infraction/[id]', fetcher)
  const [isSubmitCancel, setIsSubmitCancel] = useState(false)
  const [isErrorCloseInfraction, setIsErrorCloseInfraction] = useState({ isActive: false, message: '' })
  const [closeInfractionData, setCloseInfractionData] = useState<CloseInfractionProps>({
    fraudType: 'ApplicationFraud',
    analysisDetails: '',
    analysisResult: AnalysisResult.AGREED,
    infractionIdentification: ''
  })

  const handleInfraction = async (action: 'accept' | 'refuse') => {
    setIsErrorCloseInfraction({ isActive: false, message: '' })

    const result = action === 'accept' ? AnalysisResult.AGREED : AnalysisResult.DISAGREED
    const updatedInfractionData = { ...closeInfractionData, analysisResult: result }

    if (IsEmpty(updatedInfractionData.analysisDetails)) {
      setIsErrorCloseInfraction({ isActive: true, message: 'Preencha antes de enviar' })
      return Swal.fire({
        icon: "error",
        title: "Preencha o campo obrigatório antes de enviar.",
        confirmButtonColor: "var(--gray-500)",
      })
    }

    const response = await closeInfraction({
      infractionIdentification: data.infractionIdentification,
      analysisDetails: closeInfractionData.analysisDetails,
      analysisResult: closeInfractionData.analysisResult,
      fraudType: closeInfractionData.fraudType
    })

    if (response?.data?.isSucceded) {
      Swal.fire({
        icon: "success",
        title: "Análise realizada com Sucesso!",
        confirmButtonColor: "var(--gray-500)",
      })
      return navigate("/infraction")
    }

    setIsSubmitCancel(false)
    return Swal.fire({
      icon: "error",
      title: "Ocorreu um erro, não foi possível enviar.",
      confirmButtonColor: "var(--gray-500)",
    })
  }

  const handleCancelInfraction = async () => {
    setIsSubmitCancel(true)
    const response = await cancelInfraction(data.infractionIdentification)

    if (response?.data?.isSucceded) {
      Swal.fire({
        icon: "success",
        title: "Cancelamento realizado com Sucesso!",
        confirmButtonColor: "var(--gray-500)",
      })
      return navigate("/infraction")
    }

    setIsSubmitCancel(false)
    return Swal.fire({
      icon: "error",
      title: "Não foi possível Cancelar.",
      confirmButtonColor: "var(--gray-500)",
    })
  }

  if (error) {
    return (
      <PlatformTemplate>
        Ocorreu um erro ao carregar os dados da infração.
      </PlatformTemplate>
    )
  }

  if (!data) {
    return (
      <PlatformTemplate>
        <Skeleton className="w-full h-full" />
      </PlatformTemplate>
    )
  }

  const renderDialog = (action: 'accept' | 'refuse', label: string, variant: 'link' | 'secondary') => (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant={variant}>{label}</Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="sm:max-w-[425px]">
        <AlertDialogHeader>
          <AlertDialogTitle>{label} Infração</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="flex flex-col gap-2">
          <p className='text-sm text-zinc-600'>Em poucas palavras, escreva uma justificativa da sua análise para enviar {action}.</p>
          <Textarea rows={5}
            placeholder='Escreva os detalhes da análise'
            value={closeInfractionData.analysisDetails}
            onChange={(event) => setCloseInfractionData({ ...closeInfractionData, analysisDetails: event.target.value })}
          />
          {isErrorCloseInfraction.isActive && <p className='text-sm text-red-500'>{isErrorCloseInfraction.message}</p>}
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancelar</AlertDialogCancel>
          <AlertDialogAction onClick={() => handleInfraction(action)}>Continuar</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )

  const renderInfractionDetails = () => {
    const details = [
      { label: "ID da Infração", value: data.infractionId },
      { label: "Identificação End-To-End", value: data.endToEndIdentification },
      { label: "Motivo", value: data.reason },
      { label: "Situação", value: data.situation },
      { label: "Status", value: data.status },
      { label: "ISPB do Relator", value: data.reporterIspb },
      { label: "ISPB da Contraparte", value: data.counterPartIspb },
      { label: "Última Modificação", value: convertUTCToUserTimezone(data.lastModified, getUserTimezoneOffset()) },
      { label: "Motivo do Status", value: data.statusReason || "N/A" },
      { label: "Tipo de Fraude", value: data.fraudType },
      { label: "Resultado da Análise", value: data.analysisResult },
      { label: "Identificação da Infração", value: data.infractionIdentification },
      { label: "Detalhes da Análise", value: data.analysisDetails },
      { label: "Identificação do Marcador de Fraude", value: data.fraudMarkerIdentification },
      { label: "Data de Criação", value: convertUTCToUserTimezone(data.createDate, getUserTimezoneOffset()) },
      { label: "Data de Atualização", value: convertUTCToUserTimezone(data.updateDate, getUserTimezoneOffset()) }
    ]

    return details.map((item, index) => (
      <div key={index} className="w-full flex items-center justify-between border-b border-gray-200 py-1">
        <p className="font-medium text-gray-700 text-sm">{item.label}</p>
        <p className="text-gray-900 text-sm">{item.value}</p>
      </div>
    ))
  }

  return (
    <PlatformTemplate>
      <div className="w-full h-full flex flex-col items-center justify-center py-10 gap-5">
        <div className='w-full max-w-2xl flex items-center justify-end'>
          {data.reporterIspb === ISPB && data.status !== "Cancelled" && data.status !== "Failed" && data.status !== "Closed" && (
            <div>
              {!isSubmitCancel ? (
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button className='gap-1' variant="destructive">Cancelar Infração<X size={15} /></Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle className='flex items-center gap-2'><CircleAlert size={20} />Tem certeza de que deseja cancelar?</AlertDialogTitle>
                      <AlertDialogDescription>
                        Você tem certeza de que deseja cancelar esta Infração bancária? Esta ação não pode ser desfeita.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancelar</AlertDialogCancel>
                      <AlertDialogAction onClick={handleCancelInfraction}>Continuar</AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              ) : (
                <div className='w-20 flex items-center justify-center'>
                  <Loader2 className="mr-2 h-8 w-8 animate-spin" />
                </div>
              )}
            </div>
          )}
          {data.reporterIspb !== ISPB && data.status !== "Closed" && data.status !== "Cancelled" && data.status !== "Failed" && !data.analysisResult && (
            <div className='flex gap-2'>
              {renderDialog('refuse', 'Recusar', 'link')}
              {renderDialog('accept', 'Aceitar', 'secondary')}
            </div>
          )}
        </div>
        <Card className="w-full max-w-2xl shadow-lg rounded-lg">
          <CardContent className="flex flex-col gap-4 py-4 px-6">
            <h1 className="flex items-center gap-3 font-semibold text-xl text-gray-700 mb-4"><AlertTriangle size={25} />Detalhes da Infração</h1>
            <div className="flex flex-col gap-2">
              {renderInfractionDetails()}
            </div>
          </CardContent>
        </Card>
      </div>
    </PlatformTemplate>
  )
}
