import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table"
import {
  ColumnDef,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ChevronDown, Fingerprint, History, Library, MessageCircle, MoreHorizontal, RefreshCw } from "lucide-react"
import { Button } from "../../../../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu"
import { AlertProps, Filters } from "../page"
import { TableFiltersAlert } from "./table-filter-alert"
import { Skeleton } from "../../../../components/ui/skeleton"
import { getAlerts } from "../../../../api/endpoints/alert"
import { convertUTCToUserTimezone } from "../../../../utils/helpers/date"
import { getUserTimezoneOffset } from "../../../../utils/storage/auth"
import { CaretSortIcon } from "@radix-ui/react-icons"

const priorityLabels = [
  { key: 'LOW', label: 'baixo', color: 'text-blue-500' },
  { key: 'MEDIUM', label: 'médio', color: 'text-yellow-500' },
  { key: 'HIGH', label: 'alto', color: 'text-orange-500' },
  { key: 'CRITICAL', label: 'crítico', color: 'text-red-500' }
]

const scopeLabels = [
  { key: 'OWNER', label: 'clientes' },
  { key: 'ACCOUNT', label: 'contas' },
  { key: 'TRANSACTION', label: 'transações' },
  { key: 'INFRACTION', label: 'infrações' },
  { key: 'REFUND', label: 'MED' },
  { key: 'PAYER', label: 'pagador' },
  { key: 'DICT', label: 'DICT' }
]

const ActionCell = ({ alert }: { alert: AlertProps }) => {
  const navigate = useNavigate()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Abrir Menu</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Ações</DropdownMenuLabel>
        <DropdownMenuItem
          onClick={() => navigator.clipboard.writeText(alert.alertId)}
        >
          <Fingerprint size={15} className="mr-2" />Copiar ID do Alerta
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate(`/alert/details/${alert.alertId}`)}><Library size={15} className="mr-2" />Ver Detalhamentos</DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/alert/comments/${alert.alertId}`)}><MessageCircle size={15} className="mr-2" />Comentários</DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate(`/alert/historic/${alert.alertId}`)}><History size={15} className="mr-2" />Histórico</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const columns: ColumnDef<AlertProps>[] = [
  {
    accessorKey: "createDate",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Data de Criação
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div>{convertUTCToUserTimezone(row.getValue("createDate"), getUserTimezoneOffset())}</div>
    ),
  },
  {
    accessorKey: "ownerName",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Nome do Cliente
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div className="">{row.getValue("ownerName")}</div>
    ),
  },
  {
    accessorKey: "priority",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Prioridade
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div className="uppercase">{priorityLabels.map((item) => {
        return <span className={`font-semibold ${item.color}`}>{item.key === row.getValue("priority") && item.label}</span>
      })}</div>
    ),
  },
  {
    accessorKey: "status",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div className="font-semibold">{row.getValue("status") === "CLOSED" ? "Fechado" : row.getValue("status") === "OPEN" ? "Aberto" : "Pendente"}</div>
    ),
  },
  {
    accessorKey: "type",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Tipo
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("type") === "AVERAGE_TICKET" ? "Ingresso médio" : row.getValue("type")}</div>
    ),
  },
  {
    accessorKey: "scope",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Escopo
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div className="capitalize">{scopeLabels.map((item) => {
        return <span>{item.key === row.getValue("scope") && item.label}</span>
      })}</div>
    ),
  },
  {
    accessorKey: "value",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Valor
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <div className="">{row.getValue("value")}</div>
    ),
  },
  {
    accessorKey: "resolution",
    header: "Resolução",
    cell: ({ row }) => (
      <div className="w-[500px]">{row.getValue("resolution")}</div>
    ),
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <ActionCell alert={row.original} />,
  },
]

export default function TableAlerts({ alerts, setAlerts, pageIndex, setPageIndex, pageSize, setPageSize, totalPages, setTotalPages, filters, setFilters, appliedFilters, setAppliedFilters }:
  {
    alerts: AlertProps[],
    setAlerts: any,
    pageIndex: number,
    setPageIndex: any,
    pageSize: number,
    setPageSize: any,
    totalPages: number,
    setTotalPages: any,
    filters: Filters,
    setFilters: any,
    appliedFilters: Filters,
    setAppliedFilters: any,
  }) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})

  const [onRefresh, setOnRefresh] = useState<boolean>(false)
  const table = useReactTable({
    data: alerts,
    columns,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnVisibility,
      rowSelection,
    },
  })

  const handleRefreshTable = async (pageIndex: number, pageSize: number, filters: Filters) => {
    setOnRefresh(true)
    const response = await getAlerts({ pageIndex, pageSize, ...filters })
    setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
    if (response?.data?.items) {
      setAlerts(response?.data?.items)
      setOnRefresh(false)
    } else {
      setAlerts([])
      setOnRefresh(false)
    }
  }

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <div className="w-full h-auto flex items-center gap-2">
          <TableFiltersAlert
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setTotalPages={setTotalPages}
            filters={filters}
            setFilters={setFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            setAlerts={setAlerts}
          />
        </div>
        <DropdownMenu>
          <div className="flex items-center justify-center gap-2">
            <Button onClick={() => handleRefreshTable(pageIndex, pageSize, filters)} variant="secondary">
              <RefreshCw size={20} className={`${onRefresh && 'animate-spin'}`} />
            </Button>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                Colunas <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
          </div>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        {!onRefresh ?
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    Sem resultados
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table> :
          <Skeleton className="w-full h-[525px]" />}
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="font-semibold text-sm text-zinc-600">
          {pageIndex} de {totalPages}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPageIndex((old: number) => Math.max(old - 1, 1))}
            disabled={pageIndex === 1}
          >
            Anterior
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setPageIndex((old: number) => old + 1)}
            disabled={pageIndex === totalPages}
          >
            Próximo
          </Button>
        </div>
      </div>
    </div>
  )
}
