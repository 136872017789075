import { useState } from 'react'
import useSWR from 'swr'
import { PlatformTemplate } from "../../../../components/layout/template"
import {
  cancelRefund,
  closeRefund,
  getRefundById
} from "../../../../api/endpoints/refund"
import { getPath } from "../../../../utils/helpers/path"
import { Skeleton } from "../../../../components/ui/skeleton"
import { formatToBRL } from '../../../../utils/helpers/formated'
import { Card, CardContent } from "../../../../components/ui/card"
import {
  AlertTriangle,
  HandCoins,
  Loader2
} from 'lucide-react'
import { Button } from '../../../../components/ui/button'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '../../../../components/ui/alert-dialog'
import { Textarea } from "../../../../components/ui/textarea"
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const fetcher = async (url: string) => {
  const refundId = getPath()
  const response = await getRefundById(refundId)
  return response?.data
}

export default function RefundDetail() {
  const navigate = useNavigate()
  const ISPB = process.env.REACT_APP_BANK_ISPB
  const { data, error } = useSWR('/v1/refund/[id]', fetcher)
  const [closeRefundRemittanceInformation, setCloseRefundRemittanceInformation] = useState<string>('')
  const [isSubmitClosed, setIsSubmitClosed] = useState<boolean>(false)

  const handleCloseRefund = async () => {
    setIsSubmitClosed(true)
    const response = await closeRefund({ remittanceInformation: closeRefundRemittanceInformation, endToEndIdentification: data.endToEndIdentification, amount: null })

    if (response?.data?.isSucceded) {
      Swal.fire({
        icon: "success",
        title: "Solicitação de Reembolso realizada com Sucesso!",
        confirmButtonColor: "var(--gray-500)",
      })

      return navigate("/refund")
    }

    setIsSubmitClosed(false)
    return Swal.fire({
      icon: "error",
      title: "Não foi possível realizar a operação.",
      confirmButtonColor: "var(--gray-500)",
    })
  }

  const handleCancelRefund = async () => {
    setIsSubmitClosed(true)

    const response = await cancelRefund(getPath())

    if (response?.data?.isSucceded) {
      Swal.fire({
        icon: "success",
        title: "Solicitação de Reembolso realizada com Sucesso!",
        confirmButtonColor: "var(--gray-500)",
      })

      return navigate("/refund")
    }

    setIsSubmitClosed(false)
    return Swal.fire({
      icon: "error",
      title: "Não foi possível realizar a operação.",
      confirmButtonColor: "var(--gray-500)",
    })
  }

  if (error) {
    return (
      <PlatformTemplate>
        Ocorreu um erro ao carregar os dados do reembolso.
      </PlatformTemplate>
    )
  }

  if (!data) {
    return (
      <PlatformTemplate>
        <Skeleton className="w-full h-full" />
      </PlatformTemplate>
    )
  }

  return (
    <PlatformTemplate>
      <div className="w-full h-full flex flex-col gap-2 items-center justify-center py-10">
        <div className='w-full max-w-2xl flex items-center justify-end'>
          {data.requestingParticipant !== ISPB && data.status !== "Pending" && data.status !== "Closed" && data.status !== "Cancelled" && data.status !== "Failed" &&
            <AlertDialog>
              {isSubmitClosed ?
                <Loader2 className="mr-2 h-8 w-8 animate-spin" /> :
                <AlertDialogTrigger asChild>
                  <Button className='gap-1'>Solicitar Reembolso<HandCoins size={15} /></Button>
                </AlertDialogTrigger>
              }
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle className='flex items-center gap-2'>Realizar Reembolso</AlertDialogTitle>
                  <AlertDialogDescription className='flex flex-col gap-2'>
                    <p>Em poucas palavras, faça uma justificativa da situação para a realização de reembolso</p>
                    <Textarea rows={5} onChange={(event) => setCloseRefundRemittanceInformation(event.target.value)} />
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancelar</AlertDialogCancel>
                  <AlertDialogAction onClick={handleCloseRefund}>Enviar</AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          }
          {data.requestingParticipant === ISPB && data.status !== "Pending" && data.status !== "Closed" && data.status !== "Cancelled" && data.status !== "Failed" &&
            <Button variant="destructive" onClick={handleCancelRefund}>Cancelar reembolso</Button>
          }
        </div>
        <Card className="w-full max-w-2xl shadow-lg rounded-lg">
          <CardContent className="flex flex-col gap-4 py-4 px-6">
            <h1 className="flex items-center gap-3 font-semibold text-xl text-gray-700 mb-4"><AlertTriangle size={25} />Detalhes do Reembolso</h1>
            <div className="flex flex-col gap-2">
              {[
                { label: "ID do Reembolso", value: data.refundId },
                { label: "Identificação End-To-End", value: data.endToEndIdentification },
                { label: "Detalhes do Reembolso", value: data.refundDetails || "N/A" },
                { label: "Valor", value: `${formatToBRL(data.amount)}` },
                { label: "Motivo", value: data.reason },
                { label: "Status", value: data.status },
                { label: "Motivo do Status", value: data.statusReason || "N/A" },
                { label: "Detalhes do Status", value: data.statusDetails || "N/A" },
                { label: "ID da Correlação", value: data.correlationId || "N/A" },
                { label: "ID do Relatório de Infração", value: data.infractionReportId },
                { label: "Participante Solicitante", value: data.requestingParticipant },
                { label: "Participante Contestante", value: data.contestedParticipant },
                { label: "Identificação do Reembolso", value: data.refundIdentification },
                { label: "Identificação da Transação de Reembolso", value: data.refundTransactionIdentification || "N/A" },
                { label: "Resultado da Análise", value: data.analysisResult || "N/A" },
                { label: "Detalhes da Análise", value: data.analysisDetails || "N/A" },
                { label: "Motivo da Rejeição", value: data.rejectionReason || "N/A" },
              ].map((item, index) => (
                <div key={index} className="w-full flex items-center justify-between border-b border-gray-200 py-1">
                  <p className="font-medium text-gray-700 text-sm">{item.label}</p>
                  <p className="text-gray-900 text-sm">{item.value}</p>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </PlatformTemplate>
  )
}
