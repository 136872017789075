export const calculateTotalPageCount = ({ totalCount, pageSize }: { totalCount: number, pageSize: number }) => {
    return Math.ceil(totalCount / pageSize)
}

interface ExampleObject {
    [key: string]: string
}

export const countFilledData = (filters: { [key: string]: string | string[] }) => {
    return Object.values(filters).filter(value => {
        if (Array.isArray(value)) {
            return value.length > 0
        }
        return value.trim() !== ''
    }).length;
};
