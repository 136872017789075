import { ChangeEvent, Dispatch, FocusEvent, SetStateAction } from "react"
import { formatDocumentCnpjNumber, formatDocumentCpfNumber } from "../../../../../utils/helpers/formated"
import { areFieldsFilled, validateCNPJ, validateEmail } from "../../../../../utils/helpers/validate"
import { validateCPF } from "../../../../../utils/helpers/validate"
import { removeLeadingSpace, trimSpaces } from "../../../../../utils/helpers/trimSpaces"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select"
import { Button } from "../../../../../components/ui/button"
import { Input } from "../../../../../components/ui/input"
import { Switch } from "../../../../../components/ui/switch"
import Swal from "sweetalert2"

export const OwnerStepGeneralInformations = ({
  owner,
  setOwner,
  setStepPosition,
}: {
  owner: any;
  setOwner: Dispatch<SetStateAction<any>>
  setStepPosition: Dispatch<SetStateAction<number>>
}) => {
  const changeDocument = (event: any) => {
    setOwner({ ...owner, documentType: event })
  }

  const handleDocumentTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    let formattedDocument: any

    if (owner.documentType === 'CPF') {
      formattedDocument = formatDocumentCpfNumber(event.target.value)
    }

    if (owner.documentType === 'CNPJ') {
      formattedDocument = formatDocumentCnpjNumber(event.target.value)
    }

    setOwner({ ...owner, documentNumber: formattedDocument })
  }

  const handleValidationNextStep = () => {
    let isValidDocument = false

    switch (owner.documentType) {
      case "CPF":
        isValidDocument = validateCPF(owner.documentNumber)
        break

      default:
        isValidDocument = validateCNPJ(owner.documentNumber)
        break
    }

    if (!isValidDocument) {
      return (
        Swal.fire({
          icon: "error",
          title: `${owner.documentType} Inválido`,
          confirmButtonColor: "var(--gray-500)",
        })
      )
    }

    if (!validateEmail(owner.email)) {
      return (
        Swal.fire({
          icon: "error",
          title: `E-mail Inválido`,
          confirmButtonColor: "var(--gray-500)",
        }))
    }

    return setStepPosition(1)
  }

  return (
    <div className="w-1/2 flex flex-col gap-10 items-center justify-center">
      <div className="w-2/3 flex items-center justify-center">
        <h1 className="text-2xl font-semibold">Criar novo Cliente e Conta</h1>
      </div>
      <div className="w-2/3 flex flex-col gap-3">
        <Input
          type="text"
          placeholder="Nome Completo"
          value={owner?.name}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setOwner({ ...owner, name: removeLeadingSpace(event.target.value) })
          }
          className="text-base"
        />
        <Input
          type="text"
          placeholder="Nome Fantasia"
          value={owner?.fantasyName}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setOwner({ ...owner, fantasyName: removeLeadingSpace(event.target.value) })
          }
          className="text-base"
        />
        <Input
          type="text"
          placeholder="E-mail"
          value={owner?.email}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setOwner({ ...owner, email: trimSpaces(event.target.value) })
          }
          className="text-base"
        />
        <Select onValueChange={changeDocument}>
          <SelectTrigger className="text-base">
            <SelectValue placeholder={owner.documentType ? owner.documentType : "Selecione o Documento"} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="CNPJ">CNPJ</SelectItem>
            <SelectItem value="CPF">CPF</SelectItem>
          </SelectContent>
        </Select>
        <Input
          disabled={!owner.documentType ? true : false}
          type="text"
          value={owner.documentNumber}
          onChange={handleDocumentTypeChange}
          placeholder="Número do Documento"
          className="text-base"
        />
      </div>
      <div className="w-2/3 flex items-center justify-between">
        <div className="w-2/3 flex flex-col gap-2">
          <h2 className="text-base">Criar conta automaticamente</h2>
          <p className="text-sm text-gray-500">Esta opção criará uma conta digital para o cliente automaticamente. Caso deseje criar posteriormente desmarque esta opção.</p>
        </div>
        <Switch
          checked={owner.createAccount}
          onClick={() => setOwner({ ...owner, createAccount: !owner.createAccount })}
        />
      </div>
      <div className="w-2/3 flex items-center justify-end">
        <Button
          disabled={!areFieldsFilled(owner, ['name', 'email', 'documentType', 'documentNumber'])}
          onClick={() => handleValidationNextStep()}
          className="w-auto"
        >
          Avançar
        </Button>
      </div>
    </div>
  );
};
