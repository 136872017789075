const getPath = () => {
    const pathParts: string[] = window.location.pathname.split('/')
    return pathParts.length > 1 ? pathParts.pop()! : ''
}

const getFullPath = () => {
    return window.location.pathname
}

export { getPath, getFullPath }
