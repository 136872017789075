export const formatCEP = (cep: string) => {
    const numericCEP = cep.replace(/\D/g, '')

    if (numericCEP.length > 5) {
        return numericCEP.substring(0, 5) + '-' + numericCEP.substring(5, 8)
    } else {
        return numericCEP
    }
}

export const formatToBRL = (value: number): string => {
    const amount = value / 100;
    return amount.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    })
}

// Formatação de Documentos
export const formatDocumentCpfNumber = (document: string): string => {
    const cleanedDocument = document.replace(/\D/g, '');
    const limitedDocument = cleanedDocument.slice(0, 11);
    return limitedDocument.replace(/^(\d{0,3})(\d{0,3})?(\d{0,3})?(\d{0,2})?/, (_, p1, p2, p3, p4) =>
        [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `-${p4}`].filter(Boolean).join('')
    )
}

export const formatDocumentCnpjNumber = (document: string): string => {
    const cleanedDocument = document.replace(/\D/g, '');
    const limitedDocument = cleanedDocument.slice(0, 14);
    return limitedDocument.replace(/^(\d{0,2})(\d{0,3})?(\d{0,3})?(\d{0,4})?(\d{0,2})?/, (_, p1, p2, p3, p4, p5) =>
        [p1, p2 && `.${p2}`, p3 && `.${p3}`, p4 && `/${p4}`, p5 && `-${p5}`].filter(Boolean).join('')
    )
}
