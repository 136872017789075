import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { PreviewKeyProps } from "../page"
import { formatDocumentCnpjNumber, formatDocumentCpfNumber } from "../../../../../../../utils/helpers/formated"
import { createOtherBankByOwnerId } from "../../../../../../../api/endpoints/owner"
import { getPath } from "../../../../../../../utils/helpers/path"
import { Button } from "../../../../../../../components/ui/button"
import { Loader2 } from "lucide-react"
import Swal from "sweetalert2"

export const PreviewKey = ({ isMatchDocuments, previewKey, pixKey }: { isMatchDocuments: boolean, previewKey: PreviewKeyProps, pixKey: string }) => {
  const navigate = useNavigate()
  const [isSubmit, setIsSubmit] = useState(false)

  const handleSubmitCreateNewBank = async () => {
    setIsSubmit(true)
    const response = await createOtherBankByOwnerId({ ownerId: getPath(), key: pixKey })

    if (response.status === 200) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Banco Cadastrado com Sucesso!",
        showConfirmButton: false,
        timer: 1500
      })
      setIsSubmit(false)
      return navigate(`/owner/other-banks/${getPath()}`)
    }

    if (response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: "Algo deu errado!",
        text: response.message,
        confirmButtonColor: "var(--gray-500)",
      })
      return setIsSubmit(false)
    }
  }

  return (
    <div className={`w-full border-2 ${isMatchDocuments ? 'border-zinc-500' : 'border-red-400'} rounded-md p-5 `}>
      <div className="w-full flex flex-col items-center justify-center gap-5">
        <div className="w-full flex flex-col gap-3">
          <h1 className="font-medium">{previewKey.name}</h1>
          <div className="flex items-center justify-between text-sm">
            <p>Tipo de Documento:</p>
            <p>{previewKey.documentType}</p>
          </div>
          <div className="flex items-center justify-between text-sm">
            <p>Número do Documento:</p>
            <p>{previewKey.documentType === "CPF" ? formatDocumentCpfNumber(previewKey.documentNumber) : formatDocumentCnpjNumber(previewKey.documentNumber)}</p>
          </div>
          <div className="flex items-center justify-between text-sm">
            <p>Agência:</p>
            <p>{previewKey.branchNumber}</p>
          </div>
          <div className="flex items-center justify-between text-sm">
            <p>Número de Conta:</p>
            <p>{previewKey.accountNumber}</p>
          </div>
          <div className="flex items-center justify-between text-sm">
            <p>ISPB:</p>
            <p>{previewKey.bankIspb}</p>
          </div>
          <div className="flex items-center justify-between text-sm">
            <p>Tipo de Conta:</p>
            <p>{previewKey.accountType === "CheckingAccount" ? "Conta Corrente" : previewKey.accountType}</p>
          </div>
        </div>
        {!isSubmit ?
          <Button
            variant={isMatchDocuments ? 'default' : 'destructive'}
            disabled={!isMatchDocuments}
            onClick={handleSubmitCreateNewBank}
          >
            Cadastrar Chave</Button> :
          <Loader2 className="mr-2 h-8 w-8 animate-spin" />
        }
        {
          !isMatchDocuments &&
          <div className="w-full flex items-center justify-center">
            <p className="w-2/3 text-center text-sm text-red-500">O Documento do cliente não é o mesmo da chave inserida. Sendo assim não pode ser cadastrado(a)</p>
          </div>
        }
      </div>
    </div>
  )
}