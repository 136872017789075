import { useNavigate } from "react-router-dom"
import { Tabs, TabsList, TabsTrigger, } from "../../../../../components/ui/tabs"
import { getPath } from "../../../../../utils/helpers/path"
import { Loader2, LockKeyhole, Users } from "lucide-react"
import { Button } from "../../../../../components/ui/button"
import { useEffect, useState } from "react"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "../../../../../components/ui/alert-dialog"
import { Textarea } from "../../../../../components/ui/textarea"
import { blockOwner, getOwnersById } from "../../../../../api/endpoints/owner"
import Swal from "sweetalert2"
import { Skeleton } from "./../../../../../components/ui/skeleton"

export const TabsOwner = ({ value }: { value: string }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [isBlocked, setIsBlocked] = useState<boolean | null>(null)
  const [blockReason, setBlockReason] = useState<string>('')

  const handleBlockOwner = async () => {
    setIsSubmit(true)

    const response = await blockOwner({ id: getPath(), reason: blockReason })
    if (response.status === 200) {
      getBlockedOwner()
      setIsBlocked(false)
      return Swal.fire({
        icon: "success",
        title: "Bloqueamento realizado com sucesso!",
        confirmButtonColor: "var(--gray-500)",
      })
    }

    getBlockedOwner()
    setIsBlocked(false)
    return Swal.fire({
      icon: "error",
      title: "Não foi possível realizar a operação.",
      confirmButtonColor: "var(--gray-500)",
    })
  }

  const getBlockedOwner = async () => {
    const response = await getOwnersById(getPath())
    if (response?.data) {
      setIsBlocked(response.data.isBlocked)
      return setIsLoading(false)
    }
  }

  useEffect(() => {
    getBlockedOwner()
  }, [])

  return (
    <Tabs value={value} className="flex flex-col gap-3">
      <div className="w-full flex items-center justify-between py-3 gap-2">
        <div className="flex items-center gap-2">
          <Users size={20} />
          <h1 className="text-[20px] font-semibold">Clientes</h1>
        </div>
        {!isLoading ?
          <div>
            {!isBlocked ?
              <AlertDialog>
                {isSubmit ?
                  <div className="w-28 flex items-center justify-center">
                    <Loader2 className="mr-2 h-8 w-8 animate-spin" />
                  </div> :
                  <AlertDialogTrigger asChild>
                    <Button variant="link" className="gap-2"><LockKeyhole size={15} />Bloquear Cliente</Button>
                  </AlertDialogTrigger>
                }
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle className='flex items-center gap-2'>Bloquear Cliente</AlertDialogTitle>
                    <AlertDialogDescription className='flex flex-col gap-2'>
                      <p>Em poucas palavras, faça uma justificativa do Bloqueio.</p>
                      <Textarea rows={5} onChange={(event) => setBlockReason(event.target.value)} />
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancelar</AlertDialogCancel>
                    <AlertDialogAction onClick={handleBlockOwner}>Enviar</AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog> :
              <div className="text-red-500 font-semibold border-b border-red-500">
                Cliente Bloqueado!
              </div>
            }
          </div> :
          <Skeleton className="w-40 h-full" />
        }
      </div>
      <TabsList className="grid grid-cols-6">
        <TabsTrigger value="details" onClick={() => navigate(`/owner/details/${getPath()}`)}>Detalhes</TabsTrigger>
        <TabsTrigger value="adresses" onClick={() => navigate(`/owner/adresses/${getPath()}`)}>Endereços</TabsTrigger>
        <TabsTrigger value="other-banks" onClick={() => navigate(`/owner/other-banks/${getPath()}`)}>Outros Bancos</TabsTrigger>
        <TabsTrigger value="integrations" onClick={() => navigate(`/owner/integrations/${getPath()}`)}>Integrações</TabsTrigger>
        <TabsTrigger value="activities" onClick={() => navigate(`/owner/activities/${getPath()}`)}>Atividades</TabsTrigger>
        <TabsTrigger value="blocked" onClick={() => navigate(`/owner/blocked/historic/${getPath()}`)}>Bloqueios</TabsTrigger>
      </TabsList>
    </Tabs>
  )
}