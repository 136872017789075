import React, { useState } from "react"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/ui/table"
import {
    ColumnDef,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import {
    Blocks,
    ChevronDown,
    Fingerprint,
    Library,
    MapPin,
    MoreHorizontal,
    PiggyBank,
    RefreshCw,
    Target,
    UserRoundX
} from "lucide-react"
import { Button } from "../../../../components/ui/button"
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu"
import { useNavigate } from "react-router-dom"
import { OwnerProps } from "../page"
import { TableFiltersOwner } from "./table-filter-owner"
import { formatDocumentCnpjNumber, formatDocumentCpfNumber } from "../../../../utils/helpers/formated"
import { CaretSortIcon } from "@radix-ui/react-icons"
import { getOwners } from "../../../../api/endpoints/owner"
import { Skeleton } from "../../../../components/ui/skeleton"

const ActionCell = ({ owner }: { owner: OwnerProps }) => {
    const navigate = useNavigate()
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                    <span className="sr-only">Abrir Menu</span>
                    <MoreHorizontal className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuLabel>Ações</DropdownMenuLabel>
                <DropdownMenuItem
                    onClick={() => navigator.clipboard.writeText(owner.id)}
                >
                    <Fingerprint size={15} className="mr-2" />Copiar ID do Cliente
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => navigate(`/owner/details/${owner.id}`)}><Library size={15} className="mr-2" />Ver Detalhamentos</DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate(`/owner/adresses/${owner.id}`)}><MapPin size={15} className="mr-2" />Endereço</DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate(`/owner/other-banks/${owner.id}`)}><PiggyBank size={15} className="mr-2" />Outros Bancos</DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate(`/owner/integrations/${owner.id}`)}><Blocks size={15} className="mr-2" />Integrações</DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate(`/owner/activities/${owner.id}`)}><Target size={15} className="mr-2" />Atividades</DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate(`/owner/blocked/historic/${owner.id}`)}><UserRoundX size={15} className="mr-2" />Bloqueios</DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

export const columns: ColumnDef<OwnerProps>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Nome
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => <div className="">{row.getValue("name")}</div>,
    },
    {
        accessorKey: "email",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Email
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => <div className="lowercase">{row.getValue("email")}</div>,
    },
    {
        accessorKey: "documentType",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Tipo de Documento
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => <div>{row.getValue("documentType")}</div>,
    },
    {
        accessorKey: "documentNumber",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Número do Documento
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("documentType") === "CPF" ? formatDocumentCpfNumber(row.getValue("documentNumber")) : formatDocumentCnpjNumber(row.getValue("documentNumber"))}</div>
        ),
    },
    {
        id: "actions",
        enableHiding: false,
        cell: ({ row }) => <ActionCell owner={row.original} />,
    },
]

export interface OwnerFilterProps {
    id: string
    name: string
    email: string
    documentNumber: string
    [key: string]: string
}

export default function TableOwners({ owners, setOwners, pageIndex, setPageIndex, pageSize, setPageSize, totalPages, setTotalPages, filters, setFilters, appliedFilters, setAppliedFilters }: {
    owners: OwnerProps[],
    setOwners: any,
    pageIndex: number,
    setPageIndex: any,
    pageSize: number,
    setPageSize: any,
    totalPages: number,
    setTotalPages: any,
    filters: OwnerFilterProps,
    setFilters: any,
    appliedFilters: OwnerFilterProps,
    setAppliedFilters: any
}) {
    const navigate = useNavigate()
    const [onRefresh, setOnRefresh] = useState<boolean>(false)
    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
    const [rowSelection, setRowSelection] = React.useState({})

    const table = useReactTable({
        data: owners,
        columns,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
            columnVisibility,
            rowSelection,
        },
    })

    const handleRefreshTable = async (pageIndex: number, pageSize: number, filters: OwnerFilterProps) => {
        setOnRefresh(true)
        const response: any = await getOwners({ pageIndex, pageSize, ...filters })
        setTotalPages(Math.ceil(response?.data?.totalCount / pageSize))
        if (response?.data?.items) {
            setOwners(response?.data?.items)
            setOnRefresh(false)
        } else {
            setOwners([])
            setOnRefresh(false)
        }
    }

    return (
        <div className="w-full">
            <div className="flex items-center py-4">
                <div className="w-full h-auto flex items-center gap-2">
                    <TableFiltersOwner
                        setTotalPages={setTotalPages}
                        pageSize={pageSize}
                        setPageIndex={setPageIndex}
                        filters={filters}
                        setFilters={setFilters}
                        appliedFilters={appliedFilters}
                        setAppliedFilters={setAppliedFilters}
                        setOwners={setOwners}
                    />
                </div>
                <DropdownMenu>
                    <div className="flex items-center justify-center gap-2">
                        <Button onClick={() => handleRefreshTable(pageIndex, pageSize, filters)} variant="secondary">
                            <RefreshCw size={20} className={`${onRefresh && 'animate-spin'}`} />
                        </Button>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" className="ml-auto">
                                Colunas <ChevronDown className="ml-2 h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                    </div>
                    <DropdownMenuContent align="end">
                        {table
                            .getAllColumns()
                            .filter((column) => column.getCanHide())
                            .map((column) => {
                                return (
                                    <DropdownMenuCheckboxItem
                                        key={column.id}
                                        className="capitalize"
                                        checked={column.getIsVisible()}
                                        onCheckedChange={(value) =>
                                            column.toggleVisibility(!!value)
                                        }
                                    >
                                        {column.id}
                                    </DropdownMenuCheckboxItem>
                                )
                            })}
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
            <div className="rounded-md border">
                {!onRefresh ?
                    <Table>
                        <TableHeader>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <TableHead key={header.id}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                            </TableHead>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableHeader>
                        <TableBody>
                            {table.getRowModel().rows?.length ? (
                                table.getRowModel().rows.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        data-state={row.getIsSelected() && "selected"}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={columns.length}
                                        className="h-24 text-center"
                                    >
                                        Sem resultados
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table> :
                    <Skeleton className="w-full h-[525px]" />}
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <div className="font-semibold text-sm text-zinc-600">
                    {pageIndex} de {totalPages}
                </div>
                <div className="space-x-2">
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setPageIndex((old: number) => Math.max(old - 1, 1))}
                        disabled={pageIndex === 1}
                    >
                        Anterior
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setPageIndex((old: number) => old + 1)}
                        disabled={pageIndex === totalPages}
                    >
                        Próximo
                    </Button>
                </div>
            </div>
        </div>
    )
}
