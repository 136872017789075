export const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(email)
}

export const limitedText = (text: string, limit: number): string => {
    if (text.length <= limit) {
        return text
    } else {
        return text.substring(0, limit) + '...'
    }
}

export const ternaryOperator = (condition: boolean, trueValue: any, falseValue: any): any => {
    if (condition) {
        return trueValue
    } else {
        return falseValue
    }
}

export const IsEmpty = (value: any, response?: string): any => {
    if (response) {
        if (value === undefined || value === null || value === '') {
            return response;
        } else {
            return value;
        }
    }

    if (value === undefined || value === null || value === '') {
        return true
    } else {
        return false
    }
}

export const IsEmptyObject = (json: any): boolean => {
    if (typeof json !== 'object' || json === null) {
        return false
    }

    for (const key in json) {
        if (typeof json[key] === 'object') {
            if (!IsEmptyObject(json[key])) {
                return false
            }
        } else {
            if (json[key] === null || json[key] === undefined || json[key] === '') {
                return true
            }
        }
    }

    return false
}

export const IsEmptyObjectExceptKeys = (json: any, exemptKeys: string[]): boolean => {
    if (typeof json !== 'object' || json === null) {
        return false
    }

    for (const key in json) {
        if (!exemptKeys.includes(key)) {
            if (typeof json[key] === 'object') {
                if (!IsEmptyObjectExceptKeys(json[key], exemptKeys)) {
                    return false
                }
            } else {
                if (json[key] === null || json[key] === undefined || json[key] === '') {
                    return false
                }
            }
        }
    }

    return true;
}

export const areFieldsFilled = <T>(obj: T, fields: (keyof T)[]): boolean => {
    for (const field of fields) {
        const value = obj[field]
        if (value === null || value === undefined || value === '' || value === false) {
            return false
        }
    }
    return true
}

// Validações de Documentos
export function validateCPF(cpf: string): boolean {
    // Remover caracteres não numéricos
    cpf = cpf.replace(/[^\d]+/g, '')

    // Verificar se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
        return false
    }

    // Verificar se todos os dígitos são iguais (caso inválido)
    if (/^(\d)\1{10}$/.test(cpf)) {
        return false
    }

    // Função auxiliar para calcular os dígitos verificadores
    const calculateDigit = (base: string, initialWeight: number): number => {
        let sum = 0
        for (let i = 0; i < base.length; i++) {
            sum += parseInt(base.charAt(i)) * (initialWeight - i)
        }
        const remainder = sum % 11
        return remainder < 2 ? 0 : 11 - remainder
    };

    // Calcular o primeiro dígito verificador
    const digit1 = calculateDigit(cpf.substring(0, 9), 10)
    if (digit1 !== parseInt(cpf.charAt(9))) {
        return false
    }

    // Calcular o segundo dígito verificador
    const digit2 = calculateDigit(cpf.substring(0, 10), 11)
    if (digit2 !== parseInt(cpf.charAt(10))) {
        return false
    }

    return true
}

// Validações de Documentos
export function validateCNPJ(cnpj: string): boolean {
    // Remover caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '')

    // Verificar se o CNPJ tem 14 dígitos
    if (cnpj.length !== 14) {
        return false
    }

    // Verificar se todos os dígitos são iguais (caso inválido)
    if (/^(\d)\1{13}$/.test(cnpj)) {
        return false
    }

    // Função auxiliar para calcular os dígitos verificadores
    const calculateDigit = (base: string, factors: number[]): number => {
        let sum = 0
        for (let i = 0; i < base.length; i++) {
            sum += parseInt(base.charAt(i)) * factors[i]
        }
        const remainder = sum % 11
        return remainder < 2 ? 0 : 11 - remainder
    };

    // Calcular o primeiro dígito verificador
    const factorsForDigit1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    const digit1 = calculateDigit(cnpj.substring(0, 12), factorsForDigit1)
    if (digit1 !== parseInt(cnpj.charAt(12))) {
        return false
    }

    // Calcular o segundo dígito verificador
    const factorsForDigit2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    const digit2 = calculateDigit(cnpj.substring(0, 13), factorsForDigit2)
    if (digit2 !== parseInt(cnpj.charAt(13))) {
        return false
    }

    return true
}