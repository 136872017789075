import React from "react"
import { getFullPath } from "../../../utils/helpers/path"
import { Link } from "react-router-dom"

interface SideBarProps extends React.HTMLAttributes<HTMLDivElement> { children: React.ReactNode }

const SideBar = ({ children, ...props }: SideBarProps) => {
    return <div className={`w-[280px] h-full flex flex-col gap-7 overflow-y-auto py-14 px-5 shadow-md ${props.className}`} {...props}>{children}</div>
}

const SideBarItem = ({ children, ...props }: SideBarProps) => {
    return <div className={`w-full flex flex-col gap-2 ${props.className}`} {...props}>{children}</div>
}

interface SideBarTitleProps extends React.HTMLAttributes<HTMLHeadingElement> { children: React.ReactNode }

const SideBarTitle = ({ children, ...props }: SideBarTitleProps) => {
    return <h1 className={`text-[18px] font-semibold ${props.className}`} {...props}>{children}</h1>
}

interface SideBarLinkProps extends React.HTMLAttributes<HTMLDivElement> { children: React.ReactNode, href: string }

const SideBarLink = ({ children, href, ...props }: SideBarLinkProps) => {
    const path = getFullPath()

    return (
        <Link
            className={`w-full py-1 px-2 cursor-pointer rounded-md flex items-center justify-start gap-2 text-[14px] 
                ${props.className} 
                ${href && path.includes(href)
                    ? "bg-slate-800 hover:bg-slate-900 text-white" :
                    "hover:bg-slate-100"}`
            }
            to={href}>
            {children}
        </Link>
    )
}

export { SideBar, SideBarItem, SideBarTitle, SideBarLink }