export const SCOPE_OPTIONS = [
  { value: "ACCOUNT", label: "Conta" },
  { value: "TRANSACTION", label: "Transação" },
  { value: "INFRACTION", label: "Infração" }
]

export const TYPE_OPTIONS = [
  { value: "AVERAGE_TICKET", label: "Ticket Médio" },
  { value: "MAXIMUM_VALUE_PER_TRANSFER", label: "Valor Máximo por Transferência" },
  { value: "MAXIMUM_TRANSFER_AMOUNT", label: "Valor Máximo de Transferência" },
  { value: "MAXIMUM_TRANSACTION_AMOUNT", label: "Valor Máximo de Transação" },
  { value: "TRANSFER_RECEIVED", label: "Transferência Recebida" },
  { value: "MED_LIMIT_FOR_ACCOUNT", label: "Limite Médio por Conta" },
  { value: "MED_LIMIT_PER_PAYER", label: "Limite Médio por Pagador" },
  { value: "AUTOMATIC_SETTLEMENT_AMOUNT_LIMIT_DAILY", label: "Limite Diário de Liquidação Automática" },
  { value: "AUTOMATIC_SETTLEMENT_AMOUNT_LIMIT_WEEKLY", label: "Limite Semanal de Liquidação Automática" },
  { value: "AUTOMATIC_SETTLEMENT_AMOUNT_LIMIT_MONTHLY", label: "Limite Mensal de Liquidação Automática" },
  { value: "WITHDRAWAL_AMOUNT_LIMIT_DAILY", label: "Limite Diário de Saque" },
  { value: "WITHDRAWAL_AMOUNT_LIMIT_WEEKLY", label: "Limite Semanal de Saque" },
  { value: "WITHDRAWAL_AMOUNT_LIMIT_MONTHLY", label: "Limite Mensal de Saque" },
  { value: "WITHDRAWAL_QUANTITY_LIMIT", label: "Limite de Quantidade de Saque" }
]

export const ACTION_OPTIONS = [
  { value: "BLOCK", label: "Bloquear" },
  { value: "ALERT", label: "Alertar" }
]

export const CONDITIONS_OPTIONS = [
  { value: 'greater_than', label: 'maior que' },
  { value: 'less_than', label: 'menor que' },
  { value: 'equal_than', label: 'igual a' },
  { value: 'greater_equal_than', label: 'maior ou igual a' },
  { value: 'less_equal_than', label: 'menor ou igual a' },
  { value: 'different_than', label: 'diferente de' }
]
