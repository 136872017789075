import React, { useEffect, useState } from "react"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import { closeAlert, getAlertById, openAlert } from "../../../../../api/endpoints/alert"
import { getPath } from "../../../../../utils/helpers/path"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { Button } from "../../../../../components/ui/button"
import { TabsAlert } from "../_components/tabs-alert"
import { BookCheck, Calendar, Loader2, User } from "lucide-react"
import { Textarea } from "../../../../../components/ui/textarea"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "../../../../../components/ui/alert-dialog"
import Swal from "sweetalert2"

interface AlertDetailsProps {
  alertId: string,
  scope: string,
  type: string,
  referenceId: string,
  accountId: string,
  accountNumber: string,
  ownerId: string,
  ownerName: string,
  priority: string,
  status: string,
  expression: string,
  value: string,
  resolution: string,
  resolutionDate: string,
  openedUserId: string,
  openedUserName: string,
  closedUserId: string,
  closedUserName: string,
  comments: [
    {
      id: string,
      alertId: string,
      comment: string,
      userId: string,
      userName: string,
      createDate: string
    }
  ],
  histories: [
    {
      id: string,
      alertId: string,
      action: string,
      oldStatus: string,
      newStatus: string,
      userId: string,
      userName: string,
      createDate: string
    },
    {
      id: string,
      alertId: string,
      action: string,
      oldStatus: string,
      newStatus: string,
      userId: string,
      userName: string,
      createDate: string
    }
  ],
  createDate: string,
  updateDate: string
}

const statusLabels = [
  { key: 'PENDING', label: 'pendente' },
  { key: 'OPEN', label: 'aberto' },
  { key: 'CLOSED', label: 'fechado' },
]

const priorityLabels = [
  { key: 'LOW', label: 'baixo', color: 'text-blue-500' },
  { key: 'MEDIUM', label: 'médio', color: 'text-yellow-500' },
  { key: 'HIGH', label: 'alto', color: 'text-orange-500' },
  { key: 'CRITICAL', label: 'crítico', color: 'text-red-500' }
]

const scopeLabels = [
  { key: 'OWNER', label: 'clientes' },
  { key: 'ACCOUNT', label: 'contas' },
  { key: 'TRANSACTION', label: 'transações' },
  { key: 'INFRACTION', label: 'infrações' },
  { key: 'REFUND', label: 'MED' },
  { key: 'DICT', label: 'DICT' }
]

export default function AlertDetails() {
  const [alert, setAlert] = useState<AlertDetailsProps>()

  async function fetchAlertDefailt() {
    const response = await getAlertById({ alertId: getPath() })
    if (response?.data) {
      return setAlert(response?.data)
    }
  }

  useEffect(() => {
    fetchAlertDefailt()
  }, [])

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsAlert value="details" />
          {alert ? (
            <div className="mt-5 pb-10">
              <div className="min-h-full p-8 bg-white shadow-md rounded-lg">
                <div className="flex items-center justify-between pb-5 mb-5 border-b-2">
                  <h1 className="text-xl font-extrabold text-gray-800">Detalhes do Alerta</h1>
                  <DetailsActions alert={alert} fetchAlertDefailt={fetchAlertDefailt} />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {alert.resolution &&
                    <div className="flex flex-col gap-2 shadow shadow-neutral-400 rounded-2xl p-5 md:col-span-2">
                      <BookCheck size={25} className="text-green-500" />
                      <p className="text-md font-medium text-gray-500">Resolução:</p>
                      <p className="text-md font-medium text-gray-700">{alert.resolution}</p>
                    </div>
                  }
                  <div>
                    <p className="text-sm font-medium text-gray-500">ID:</p>
                    <p className="text-md font-semibold text-gray-700">{alert.alertId}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Escopo:</p>
                    <p className="text-md font-semibold text-gray-700">{scopeLabels.map((scope) => (
                      <span key={scope.key} className="capitalize">{alert.scope === scope.key && scope.label}</span>
                    ))}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Tipo:</p>
                    <p className="text-md font-semibold text-gray-700">{alert.type}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">ID de Referência:</p>
                    <p className="text-md font-semibold text-gray-700">{alert.referenceId}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">ID da Conta:</p>
                    <p className="text-md font-semibold text-gray-700">{alert.accountId}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Número da Conta:</p>
                    <p className="text-md font-semibold text-gray-700">{alert.accountNumber}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">ID do Proprietário:</p>
                    <p className="text-md font-semibold text-gray-700">{alert.ownerId}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Nome do Proprietário:</p>
                    <p className="text-md font-semibold text-gray-700">{alert.ownerName}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Prioridade:</p>
                    <p className="text-md font-semibold capitalize">{priorityLabels.map((priority) => (
                      <span key={priority.key} className={`${priority.color}`}>{alert.priority === priority.key && priority.label}</span>
                    ))}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Status:</p>
                    <p className="text-md font-semibold text-gray-700 capitalize">{statusLabels.map((status) => (
                      alert.status === status.key && status.label
                    ))}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Expressão:</p>
                    <p className="text-md font-semibold text-gray-700">{alert.expression}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Valor:</p>
                    <p className="text-md font-semibold text-gray-700">{alert.value}</p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-sm font-medium text-gray-500">Data da Resolução:</p>
                    <div className="flex items-center">
                      <Calendar size={20} className="text-gray-500 mr-2" />
                      <p className="text-md font-semibold text-gray-700">{convertUTCToUserTimezone(alert.resolutionDate, getUserTimezoneOffset()).toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-sm font-medium text-gray-500">Aberto Por:</p>
                    <div className="flex items-center">
                      <User size={20} className="text-gray-500 mr-2" />
                      <p className="text-md font-semibold text-gray-700">{alert.openedUserName}</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-sm font-medium text-gray-500">Fechado Por:</p>
                    <div className="flex items-center">
                      <User size={20} className="text-gray-500 mr-2" />
                      <p className="text-md font-semibold text-gray-700">{alert.closedUserName}</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-sm font-medium text-gray-500">Data de Criação:</p>
                    <div className="flex items-center">
                      <Calendar size={20} className="text-gray-500 mr-2" />
                      <p className="text-md font-semibold text-gray-700">{convertUTCToUserTimezone(alert.createDate, getUserTimezoneOffset()).toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-sm font-medium text-gray-500">Data de Atualização:</p>
                    <div className="flex items-center">
                      <Calendar size={20} className="text-gray-500 mr-2" />
                      <p className="text-md font-semibold text-gray-700">{convertUTCToUserTimezone(alert.updateDate, getUserTimezoneOffset()).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Skeleton className="w-full h-full" />
          )}
        </div>
      </div>
    </PlatformTemplate>
  )
}

function DetailsActions({ alert, fetchAlertDefailt }: { alert: AlertDetailsProps, fetchAlertDefailt: () => Promise<void> }) {
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [resolution, setResolution] = useState<string>('')

  const handleOpenAlert = async () => {
    setIsSubmit(true)

    const response = await openAlert({ alertId: getPath() })

    if (response?.status === 200) {
      setIsSubmit(false)
      await fetchAlertDefailt()
      return Swal.fire({
        icon: "success",
        title: "Alerta Aberto com Sucesso!",
        confirmButtonColor: "var(--gray-500)",
      })
    }

    setIsSubmit(false)
    return Swal.fire({
      icon: "error",
      title: "Não foi possível realizar a ação.",
      confirmButtonColor: "var(--gray-500)",
    })
  }

  const handleCloseAlert = async () => {
    setIsSubmit(true)

    const response = await closeAlert({ alertId: getPath(), resolution: resolution })

    if (response?.status === 200) {
      setIsSubmit(false)
      setResolution('')
      await fetchAlertDefailt()
      return Swal.fire({
        icon: "success",
        title: "Alerta Fechado com Sucesso!",
        confirmButtonColor: "var(--gray-500)",
      })
    }

    setIsSubmit(false)
    return Swal.fire({
      icon: "error",
      title: "Não foi possível realizar a ação.",
      confirmButtonColor: "var(--gray-500)",
    })
  }

  switch (alert.status) {
    case "PENDING":
      return (
        <Button disabled={isSubmit} onClick={handleOpenAlert} variant="default" className="capitalize">
          {isSubmit ? <span className="flex items-center gap-2"><Loader2 className="w-5 h-5 animate-spin" />Abrindo...</span> : "Abrir alerta"}
        </Button>
      )

    case "OPEN":
      return (
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button disabled={isSubmit} variant="default" className="capitalize">
              {isSubmit ? <span className="flex items-center gap-2"><Loader2 className="w-5 h-5 animate-spin" />Fechando...</span> : "fechar alerta"}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle className='flex items-center gap-2'>Fechar Alerta</AlertDialogTitle>
              <AlertDialogDescription className='flex flex-col gap-2'>
                <p>Em poucas palavras, faça uma resolução final para fechar o alerta.</p>
                <Textarea rows={5} value={resolution} onChange={(event) => setResolution(event.target.value)} />
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancelar</AlertDialogCancel>
              <AlertDialogAction onClick={handleCloseAlert}>Fechar</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )

    default:
      break
  }

  return null
}